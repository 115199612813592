import { Box, Typography, styled } from "@mui/material";

export const ProgressWrapper = styled(Box)(() => ({
    position: 'relative',
    display: 'inline-flex',

    '.MuiCircularProgress-circle': {
        stroke: "url(#linearColors) !important",
    }
}));

export const CircularProgressWrapper = styled(Box)(() => ({
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

export const ProgressLabel = styled(Typography)(() => ({
    fontSize: '1rem',
    fontWeight: 900,
}));
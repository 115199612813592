import React, { useState, useEffect } from 'react';
import { useFormik, FormikProps } from 'formik';
import { useSnackbar } from 'notistack';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { ConditionalProgress } from 'app/shared/components/ConditionalCircular/ConditionalCircular';
import CustomCheckbox from 'app/shared/components/Checkbox/Checkbox';
import CustomTextField from 'app/shared/components/TextField/TextField';
import Button from 'app/shared/components/Button/Button';
import VitalEdgeLoader from 'app/shared/components/Loader/VitalEdgeLoader';
import { COLORS } from 'app/shared/constants/COLORS';
import {
  affiliateValidationSchema,
  companyValidationSchema,
} from 'app/pages/Authentication/utils';
import { capitalizeFirstLetter, startCase } from 'app/shared/utils/helpers';
import { useCheckAuthenticity } from 'app/shared/hooks/useCheckAuthenticity';
import {
  IRegisterAffiliateRequestPayload,
  IUpdateAffiliateRequestPayload,
} from 'app/shared/types/affiliate.types';
import { affiliateDao } from 'app/shared/dao/affiliateDao';
import ThankYouIllustration from 'assets/images/thankyou_illustration.svg';
import { ThankYouWrapper, sub, title } from './ThankYouView.styles';
import { useNavigate } from 'react-router-dom';
import COUNTRIES from '../../../shared/constants/COUNTRIES';

interface PersonalFormValues {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  companyName: string;
  agree: boolean;
}

interface CompanyFormValues {
  id: string;
  userCompanyUrl: string;
  street: string;
  city: string;
  state: string;
  country: string;
  zip: string;
  bankName: string;
  routingNumber: string;
  accountType: string;
  accountNumber: string;
}

interface Field {
  name: string;
  xs: number;
  options?: { label: string; value: string }[];
  inputProps?: any
}

const personalFields: Field[] = [
  { name: 'firstName', xs: 6 },
  { name: 'lastName', xs: 6 },
  { name: 'companyName', xs: 12 },
  { name: 'phone', xs: 12 },
  { name: 'email', xs: 12 },
];

const bankFields: Field[] = [
  { name: 'bankName', xs: 6 },
  { name: 'routingNumber', xs: 6, inputProps: { maxLength: 9 }},
  {
    name: 'accountType',
    xs: 6,
    options: [
      { label: 'Business Account', value: 'Business Account' },
      { label: 'Personal Account', value: 'Personal Account' },
    ],
  },
  { name: 'accountNumber', xs: 6 },
];

const renderFields = (fields: Field[], formik: any) =>
  fields.map((field) => (
    <Grid item xs={field.xs} key={field.name as string}>
      {field.options ? (
        <FormControl fullWidth>
          <Select
            id={field.name as string}
            name={field.name as string}
            value={formik.values[field.name as string] as string}
            onChange={formik.handleChange}
            displayEmpty
            sx={{
              borderRadius: '30px',
              fontSize: '16px',
              color: COLORS.TEXT_GRAY,
            }}
          >
            <MenuItem disabled value="">
              <span style={{ color: COLORS.TEXT_GRAY, opacity: 0.6 }}>
                Account Type
              </span>
            </MenuItem>
            {field.options.map(({ label, value }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>
            {formik.touched[field.name as string] &&
            formik.errors[field.name as string]}
          </FormHelperText>
        </FormControl>
      ) : (
        <CustomTextField
          fullWidth
          id={field.name as string}
          name={field.name as string}
          placeholder={startCase(field.name as string)}
          value={formik.values[field.name as string] as string}
          onChange={formik.handleChange}
          error={
            formik.touched[field.name as string] &&
            Boolean(formik.errors[field.name as string])
          }
          helperText={
            formik.touched[field.name as string] &&
            formik.errors[field.name as string]
          }
          variant="standard"
          InputLabelProps={{ shrink: true }}
          inputProps={field.inputProps}
          className="mb-4 custom-login-input"
        />
      )}
    </Grid>
  ));

const PersonalInfo: React.FC<{
  formik: FormikProps<PersonalFormValues>;
  setPageDisplay: (page: 'Personal' | 'Company') => void;
  submitting: boolean;
}> = ({ formik, submitting }) => {
  const isDisabled =
    !formik.values.firstName ||
    !formik.values.lastName ||
    !formik.values.email ||
    !formik.dirty ||
    !formik.values.agree;
  return (
    <form onSubmit={formik.handleSubmit} className="personal-form">
      <div style={{ maxWidth: 400 }}>
        <div className="title">
          Thanks for choosing to be one of our affiliate partners
        </div>
        <div className="supporting">
          Enter your details to allow us to generate your new account and
          referral link
        </div>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          {renderFields(personalFields, formik)}
        </Grid>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <FormControl
              error={formik.touched.agree && Boolean(formik.errors.agree)}
            >
              <FormControlLabel
                className="remember-me supporting affiliate-terms-wrapper"
                checked={formik.values.agree}
                control={<CustomCheckbox name="agree" />}
                onChange={() =>
                  formik.setFieldValue('agree', !formik.values.agree)
                }
                label={
                  <>
                    I agree to all{' '}
                    <span
                      className="terms-btn-text"
                      onClick={() =>
                        window.open('https://vitaledge.io/affiliate-terms')
                      }
                    >
                      Terms & conditions
                    </span>
                  </>
                }
              />
              <FormHelperText
                style={{
                  fontSize: '14px',
                  marginLeft: 0,
                }}
              >
                {formik.touched.agree && formik.errors.agree}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Button
            type={'submit'}
            variant="contained"
            size="large"
            fullWidth
            className="submit-button"
            disabled={submitting || isDisabled}
          >
            Next
          </Button>
        </Grid>
      </div>
    </form>
  );
};

const CompanyForm: React.FC<{
  formik: FormikProps<CompanyFormValues>;
  submitting: boolean;
}> = ({ formik, submitting }) => {
  const [selectedCountry, setSelectedCountry] = useState<string>('');
  const [stateOptions, setStateOptions] = useState<string[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const country = COUNTRIES.find((c) => c.country === selectedCountry);
    setStateOptions(country?.states || []);
  }, [selectedCountry]);

  return (
    <form onSubmit={formik.handleSubmit} className="company">
      <div className="title">
        Your unique affiliate link has been emailed to you. In order to receive
        your affiliate payments, please provide company and bank deposit
        information.
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h2>Company Information</h2>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <Select
              id="country"
              name="country"
              value={selectedCountry}
              onChange={(e) => {
                setSelectedCountry(e.target.value);
                formik.setFieldValue('country', e.target.value);
                formik.setFieldValue('state', ''); // Reset state when country changes
              }}
              displayEmpty
              sx={{
                borderRadius: '30px',
                fontSize: '16px',
                color: COLORS.TEXT_GRAY,
              }}
            >
              <MenuItem disabled value="">
                <span style={{ color: COLORS.TEXT_GRAY, opacity: 0.6 }}>
                  Country
                </span>
              </MenuItem>
              {COUNTRIES.map(({ country }) => (
                <MenuItem key={country} value={country}>
                  {country}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              {formik.touched.country && formik.errors.country}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <CustomTextField
            fullWidth
            id={'city'}
            name={'city'}
            placeholder={'City'}
            value={formik.values['city']}
            onChange={formik.handleChange}
            error={
              formik.touched['city'] &&
              Boolean(formik.errors['city'])
            }
            helperText={
              formik.touched['city'] &&
              formik.errors['city']
            }
            variant="standard"
            InputLabelProps={{ shrink: true }}
            className="mb-4 custom-login-input"
          />
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <Select
              id="state"
              name="state"
              value={formik.values.state}
              onChange={formik.handleChange}
              displayEmpty
              disabled={!selectedCountry}
              sx={{
                borderRadius: '30px',
                fontSize: '16px',
                color: COLORS.TEXT_GRAY,
              }}
            >
              <MenuItem disabled value="">
                <span style={{ color: COLORS.TEXT_GRAY, opacity: 0.6 }}>
                  State
                </span>
              </MenuItem>
              {stateOptions.map((state) => (
                <MenuItem key={state} value={state}>
                  {state}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              {formik.touched.state && formik.errors.state}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={8}>
          <CustomTextField
            fullWidth
            id={'street'}
            name={'street'}
            placeholder={'Street'}
            value={formik.values['street']}
            onChange={formik.handleChange}
            error={
              formik.touched['street'] &&
              Boolean(formik.errors['street'])
            }
            helperText={
              formik.touched['street'] &&
              formik.errors['street']
            }
            variant="standard"
            InputLabelProps={{ shrink: true }}
            className="mb-4 custom-login-input"
          />
        </Grid>
        <Grid item xs={4}>
          <CustomTextField
            fullWidth
            id={'zip'}
            name={'zip'}
            placeholder={'Zip'}
            value={formik.values['zip']}
            onChange={formik.handleChange}
            error={
              formik.touched['zip'] &&
              Boolean(formik.errors['zip'])
            }
            helperText={
              formik.touched['zip'] &&
              formik.errors['zip']
            }
            variant="standard"
            InputLabelProps={{ shrink: true }}
            className="mb-4 custom-login-input"
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            fullWidth
            id={'userCompanyUrl'}
            name={'userCompanyUrl'}
            placeholder={'Company Website'}
            value={formik.values['userCompanyUrl']}
            onChange={formik.handleChange}
            error={
              formik.touched['userCompanyUrl'] &&
              Boolean(formik.errors['userCompanyUrl'])
            }
            helperText={
              formik.touched['userCompanyUrl'] &&
              formik.errors['userCompanyUrl']
            }
            variant="standard"
            InputLabelProps={{ shrink: true }}
            className="mb-4 custom-login-input"
          />
        </Grid>
        <Grid item xs={12}>
          <h2>Bank Information</h2>
        </Grid>
        {renderFields(bankFields, formik)}
        <Grid item xs={6}>
          <Button
            type="button"
            variant="outlined"
            size="large"
            fullWidth
            className="submit-button"
            onClick={() => {
              navigate('/', { replace: true });
            }}
          >
            Skip for now
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            type="submit"
            variant="contained"
            size="large"
            fullWidth
            className="submit-button"
            disabled={submitting || !formik.dirty}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const Affiliate = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { registerAffiliate, updateAffiliate } = affiliateDao();
  const { authenticating } = useCheckAuthenticity();

  const [pageDisplay, setPageDisplay] = useState<'Personal' | 'Company' | 'ThankYou'>('Personal');
  const [submitting, setSubmitting] = useState(false);
  const [affiliateId, setAffiliateId] = useState<string>('');

  const personalFormik = useFormik<PersonalFormValues>({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      companyName: '',
      agree: false,
    },
    validationSchema: affiliateValidationSchema,
    onSubmit: async (values) => {
      const registerParams: IRegisterAffiliateRequestPayload = {
        email: values.email.trim().toLowerCase(),
        name: `${values.firstName} ${values.lastName}`.trim(),
        phone: values.phone,
        companyName: values.companyName,
      };

      try {
        setSubmitting(true);
        const res = await registerAffiliate(registerParams);
        setAffiliateId(res.data?.affiliateID);
        setPageDisplay('Company');
      } catch (error) {
        enqueueSnackbar('Failed to register affiliate', { variant: 'error' });
      } finally {
        setSubmitting(false);
      }
    },
  });

  const companyFormik = useFormik<CompanyFormValues>({
    initialValues: {
      id: affiliateId,
      userCompanyUrl: '',
      street: '',
      city: '',
      state: '',
      country: '',
      zip: '',
      bankName: '',
      routingNumber: '',
      accountType: '',
      accountNumber: '',
    },
    validationSchema: companyValidationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const updateParams: IUpdateAffiliateRequestPayload = {
        id: affiliateId || values.id,
        street: values.street,
        city: values.city,
        state: values.state,
        country: values.country,
        zip: values.zip,
        userCompanyUrl: values.userCompanyUrl,
        bankName: values.bankName,
        routingNumber: values.routingNumber,
        accountType: values.accountType,
        accountNumber: values.accountNumber,
      };

      try {
        setSubmitting(true);
        await updateAffiliate(updateParams);
        setPageDisplay('ThankYou');
      } catch (error) {
        enqueueSnackbar('Failed to update affiliate information', { variant: 'error' });
      } finally {
        setSubmitting(false);
      }
    },
  });

  const ThankYouView = () => (
    <ThankYouWrapper container>
      <Grid item xs={12} className="d-flex align-items-center justify-content-center flex-column">
        <img src={ThankYouIllustration} alt="Thank You" className="illustration" />
        <Typography sx={{ fontSize: '48px', fontWeight: 600 }}>Thank you!</Typography>
        <Typography color={COLORS.TEXT_GRAY} fontSize={16}>
          Thank you for submitting your company information and bank information.
        </Typography>
      </Grid>
    </ThankYouWrapper>
  );

  return (
    <>
      {(authenticating || submitting) ? (
        <VitalEdgeLoader isOpen={true} />
      ) : pageDisplay === 'Personal' ? (
        <PersonalInfo formik={personalFormik} setPageDisplay={setPageDisplay} submitting={submitting} />
      ) : pageDisplay === 'Company' ? (
        <CompanyForm formik={companyFormik} submitting={submitting} />
      ) : (
        <ThankYouView />
      )}
    </>
  );
};

export default Affiliate;
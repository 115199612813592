import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Divider } from '@mui/material';
import { COLORS } from '../../constants/COLORS';

interface IOptions {
  label: string | React.ReactNode;
  value: any;
  onClick: () => void;
  isDisabled?: boolean;
  isHidden?: boolean;
  hasDivider?: boolean;
}

interface IPopupButton {
  label: any;
  options: IOptions[];
}

const PopupButton = ({ label, options }: IPopupButton) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button onClick={handleClick}>{label}</Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {options.filter(op => !!!op?.isHidden).map((option) => (
          <>
            {option.hasDivider && <Divider />}
            <MenuItem
              disabled={option.isDisabled}
              key={option.value}
              onClick={() => {
                option.onClick();
                handleClose();
              }}
              sx={{
                fontSize: 16,
                '&:hover': {
                  backgroundColor: COLORS.PRIMARY_LIGHT,
                  color: COLORS.PRIMARY,
                  '& svg path': {
                    fill: COLORS.PRIMARY,
                  },
                },
              }}
            >
              {option.label}
            </MenuItem>
          </>
        ))}
      </Menu>
    </>
  );
};

export default PopupButton;

import React, { ReactNode } from 'react'
import Grid from '@mui/material/Grid';

import InfoGraphics from 'app/components/Authentication/InfoGraphics';
import { LeftDisplayWrapper, RightDisplayWrapper } from 'app/pages/Authentication/Authentication.styles';

const AuthenticationLayout = ({ children }: { children: ReactNode }) => {
  return (
    <Grid container sx={{height: '100%'}}>
        <LeftDisplayWrapper container item xs={12} md={6} justifyContent='center' alignItems='center'>
          {children}
        </LeftDisplayWrapper>  
        <RightDisplayWrapper container item md={6} justifyContent='center' alignItems='center' display={{ xs: 'none', md: 'flex' }}>
          <InfoGraphics />
        </RightDisplayWrapper>
    </Grid>
  )
}

export default AuthenticationLayout
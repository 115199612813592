import { CONFIG } from '../config';

export const ENDPOINTS = {
  ORGANIZATION_SERVICE: `${CONFIG.API_URL}/organization-service`,
  PIXEL_SERVICE: `${CONFIG.API_URL}/pixel-service`,
  PRICING_SERVICE: `${CONFIG.API_URL}/pricing-service`,
  STRIPE_SERVICE: `${CONFIG.API_URL}/stripe-service`,
  USER_SERVICE: `${CONFIG.API_URL}/user-service`,
  WORKSPACE_SERVICE: `${CONFIG.API_URL}/workspace-service`,
  CAPTURES_SERVICE: `${CONFIG.API_URL}/contact-service`,
  GOOGLE_SERVICE: `${CONFIG.API_URL}/google-service`,
  AFFILIATE_SERVICE: `${CONFIG.API_URL}/affiliate-service`,
  SYSTEM_SERVICE: `${CONFIG.API_URL}/system-service`,
  PAYMENT_SERVICE: `${CONFIG.API_URL}/payment-service`,
  EMAIL_SERVICE: `${CONFIG.API_URL}/email-service`,
};

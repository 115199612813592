import React from 'react';
import { CircularProgress } from '@mui/material';

interface Props {
  isLoading: boolean;
  color?: string;
  sizeInRem?: number;
}

export const ConditionalProgress: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  isLoading,
  sizeInRem: size,
  color,
}) => {
  return isLoading ? (
    <CircularProgress
      size={size ? size : 28}
      sx={{ color: color ? color : 'white', alignSelf: 'center', justifyContent: 'center' }}
    />
  ) : (
    <>{children}</>
  );
};

import React, { useState, useEffect, useMemo } from 'react';
import {
  PaymentMethodCont,
  ExpCont,
  FieldCont,
  cvvField,
  updateButton,
  applyButton,
  CardBoxWrapper,
  AmountCont,
} from './PaymentMethod.styles';
import { Box, Button, FormControlLabel, Grid, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { QueryClient, useQueryClient } from '@tanstack/react-query';

import CustomTextField from 'app/shared/components/TextField/TextField';
import { ConditionalProgress } from 'app/shared/components/ConditionalCircular/ConditionalCircular';

import { COLORS } from 'app/shared/constants/COLORS';
import { paymentDetailsValidationSchema } from 'app/pages/Authentication/utils';
import { stripeDao } from 'app/shared/dao/stripeDao';
import { capitalizeFirstLetter, numberWithCommas } from 'app/shared/utils/helpers';
import { ls } from 'app/shared/utils/ls';
import { pricingDao } from 'app/shared/dao/pricingDao';
import { billingServices } from 'app/shared/services/billingServices';
import CustomCheckbox from 'app/shared/components/Checkbox/Checkbox';
import CardPreview from 'app/components/Settings/BillingInfo/CardPreview/CardPreview';
import { useChangeWorkspace } from 'app/shared/hooks/useChangeWorkspace';
import { IUserAddNewWorkspace } from '../../../../types/user.types';
import { useUserJobRole } from '../../../../hooks/useUserJobRole';
import { userServices } from '../../../../services/userServices';

interface Props {
  handleSubmit: (e?: any) => void;
  paymentCards: any[];
  selectedPlan: string;
  workspaceName: string;
  period: string;
  setSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
  setApplying: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: VoidFunction;
}

const { addPaymentMethods, paymentMethodAttach, createSubscription } = stripeDao();
const { plans, retrieveOrderSummary } = pricingDao();
const { addNewWorkspace } = userServices();
const { createAlternativeCard, setDefaultCard, getStripeCustomer } = billingServices();
const PaymentMethod: React.FC<Props> = (props: Props) => {
  const { jobRole } = useUserJobRole();
  const { getLS, setLS } = ls();
  const { workspacesList, currentWorkspaceId } = useChangeWorkspace();
  const user = JSON.parse(getLS('user') || '{}');
  const currentWorkspace = workspacesList?.find((w: any) => w?.id === currentWorkspaceId);

  const [planInfo, setPlanInfo] = useState<any>({});
  const [orderSummary, setOrderSummary] = useState<any>({});
  const [ccAdding, setccAdding] = useState(false);
  const [couponCode, setCouponCode] = useState<string | null>(null);
  const [couponApplying, setCouponApplying] = useState(false);
  const [isUsePreviouslySaved, setUsePreviouslySaved] = useState(false);
  const [couponApplied, setCouponApplied] = useState(false);
  const [paymentMethodId, setPaymentMethodId] = useState<string | null>(null);

  const { mutate, isLoading } = addNewWorkspace();
  const {
    data: stripeCustomerData,
    isLoading: isStripeCustomerDataFetching,
    refetch: refetchStripeCustomerData,
  } = getStripeCustomer(currentWorkspace?.stripeCustomerId || user?.stripeCustomerId);

  const stripeCustomerDataFormatted = useMemo(() => stripeCustomerData || {}, [stripeCustomerData]);

  useEffect(() => {
    const getPlanInfo = async () => {
      const p = props?.selectedPlan?.replace(/\s/g, '')?.toLowerCase();
      const response = await plans(currentWorkspace?.pricingVersion);
      const planInfo = response?.data?.find((plan: any) => plan?.planName === p);
      setPlanInfo(planInfo);
    };

    getPlanInfo();
  }, [props?.selectedPlan, currentWorkspace?.pricingVersion]);

  useEffect(() => {
    const fetchOrderSummary = async () => {
      setCouponApplying(true);
      props?.setApplying(true);
      const planInterval = props?.period === 'yearly' ? 'annually' : 'monthly';
      const retrievedOrderSummary = await retrieveOrderSummary({
        id: planInfo?.id,
        planInterval,
        couponCode,
      });
      setOrderSummary(retrievedOrderSummary?.data);
      setCouponApplying(false);
      props?.setApplying(false);
      if (retrievedOrderSummary?.data?.couponId) {
        setCouponApplied(true);
      } else {
        setCouponApplied(false);
      }
    };

    fetchOrderSummary();
  }, [planInfo, couponCode, props?.period]);

  const formik = useFormik({
    initialValues: {
      card_name: '',
      number: '',
      exp_month: '',
      exp_year: '',
      exp_monthyear: '',
      cvv: '',
      coupon_code: '',
      use_saved_info: false,
    },
    enableReinitialize: true,
    validationSchema: paymentMethodId ? null : paymentDetailsValidationSchema,
    onSubmit: async (values) => {
      setccAdding(true);
      props?.setSubmitting(true);
      if (!isUsePreviouslySaved) {
        const response = await addPaymentMethods({
          card: {
            number: values.number,
            exp_month: Number(values?.exp_monthyear?.split('/')?.[0]),
            exp_year: Number(values?.exp_monthyear?.split('/')?.[1]),
            cvc: values.cvv,
          },
          billing_details: {
            name: values?.card_name || user?.name || `${user?.firstName} ${user?.lastName}`,
            email: user?.email?.toLowerCase() || '',
          },
        });
        if (response?.id) {
          handleSubmit(response);
        } else {
          console.log({ response });
          if (response?.code === 'incorrect_number') {
            formik.setFieldError('number', 'Incorrect card number');
          } else if (response?.code === 'invalid_expiry_year') {
            formik.setFieldError('exp_monthyear', 'Invalid expiry year');
          } else if (response?.code === 'invalid_cvc') {
            formik.setFieldError('cvv', 'Invalid cvv');
          } else {
            enqueueSnackbar(capitalizeFirstLetter(response?.message || ''), { variant: 'error' });
          }
          setccAdding(false);
          props?.setSubmitting(false);
        }
      } else {
        handleSubmit();
      }
    },
  });

  const applyCouponCode = () => {
    setCouponCode(formik.values.coupon_code);
  };

  const removeCouponCode = () => {
    setCouponCode('');
    setTimeout(() => {
      formik.setFieldValue('coupon_code', '');
    }, 500);
  };

  const handleUsePreviouslySaved = () => {
    setUsePreviouslySaved((prev) => !prev);
    if (!isUsePreviouslySaved && props?.paymentCards?.length > 0) {
      // const p = props?.paymentCards?.[0];
      const p = props?.paymentCards?.find(
        (p: any) => p?.id === stripeCustomerDataFormatted?.invoice_settings?.default_payment_method,
      );
      setPaymentMethodId(p?.id);
      formik.setFieldValue('card_name', p?.billing_details?.name);
      formik.setFieldValue('number', `************${p?.card?.last4}`);
      const updatedExpMonth = p?.card?.exp_month < 10 ? `0${p?.card?.exp_month}` : p?.card?.exp_month;
      formik.setFieldValue('exp_monthyear', `${updatedExpMonth}/${p?.card?.exp_year?.toString()?.slice(-2)}`);
      formik.setFieldValue('cvc', '****');
    } else {
      formik.setErrors({});
      formik.resetForm();
      setPaymentMethodId('');
    }
    setCouponApplied(false);
    setCouponCode('');
  };

  const handleSubmit = async (responsePM?: any) => {
    let registerParams: IUserAddNewWorkspace = {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      workspaceName: props.workspaceName,
      organizationid: user.organizationid,
      creditPlanInterval: props?.period === 'yearly' ? 'annually' : 'monthly',
      pricingVersion: currentWorkspace?.pricingVersion,
      plan: props.selectedPlan.replace(/\s/g, '').replace(/([A-Z])/g, (match) => match.toLowerCase()),
      price: orderSummary?.total,
      couponCode: orderSummary?.couponCode || '',
      couponId: orderSummary?.couponId || '',
      stripePaymentMethodId: responsePM?.id || paymentCard?.[0]?.id,
    };

    mutate(registerParams, {
      onSuccess: (res) => {
        formik.resetForm();
        props.handleSubmit();
      },
      onError: (err: any) => {
        console.log('err: ', err);
        enqueueSnackbar(capitalizeFirstLetter(err?.message || ''), { variant: 'error' });
      },
    });
  };

  // only show the first in the array props.paymentCards
  const paymentCard =
    props?.paymentCards?.length > 0
      ? props?.paymentCards?.filter(
          (p: any) => p?.id === stripeCustomerDataFormatted?.invoice_settings?.default_payment_method,
        )
      : [];

  return (
    <PaymentMethodCont>
      <Typography fontSize={24} fontWeight={700}>
        Add Payment Method
      </Typography>
      <FieldCont>
        <CustomTextField
          fullWidth
          name="card_name"
          label="Card Name"
          placeholder="Enter the name on your card"
          value={formik.values.card_name}
          onChange={formik.handleChange}
          error={formik.touched.card_name && Boolean(formik.errors.card_name)}
          helperText={formik.touched.card_name && formik.errors.card_name}
          variant="standard"
          InputLabelProps={{
            shrink: true,
          }}
          className="mb-4"
          disabled={isUsePreviouslySaved}
        />
        <CustomTextField
          style={{ marginTop: 20 }}
          fullWidth
          name="number"
          label="Card Number"
          placeholder="0000 0000 0000 0000"
          value={
            paymentMethodId && isUsePreviouslySaved
              ? formik.values.number.replace(/(\d{4})/g, '$1 ').trim()
              : formik.values.number
                  .replace(/[^0-9]/g, '')
                  .replace(/(\d{4})/g, '$1 ')
                  .trim()
          }
          onChange={formik.handleChange}
          error={formik.touched.number && Boolean(formik.errors.number)}
          helperText={formik.touched.number && formik.errors.number}
          variant="standard"
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{ maxLength: 19 }}
          className="mb-4"
          disabled={isUsePreviouslySaved}
        />
      </FieldCont>
      <ExpCont>
        <CustomTextField
          name="exp_monthyear"
          label="Expiry Date"
          placeholder="MM/YY"
          value={
            paymentMethodId && isUsePreviouslySaved
              ? formik.values.exp_monthyear
              : formik.values.exp_monthyear
                  .replace(/[^0-9]/g, '')
                  .replace(/^([2-9])$/g, '0$1')
                  .replace(/^(1{1})([3-9]{1})$/g, '0$1/$2')
                  .replace(/^0{1,}/g, '0')
                  .replace(/^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g, '$1/$2')
                  .trim()
          }
          onChange={formik.handleChange}
          error={formik.touched.exp_monthyear && Boolean(formik.errors.exp_monthyear)}
          helperText={formik.touched.exp_monthyear && formik.errors.exp_monthyear}
          variant="standard"
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{ maxLength: 5 }}
          className="mb-4"
          disabled={isUsePreviouslySaved}
        />
        <CustomTextField
          sx={cvvField}
          type="password"
          name="cvv"
          label="CVV"
          placeholder="****"
          value={formik.values.cvv.replace(/[^0-9]/g, '').trim()}
          onChange={formik.handleChange}
          error={formik.touched.cvv && Boolean(formik.errors.cvv)}
          helperText={formik.touched.cvv && formik.errors.cvv}
          variant="standard"
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{ maxLength: 4 }}
          className="mb-4"
          disabled={isUsePreviouslySaved}
        />
      </ExpCont>
      {/* !isUsePreviouslySaved ? '0px' : '30px */}
      <ExpCont style={{ marginBottom: '15px' }}>
        <CustomTextField
          name="coupon_code"
          label="Coupon Code"
          placeholder="Coupon Code"
          value={formik.values.coupon_code.trim()}
          color={couponApplied ? 'success' : undefined}
          onChange={formik.handleChange}
          error={formik.touched.coupon_code && Boolean(formik.errors.coupon_code)}
          helperText={
            (couponApplied && `Applied coupon code: '${formik?.values?.coupon_code}'`) ||
            (formik.touched.coupon_code && formik.errors.coupon_code)
          }
          variant="standard"
          InputLabelProps={{
            shrink: true,
          }}
          disabled={couponApplied}
          className="mb-4"
        />
        <Button
          sx={{ ...updateButton, ...cvvField, ...applyButton }}
          variant="contained"
          size="large"
          disabled={!!!formik.values.coupon_code || couponApplying || ccAdding}
          onClick={couponApplied ? removeCouponCode : applyCouponCode}
        >
          <ConditionalProgress isLoading={couponApplying} color={COLORS.PRIMARY}>
            {couponApplied ? 'Remove' : 'Apply'}
          </ConditionalProgress>
        </Button>
      </ExpCont>
      {orderSummary?.planPrice !== undefined &&
      orderSummary?.planPrice !== null &&
      orderSummary?.discountedAmount !== undefined &&
      orderSummary?.discountedAmount !== null ? (
        <AmountCont container>
          <Grid item xs={4}>
            Subscription Amount
          </Grid>
          <Grid item xs={8} style={{ textAlign: 'right' }}>
            ${numberWithCommas((orderSummary?.planPrice).toFixed(2))}
          </Grid>
        </AmountCont>
      ) : (
        ''
      )}
      {orderSummary?.discountedAmount !== undefined && orderSummary?.discountedAmount !== null ? (
        <AmountCont container>
          <Grid item xs={4}>
            Discounted Amount
          </Grid>
          <Grid item xs={8} style={{ textAlign: 'right' }}>
            - ${numberWithCommas((orderSummary?.discountedAmount).toFixed(2))}
          </Grid>
        </AmountCont>
      ) : (
        ''
      )}
      {orderSummary?.total !== undefined && orderSummary?.total !== null ? (
        <AmountCont container>
          <Grid item xs={4}>
            Total Due Today
          </Grid>
          <Grid item xs={8} style={{ textAlign: 'right' }}>
            ${numberWithCommas(orderSummary?.total?.toFixed(2))}
          </Grid>
        </AmountCont>
      ) : (
        ''
      )}
      {/* <div>
                {
                    !isUsePreviouslySaved && !(props?.paymentCards?.length > 3) ? (
                        <FormControlLabel
                            className="remember-me"
                            checked={isSaveInfo}
                            control={<CustomCheckbox />}
                            onChange={() => {
                                setSaveInfo((prev) => !prev);
                            }}
                            label="Save card info (You can only save up to a maximum of 3 cards)"
                            style={{ marginBottom: 20, }}
                        />
                    ) : ''
                }
            </div> */}
      <Button
        sx={updateButton}
        type="submit"
        variant="contained"
        size="large"
        fullWidth
        onClick={() => formik.handleSubmit()}
        disabled={
          (!isUsePreviouslySaved && !formik.dirty) ||
          ccAdding ||
          couponApplying ||
          (isUsePreviouslySaved && !paymentMethodId)
        }
      >
        <ConditionalProgress isLoading={ccAdding} color={COLORS.PRIMARY}>
          Pay Now
        </ConditionalProgress>
      </Button>
    </PaymentMethodCont>
  );
};

export default PaymentMethod;

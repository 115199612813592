import { Box, Grid, Stack, Typography, styled } from '@mui/material';
import { COLORS } from 'app/shared/constants/COLORS';
import Radio from '@mui/material/Radio';

export const ModalCont = styled(Box)(({ theme }: any) => ({
  display: 'flex',
  width: 500,
  minHeight: 380,
  borderRadius: 10,
  background: '#fff',
  padding: 30,
  position: 'relative',
  flexDirection: 'column',

  [theme.breakpoints.down('md')]: {
    width: '500px',
  },

  [theme.breakpoints.down('sm')]: {
    width: 'auto',
  },
}));

export const FieldsCont = styled(Stack)(({ theme }) => ({
  alignSelf: 'center',
  flexDirection: 'column',
  width: '90%',
  gap: '24px',
}));

export const updateButton = {
  margin: 'auto',
  fontSize: 18,
  textTransform: 'none',
  marginBottom: '15px',
  borderRadius: '8px',
  width: '90%',
  height: 48,
};

export const lastNameField = {
  marginLeft: '20px',
};

export const Title = styled(Typography)(() => ({
  fontSize: '24px',
  fontWeight: 700,
  color: COLORS.LABEL_NORMAL,
  textAlign: 'center',
  alignSelf: 'center',
}));

export const Subtitle = styled(Typography)(() => ({
  fontSize: '16px',
  fontWeight: 400,
  color: COLORS.BUTTON_BLACK,
  textAlign: 'center',
  alignSelf: 'center',
  width: '90%',
  opacity: 0.6,
  marginBottom: '32px',
}));

export const IntegrationContainer = styled(Grid)(() => ({
  width: '90%',
  alignSelf: 'center',
}));

export const IntegrationBox = styled(Box)(() => ({
  width: '200px',
  height: '200px',
  border: '1px solid #E6E6E6',
  borderRadius: '8px',
  position: 'relative',
  margin: '0px auto 20px auto',
  alignItems: 'center',
}));

export const IntegrationItemLabel = styled(Typography)(() => ({
  fontSize: '20px',
  fontWeight: 500,
  color: '#3A3A3A',
}));

export const IntegrationRadioButton = styled(Radio)(() => ({
  position: 'absolute',
  top: 5,
  right: 5,
}));

export const SuccessTitle = styled(Typography)(() => ({
  textAlign: 'center',
  fontWeight: 700,
  fontSize: '24px',
  marginTop: '20px',
  marginBottom: '32px',
}));

export const SuccessSubtitle = styled(Typography)(() => ({
  textAlign: 'center',
  fontWeight: 400,
  fontSize: '14px',
  width: '90%',
  color: '#5D5E5F',
}));

export const SuccessText = styled(Typography)(() => ({
  fontWeight: 400,
  fontSize: '18px',
}));

export const SuccessTextContainer = styled(Stack)(() => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '90%',
  marginBottom: '12px',
}));

export const SuccessTitle2 = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: '16px',
  marginTop: 8,
  textAlign: 'left',
  width: '90%',
  color: '#152C7D'
}));

export const SuccessSubtitle2 = styled(Typography)(() => ({
  fontWeight: 600,
  fontSize: '12px',
  marginTop: 8,
  textAlign: 'left',
  width: '90%',
  color: '#353537',
  opacity: 0.6,
}));

export const CodeSnippetBox = styled(Box)(() => ({
  marginTop: '24px',
  marginBottom: '17px',
  backgroundColor: '#F9FAFF',
  borderRadius: '8px',
  width: '90%',
  // height: '100px',
}));

export const CodeText = styled(Typography)(() => ({
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '32px',
  color: COLORS.PRIMARY,
  padding: '4px 13px',
}));

export const CodeButtonContainer = styled(Stack)(() => ({
  flexDirection: 'row',
  justifyContent: 'right',
  width: '90%',
}));

export const CodeButtonLabel = styled(Typography)(() => ({
  fontSize: '16px',
  fontWeight: 500,
  color: '#4A3AFF',
  paddingLeft: '8px',
  textDecoration: 'underline',
  textUnderlineOffset: 5,
}));

export const ErrorLabel = styled(Typography)(() => ({
  textAlign: 'center',
  fontWeight: 400,
  fontSize: '14px',
  width: '90%',
  color: '#5d5e5f',
}));

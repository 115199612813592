import * as yup from 'yup';
import valid from 'card-validator';

export const loginValidationSchema = yup.object({
  email: yup
    .string()
    .trim()
    .strict(false)
    .email('Please enter a valid email address')
    .required('Email is required'),
  password: yup
    .string()
    .required('Password is required'),
});

export const signupValidationSchema = yup.object({
  email: yup
    .string()
    .trim()
    .strict(false)
    .email('Please enter a valid email address')
    .required('Email is required'),
  firstName: yup
    .string()
    .required('First name is required'),
  companyName: yup
    .string()
    .required('Organization name is required'),
  lastName: yup
    .string()
    .required('Last name is required'),
  password: yup
    .string()
    .required('Password is required'),
    // .min(8, 'Password should be of minimum 8 characters length')
    // .matches(/[0-9]/, 'Password requires a number (0-9)')
    // .matches(/[a-z]/, 'Password requires a lowercase letter (a-z)')
    // .matches(/[A-Z]/, 'Password requires an uppercase letter (A-Z)')
    // .matches(/[^\w]/, 'Password requires a symbol (!, @, #, $, etc.)'),
  confirm_password: yup
    .string()
    .oneOf([yup.ref('password')], 'Passwords must match')
    .required('Confirm password is required'),
});

export const forgotPasswordValidationSchema = yup.object({
  email: yup
    .string()
    .trim()
    .strict(false)
    .email('Please enter a valid email address')
    .required('Email is required'),
});

export const resetPasswordValidationSchema = yup.object({
  password: yup
    .string()
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required')
    .matches(/[0-9]/, 'Password requires a number (0-9)')
    .matches(/[a-z]/, 'Password requires a lowercase letter (a-z)')
    .matches(/[A-Z]/, 'Password requires an uppercase letter (A-Z)')
    .matches(/[^\w]/, 'Password requires a symbol (!, @, #, $, etc.)'),
  confirm_password: yup
    .string()
    .oneOf([yup.ref('password')], 'Passwords must match')
    .required('Confirm password is required'),
});

export const paymentDetailsValidationSchema = yup.object({
  number: yup
    .string()
    .required('Card number is required'),
  exp_monthyear: yup
    .string()
    .required('Expiry date is required')
    .max(5, 'Not a valid expiry date (MM/YY)')
    .matches(
      /^(0[1-9]|1[0-2])\/[0-9]{2}$/,
      'Not a valid expiry date (MM/YY)'
    )
    .test(
      'test-credit-card-expiration-date',
      'Invalid expiry date',
      expirationDate => {
        if (!expirationDate) {
          return false
        }
  
        const today = new Date()
        const monthToday = today.getMonth() + 1
        const yearToday = today
          .getFullYear()
          .toString()
          // .substr(-2)
  
        const [expMonth, expYear] = expirationDate.split('/')
  
        if (Number(`20${expYear}`) < Number(yearToday)) {
          return false
        } else if (
          Number(expMonth) < monthToday &&
          Number(`20${expYear}`) <= Number(yearToday)
        ) {
          return false
        }
  
        return true
      }
    ),
  cvv: yup
    .string()
    .required('CVC is required'),
});

export const memberSignupValidationSchema = yup.object({
  password: yup
    .string()
    .required('Password is required'),
  confirm_password: yup
    .string()
    .oneOf([yup.ref('password')], 'Passwords must match')
    .required('Confirm password is required'),
});


export const affiliateValidationSchema = yup.object({
  email: yup
    .string()
    .trim()
    .strict(false)
    .email('Please enter a valid email address')
    .required('Email is required'),
  firstName: yup
    .string()
    .trim()
    .required('First name is required'),
  companyName: yup
    .string()
    .trim(),
    // .required('Organization name is required'),
  lastName: yup
    .string()
    .trim()
    .required('Last name is required'),
  phone: yup
    .string()
    .trim(),
  agree: yup
    .boolean()
    .oneOf([true], 'You must agree to the terms and conditions'),
});

export const companyValidationSchema = yup.object().shape({
  userCompanyUrl: yup.string(),
  street: yup.string(),
  city: yup.string(),
  state: yup.string(),
  country: yup.string(),
  zip: yup.string(),
  bankName: yup.string(),
  routingNumber: yup.string().matches(/^\d{9}$/, 'Routing number must be 9 digits'),
  accountType: yup.string().oneOf(['Business Account', 'Personal Account'], 'Invalid account type'),
  accountNumber: yup.string(),
});

export const nmiPaymentDetailsValidationSchema = yup.object({
  ccnumber: yup
    .string()
    .required('Card number is required'),
  ccexp: yup
    .string()
    .required('Expiry date is required')
    .max(5, 'Not a valid expiry date (MM/YY)')
    .matches(
      /^(0[1-9]|1[0-2])\/[0-9]{2}$/,
      'Not a valid expiry date (MM/YY)'
    )
    .test(
      'test-credit-card-expiration-date',
      'Invalid expiry date',
      expirationDate => {
        if (!expirationDate) {
          return false
        }

        const today = new Date()
        const monthToday = today.getMonth() + 1
        const yearToday = today
          .getFullYear()
          .toString()
        // .substr(-2)

        const [expMonth, expYear] = expirationDate.split('/')

        if (Number(`20${expYear}`) < Number(yearToday)) {
          return false
        } else if (
          Number(expMonth) < monthToday &&
          Number(`20${expYear}`) <= Number(yearToday)
        ) {
          return false
        }

        return true
      }
    ),
  cvv: yup
    .string()
    .required('CVC is required'),
  first_name: yup
    .string()
    .required('First Name is required'),
  last_name: yup
    .string()
    .required('Last Name is required'),
  address1: yup
    .string()
    .required('Address is required'),
  city: yup
    .string()
    .required('City is required'),
  state: yup
    .string()
    .required('State is required'),
  zip: yup
    .string()
    .required('Zip is required'),
  country: yup
    .string()
    .required('Country is required'),
  email: yup
    .string()
    .trim()
    .strict(false)
    .email('Please enter a valid email address')
    .required('Email is required'),
});
import React, { useEffect, useMemo } from 'react';
import { BalanceCont, CountCont, IconCountCont, MainCont } from './Balance.style';
import { Tooltip, Typography } from '@mui/material';
import { CaptureIcon, ManagePixelIcon } from '../../Icon/Icons';
import { workspaceServices } from 'app/shared/services/workspaceServices';
import { ConditionalProgress } from 'app/shared/components/ConditionalCircular/ConditionalCircular';
import { useChangeWorkspace } from 'app/shared/hooks/useChangeWorkspace';
import { ls } from 'app/shared/utils/ls';
import { InfoOutlined } from '@mui/icons-material';
import { pixelServices } from 'app/shared/services/pixelServices';

const { listPixels } = pixelServices();
const { getWorkspace } = workspaceServices();
const Balance = () => {
  const { getLS, setLS } = ls();
  const { currentWorkspaceId, currentOrganizationId, updateCurrentWorkspace } = useChangeWorkspace();
  const { organizationid, workspaceid } = JSON.parse(localStorage.getItem('user') as string);
  const { data: pixels, isLoading } = listPixels({
    workspaceid: currentWorkspaceId || getLS('currentWorkspaceId') || workspaceid,
    organizationid: currentOrganizationId || organizationid,
  });
  const { data: workspaces, isLoading: isLoadingWorkspaces } = getWorkspace({
    workspaceid: currentWorkspaceId || getLS('currentWorkspaceId'),
  });
  const pixelCount = useMemo(() => {
    return pixels?.data?.length || 0
  }, [pixels])
  const totalMatches = workspaces?.data?.contactsTotal || 0;
  const availableMatches = workspaces?.data?.contactsCount || 0;
  const availablePixels = workspaces?.data?.webPixelCount || 0;
  const uncreditedContactsCount = workspaces?.data?.uncreditedContactsCount || 0;
  const totalPixel = pixelCount + availablePixels
  const pixelAvailableIsLessThan20 = (availablePixels/totalPixel * 100) < 20;
  const matchesAvailableIsLessThan20 = (availableMatches/totalMatches * 100) < 20;

  const isInsufficientCredits = uncreditedContactsCount > availableMatches;

  useEffect(() => {
    if(workspaces?.data) {
      updateCurrentWorkspace({workspace: workspaces?.data})
    };

  }, [JSON.stringify(workspaces)])
  
  return (
    <MainCont>
      <BalanceCont>
        <IconCountCont>
          <ManagePixelIcon size={20} color={'#5D5E5F'} />
          <CountCont insufficient={pixelAvailableIsLessThan20}>
            <Typography fontWeight={800}>
              <ConditionalProgress isLoading={isLoadingWorkspaces} sizeInRem={10}>
                {availablePixels > 0 ? availablePixels : 'N/A'}
              </ConditionalProgress>
            </Typography>
          </CountCont>
          {pixelAvailableIsLessThan20 && <Tooltip placement={'top'} title={
            <Typography>
              Pixel Balance low at 20%
            </Typography>
          }>
            <InfoOutlined sx={{ fontSize: '20px', ml: '5px', color: '#D43125' }} />
          </Tooltip>}
        </IconCountCont>
        <Typography fontSize={12}>Pixel balance</Typography>
      </BalanceCont>
      <BalanceCont>
        <IconCountCont>
          <CaptureIcon size={20} color={''} />
          <CountCont insufficient={isInsufficientCredits || matchesAvailableIsLessThan20}>
            <Typography fontWeight={800}>
              <ConditionalProgress isLoading={isLoadingWorkspaces} sizeInRem={10}>
                {availableMatches > 0 ? availableMatches.toLocaleString() : 'N/A'}
              </ConditionalProgress>
            </Typography>
          </CountCont>
          {matchesAvailableIsLessThan20 && <Tooltip placement={'top'} title={
            <Typography>
              Match Balance low at 20%
            </Typography>
          }>
            <InfoOutlined sx={{ fontSize: '20px', ml: '5px', color: '#D43125' }} />
          </Tooltip>}
        </IconCountCont>
        <Typography fontSize={12}>Match balance</Typography>
      </BalanceCont>
    </MainCont>
  );
};

export default Balance;

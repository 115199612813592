import { SelectProps, MenuItem, FormControl, InputLabel, Stack, ListItemText } from '@mui/material';
import { StyledSelect } from './Select.styles';
import React, { ReactNode } from 'react';

interface IOption {
  label: string;
  secondary?: string;
  value: string | number;
  disabled?: boolean;
}

type SelectProp = Omit<SelectProps, 'variant'>

interface ISelectProps {
  size?: string;
  label?: string;
  options: IOption[];
  value: string | number;
  onChange: (value: any) => void;
  noLabel?: boolean;
  prefixIcon?: ReactNode;
  selectStyle?: any;
  formStyle?: React.CSSProperties;
  formWidth?: number | string;
}

function Select({ label, options, value, onChange, noLabel, prefixIcon, selectStyle, formStyle, size= 'small', formWidth }: ISelectProps & SelectProp) {
  return (
    <>
      <FormControl sx={{ minWidth: 120, width: formWidth || 120}} size={size} style={formStyle}>
        {!noLabel && (
          <InputLabel id="demo-simple-select-helper-label">
            <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
              {prefixIcon}
              {label}
            </Stack>
          </InputLabel>
        )}
        <StyledSelect
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          variant="outlined"
          value={value}
          {...(!noLabel
            ? {
                label: (
                  <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
                    {prefixIcon}
                    {label}
                  </Stack>
                ),
              }
            : {
                label: label,
                sx: {
                  backgroundColor: '#F8F8FF',
                  boxShadow: 'none',
                  '.MuiOutlinedInput-notchedOutline': { border: 0 },
                  '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                    border: 0,
                  },
                  '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    border: 0,
                  },
                  ...(selectStyle || {}),
                },
              })}
          placeholder={'Action'}
          onChange={onChange}
          MenuProps={{ sx: {
          maxHeight: '400px'
          }}}
        >
          {options.map((option) => {
            return (
              <MenuItem key={option.value} value={option.value} disabled={option.disabled}>
                <ListItemText primary={option?.label} secondary={option?.secondary} />
              </MenuItem>
            );
          })}
        </StyledSelect>
      </FormControl>
    </>
  );
}

export default Select;

import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useCheckAuthenticity } from '../hooks/useCheckAuthenticity';
import { ROUTES } from '../constants/ROUTES';

interface Props {}

export const PublicRoutes: React.FC<React.PropsWithChildren<Props>> = ({ children }) => {
  const location = useLocation();
  // Checking if user is authenticated
  const { isAuthenticated } = useCheckAuthenticity();
  const url = location.pathname;
  if (url.includes(ROUTES.MEMBER_SIGNUP)) return <React.Fragment>{children}</React.Fragment>;
  if (url.includes(ROUTES.GOOGLE_REDIRECT_URL)) return <React.Fragment>{children}</React.Fragment>;
  if (url.includes(ROUTES.ACTIVATION)) return <React.Fragment>{children}</React.Fragment>;
  if (url.includes(ROUTES.SIGN_IN_AS_OWNER_BY_ADMIN)) return <React.Fragment>{children}</React.Fragment>;
  if (url.includes(ROUTES.RESET_PASSWORD)) return <React.Fragment>{children}</React.Fragment>;
  if (url.includes(ROUTES.UNSUBSCRIBE)) return <React.Fragment>{children}</React.Fragment>;

  //if still authorized redirect to dashboard, if not bring to where wanted to
  return !isAuthenticated ? <>{children}</> : <Navigate to="/dashboard" />;
};

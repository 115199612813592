import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { FormControlLabel, Grid, IconButton, InputAdornment, FormHelperText } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import CustomTextField from 'app/shared/components/TextField/TextField';
import CustomCheckbox from 'app/shared/components/Checkbox/Checkbox';
import Button from 'app/shared/components/Button/Button';

import { useCheckAuthenticity } from 'app/shared/hooks/useCheckAuthenticity';
import { loginValidationSchema } from 'app/pages/Authentication/utils';
import { COLORS } from 'app/shared/constants/COLORS';
import { ConditionalProgress } from 'app/shared/components/ConditionalCircular/ConditionalCircular';
import { ROUTES } from 'app/shared/constants/ROUTES';
import { ls } from 'app/shared/utils/ls';
import { ErrorLabel } from 'app/shared/components/Pixels/CreatePixel/CreatePixelModal.styles';
import PromptModal from 'app/shared/modals/PromptModal';
import { CustomModal } from 'app/shared/modals/CustomModal';

const Login = () => {
  const { signIn, authenticating } = useCheckAuthenticity();
  const navigate = useNavigate();
  const [showErrorModal, setShowErrorModal] = useState(false)
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember_me: false,
    },
    validationSchema: loginValidationSchema,
    onSubmit: async (values) => {
      setError('');
      const response: any = await signIn({
        username: values?.email?.trim()?.toLowerCase() || '',
        password: values.password,
      });
      if (response?.status === 'error') {
        if(!response?.data?.isActivated && response?.data?.isActivated !== undefined){
          setShowErrorModal(true)
          ls().setLS('email', response?.data?.email)
        }else {
          setError(response?.message || '');
        }
      }
    },
  });

  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');

  const handleSignup = () => {
    window.open('https://vitaledge.io/#pricing');
  };

  const handleVerification = () => {
    navigate(ROUTES.RESEND_VERIFICATION);
  };

  const handleForgotPassword = () => {
    navigate(ROUTES.FORGOT_PASSWORD);
  };

  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <CustomTextField
          fullWidth
          id="email"
          name="email"
          placeholder="Email"
          value={formik.values.email}
          onChange={(e) => {
            formik.handleChange(e);
            setError('');
          }}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          variant="standard"
          InputLabelProps={{
            shrink: true,
          }}
          className="mb-4 custom-login-input"
        />
        <CustomTextField
          fullWidth
          type={showPassword ? 'text' : 'password'}
          id="password"
          name="password"
          placeholder="Password"
          value={formik.values.password}
          onChange={(e) => {
            formik.handleChange(e);
            setError('');
          }}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          variant="standard"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                  {!showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          className="mb-16 custom-login-input"
        />
        <FormHelperText error className="custom-error-text">
          {error}
        </FormHelperText>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={6}>
            <FormControlLabel
              className="remember-me"
              checked={formik.values.remember_me}
              control={<CustomCheckbox />}
              onChange={() => {
                formik.setFieldValue('remember_me', !formik.values.remember_me);
              }}
              label="Remember me"
            />
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <span className="button-text" onClick={handleForgotPassword}>
              Forgot Password?
            </span>
          </Grid>
        </Grid>
        <Grid xs={12}>
          <Button
            type="submit"
            variant="contained"
            size="large"
            fullWidth
            className="submit-button"
            disabled={authenticating || !formik.dirty}
          >
            <ConditionalProgress isLoading={authenticating} color={COLORS.PRIMARY}>
              Sign In
            </ConditionalProgress>
          </Button>
        </Grid>
        <Grid container xs={12} justifyContent="center" alignItems="center" className="signup-btn-wrapper">
          Don't have an account?
          <span className="signup-btn-text" onClick={handleSignup}>
            Sign Up
          </span>
        </Grid>
        <CustomModal
          open={showErrorModal}
          onClose={() => {
            setShowErrorModal(false)
          }}
        >
        <PromptModal
          type={'error'}
          title={'Email not yet verified'}
          content={
            <>
              <ErrorLabel>
               Your email has not been verified or activated
              </ErrorLabel>
            </>
          }
          successLabel={'Resend Verification'}
          successAction={handleVerification}
        />
        </CustomModal>
      </form>
    </>
  );
};

export default Login;

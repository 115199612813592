import React, { useState, ChangeEvent } from 'react';
import { TextFieldProps } from '@mui/material/TextField';
import { StyledTextField } from "./TextField.styles";

interface NumberInputTextFieldProps extends Omit<TextFieldProps, 'onChange' | 'value'> {
  initialValue: number;
  onChange: (value: number) => void;
}

const NumberInputTextField: React.FC<NumberInputTextFieldProps> = ({
                                                                     initialValue = 0,
                                                                     onChange = () => {}, // Default empty function
                                                                     ...rest // Additional props spread
                                                                   }) => {
  const [value, setValue] = useState<number>(initialValue);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue: string = event.target.value;
    const regex: RegExp = /^[0-9]*$/;

    if (regex.test(inputValue)) {
      setValue(Number(inputValue));
      onChange(Number(inputValue));
    }
  };

  return (
    <StyledTextField
      value={value}
      onChange={handleChange}
      sx={{
        '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
          padding: '4px 8px',
          fontSize: '16px'
        }
      }}
      {...rest}
    />
  );
}

export default NumberInputTextField;

import { useMutation, useQuery } from '@tanstack/react-query';
import { billingDao } from '../dao/billingDao';

import { IAddCardRequestPayload, ICardRequestPayload, IDefaultCardRequestPayload, IPaymentMethodRequestPayload } from '../types/billing.types';
const { 
  paymentMethods: paymentMethodsDao, 
  invoices: invoicesDao, 
  createAlternativeCard: createAlternativeCardDao,
  deleteCard: deleteCardDao,
  getStripeCustomer: getStripeCustomerDao,
  setDefaultCard: setDefaultCardDao,
} = billingDao();

export const billingServices = () => {

  const paymentMethods = () => {
    return useMutation((payload: IPaymentMethodRequestPayload) => paymentMethodsDao(payload));
  };

  const billingHistory = (payload: string) => {
    return useQuery(['stripeCustomerId'], () => invoicesDao(payload));
  }

  const createAlternativeCard = () => {
    return useMutation((payload: IAddCardRequestPayload) => createAlternativeCardDao(payload));
  };

  const deleteCard = () => {
    return useMutation((payload: string) => deleteCardDao(payload))
  }

  const getStripeCustomer = (payload: string) => {
    return useQuery(['getStripeCustomer'], () => getStripeCustomerDao(payload));
  }

  const setDefaultCard = () => {
    return useMutation((payload: IDefaultCardRequestPayload) => setDefaultCardDao(payload));
  };

  return {
    paymentMethods,
    billingHistory,
    createAlternativeCard,
    deleteCard,
    getStripeCustomer,
    setDefaultCard,
  };
};

import React from 'react'
import { useNavigate } from 'react-router-dom';

import { MainCont } from './TrialExpired.style';
import { useChangeWorkspace } from 'app/shared/hooks/useChangeWorkspace';
import { useUserJobRole } from 'app/shared/hooks/useUserJobRole';



const TrialExpired = () => {
    const navigate = useNavigate();
    const { jobRole } = useUserJobRole();
    const { currentWorkspaceId, workspacesList, currentOrganizationId } = useChangeWorkspace();
    const currentWorkspace = workspacesList.find((item: any) => item?.id === currentWorkspaceId);

    const uncreditedContactsCount = currentWorkspace?.uncreditedContactsCount || 0;
  
    const notallowed = (jobRole)?.toLowerCase() === 'member'


    if(!currentWorkspace?.trialExpired  || currentWorkspace?.planName === 'free') return <></>;

    return (
        <>
            <MainCont severity="error">
                {uncreditedContactsCount > 0 ? `${ uncreditedContactsCount?.toLocaleString('en-US')} match${uncreditedContactsCount > 1 ? 'es' : ''} not shown.&nbsp;` : ''}
                Your trial period has expired.
                {
                    !notallowed ? <>
                        <span
                          style={{ textDecoration: 'underline', cursor: 'pointer' }}
                          onClick={() => {
                              // navigate('/pricing')
                              navigate('/upgrade-change-plan', {state: {message: 'You currently do not have pixel balance in your account. Please contact our administrator in upgrading or changing your plan.'}});
                          }}
                        >Please see options here.</span>
                    </> : <span>Please contact your workspace owner to upgrade the account.</span>
                }
            </MainCont>
        </>
    )
}

export default TrialExpired
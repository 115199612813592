import React, { useState } from 'react';
import CheckYourInbox from 'app/components/Authentication/CheckYourInbox';
import { capitalizeFirstLetter } from 'app/shared/utils/helpers';
import { useSnackbar } from 'notistack';
import { authDao } from 'app/shared/dao/authDao';
import { ls } from 'app/shared/utils/ls';

const ResendVerification = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [resending, setResending] = useState(false);
  const { resendActivation } = authDao();
  const handleResendActivationLink = async () => {
    setResending(true);
    const res = await resendActivation(ls().getLS('email'));

    if (res.status === 'success') {
      enqueueSnackbar('Successfully resent activation link', { variant: 'success' });
    } else {
      enqueueSnackbar(capitalizeFirstLetter(res?.data?.message || res?.message || ''), { variant: 'error' });
    }
    setResending(false);
  };
  return (
    <>
      <CheckYourInbox
        withResend
        withBackSignIn={true}
        details='An email verification link has been sent to your inbox.'
        handleResend={handleResendActivationLink}
        isLoading={resending}
      />
    </>
  );
};

export default ResendVerification;

import { ENDPOINTS } from '../constants/ENDPOINTS';
import { useAxios } from '../hooks/useAxios';
import { IDefaultResponsePayload } from '../types/auth.types';
import {
  IAuthResponsePayload,
  ICreateSheetRequestPayload,
  ICreateSheetResponsePayload,
  IGenerateURLResponsePayload,
  IListSheetResponsePayload,
} from '../types/google.types';

const { GET, POST, DELETE, PUT } = useAxios();

export const googleDao = () => {
  const connect = async (authCode: string, workspaceid: string) => {
    const response = await POST<IAuthResponsePayload>({
      url: `${ENDPOINTS.GOOGLE_SERVICE}/auth/v2`,
      data: {
        authCode,
        workspaceid,
      },
    } as any);

    return response?.data;
  };

  const generateUrl = async () => {
    const response = await GET<IGenerateURLResponsePayload>({
      url: `${ENDPOINTS.GOOGLE_SERVICE}/auth/generate_url/v2`,
    } as any);

    return response?.data;
  };

  const createUpdateSpreadsheet = async (payload: ICreateSheetRequestPayload) => {
    const response = await POST<ICreateSheetResponsePayload>({
      url: `${ENDPOINTS.GOOGLE_SERVICE}/spreadsheet/v2`,
      data: payload,
    } as any);

    return response.data;
  };

  const spreadsheetList = async (googleId: string) => {
    const response = await GET<IListSheetResponsePayload>({
      url: `${ENDPOINTS.GOOGLE_SERVICE}/spreadsheet/api/list/v2?googleAccountid=${encodeURIComponent(googleId)}`,
    } as any);

    return response.data;
  };

  const disconnect = async (id: string, workspaceid: string) => {
    const response = await DELETE<IDefaultResponsePayload>({
      url: `${ENDPOINTS.GOOGLE_SERVICE}/spreadsheet?id=${encodeURIComponent(id)}&workspaceid=${encodeURIComponent(
        workspaceid,
      )}`,
    } as any);

    return response?.data;
  };

  const filterSheetRows = async (id: string, workspaceid: string) => {
    const response = await PUT<any>({
      url: `${ENDPOINTS.GOOGLE_SERVICE}/spreadsheet/filter-rows?id=${encodeURIComponent(id)}&workspaceid=${encodeURIComponent(
        workspaceid,
      )}`,
    } as any);

    return response?.data;
  };

  return {
    generateUrl,
    connect,
    createUpdateSpreadsheet,
    spreadsheetList,
    disconnect,
    filterSheetRows,
  };
};

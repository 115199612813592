import { styled, Typography } from "@mui/material";
import { COLORS } from "app/shared/constants/COLORS";

export const ValidationWrapper = styled('div')(() => ({
    '.success': {
        color: COLORS.GAUGE_GREEN
    },

    '.error': {
        color: COLORS.ERROR
    },
}));

export const ValidationText = styled(Typography)(() => ({
    fontSize: '14px',
    color: '#5D5E5F',
    fontWeight: 500,
    marginBottom: '12px',

    '&.header': {
        color: COLORS.BUTTON_BLACK,
        marginBottom: '16px',
    },

    'span': {
        verticalAlign: 'middle',
        marginRight: '8px',
    }
}));

export const tooltipStyle = {
    popper: {
        sx: {
            marginLeft: '40px !important',
        }
    },
    tooltip: {
        sx: {
            background: COLORS.BG_WHITE,
            color: COLORS.TEXT_GRAY,
            border: `1px solid ${COLORS.BORDER_GRAY}`,
            borderRadius: '12px',
            padding: '20px 80px 20px 24px',
            fontSize: '14px',
            fontWeight: 500,
        }
    }
}
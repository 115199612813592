import { TextField, styled } from '@mui/material';

import { COLORS } from '../../constants/COLORS';

export const StyledTextField = styled(TextField)(() => ({
  '& .MuiFormLabel-root.MuiInputLabel-root': {
    color: COLORS.LABEL_NORMAL,
    fontSize: '14px',
    transform: 'none',
  },

  '& .MuiInputBase-root.MuiInput-root.MuiInput-underline': {
    borderRadius: '4px',
    border: `1px solid ${COLORS.BORDER_GRAY}`,
    padding: '12px',
    fontSize: '16px',
    marginTop: '24px',

    '&.Mui-error': {
      border: `1px solid ${COLORS.ERROR_DARK}`,
    },

    '&:placeholder': {
      color: COLORS.TEXT_GRAY,
    },

    '&::before': {
      display: 'none',
    },

    '&:not(.Mui-disabled):hover': {
      '&::before': {
        display: 'none',
      },
      border: `1px solid ${COLORS.PRIMARY}`,
    },
  },

  '& .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-colorSuccess': {
    border: `1px solid ${COLORS.SUCCESS}`,
  },

  '.MuiFormHelperText-root': {
    fontSize: '14px',
    marginTop: '4px',
  },

  '.MuiFormHelperText-root.Mui-error': {
    color: COLORS.ERROR_DARK,
  },

  '& .MuiInputBase-root.MuiInput-root.MuiInput-underline.Mui-focused': {
    border: `1px solid ${COLORS.PRIMARY}`,
    color: COLORS.PRIMARY,
    '&::after': {
      display: 'none',
    },
  },

  '& .MuiInputBase-root.MuiInput-root.MuiInput-underline.Mui-disabled': {
    background: COLORS.BG_SIDEBAR,
  }
}));

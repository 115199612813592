import { ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from 'app/shared/components/Button/Button';

import VitalEdgeLogo from 'assets/images/logo_dark.png';
import CheckYourInboxIllustration from 'assets/images/check_your_inbox.svg';
import CircularProgressWithLabel from 'app/shared/components/CircularProgressWithLabel/CircularProgressWithLabel';
import { ConditionalProgress } from 'app/shared/components/ConditionalCircular/ConditionalCircular';
import { COLORS } from 'app/shared/constants/COLORS';

interface IProps {
    withTimer?: boolean;
    withResend?: boolean;
    withBackSignIn?: boolean;
    timer?: number;
    details?: ReactNode;
    handleResend?: () => void;
    isLoading?: boolean;
    hideLogo?: boolean;
}

const CheckYourInbox = ({
    withTimer = false,
    withResend = false,
    withBackSignIn = true,
    timer = 0,
    details = '',
    handleResend = () => {},
    isLoading = false,
    hideLogo = false,
}: IProps) => {
    const navigate = useNavigate();
    const [timeLeft, setTimeLeft] = useState(timer);
    let timerTimeout: any = null

    const handleBackSignIn = () => {
        navigate('/');
    }

    useEffect(() => {
        if(withTimer && timer) {
            timerTimeout = timeLeft > -1 && setInterval(() => {
                timer = timer - 1;
                setTimeLeft((a) => a - 1)
            }, 1000)
        }

        if(!withTimer && timerTimeout) {
            clearInterval(timerTimeout)
        }

        return () => {
            if(timerTimeout) {
                clearInterval(timerTimeout)
            }
        }
    }, [timeLeft, withTimer, timer])

    return (
        <>
            <div>
                {!hideLogo && <img src={VitalEdgeLogo} alt="Vital Edge" />}
                <div className='title'>Check your inbox!</div>
                <div className='supporting'>{details || 'Password reset link has been sent to your inbox.'}</div>
                <div>
                    <img src={CheckYourInboxIllustration} alt="Check your inbox" className='illustration'/>
                </div>
                <div className="buttons-wrapper">
                    {
                        (withTimer && timeLeft > -1) ? (
                            <div className="resend-now-wrapper">
                                {withTimer && <CircularProgressWithLabel label={timeLeft} />}
                                <div className="resend-now-btn" onClick={handleResend}>
                                    <ConditionalProgress isLoading={isLoading} color={COLORS.PRIMARY}>
                                        Resend Now
                                    </ConditionalProgress>
                                </div>
                            </div>
                        ) : ''
                    }
                    {
                        !withTimer && withResend ? (
                            <div className="resend-now-wrapper">
                                <div className="resend-now-btn" onClick={handleResend}>
                                    <ConditionalProgress isLoading={isLoading} color={COLORS.PRIMARY}>
                                        Resend Now
                                    </ConditionalProgress>
                                </div>
                            </div>
                        ) : ''
                    }
                    {
                        withBackSignIn ? (
                            <Button variant="contained" size="large" className="back-to-sign-in-button" onClick={handleBackSignIn}>
                                Back to sign in
                            </Button>
                        ) : ''
                    }
                </div>
            </div>
        </>
    )
};

export default CheckYourInbox;

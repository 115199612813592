import React, { useState, useEffect, useMemo } from 'react';
import {
  PaymentMethodCont,
  FieldCont,
  updateButton,
  AmountCont,
} from './PaymentMethod.styles';
import { Button, Grid, MenuItem, Stack, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { enqueueSnackbar } from 'notistack';

import CustomTextField from 'app/shared/components/TextField/TextField';
import { ConditionalProgress } from 'app/shared/components/ConditionalCircular/ConditionalCircular';

import { COLORS } from 'app/shared/constants/COLORS';
import { nmiPaymentDetailsValidationSchema } from 'app/pages/Authentication/utils';
import { capitalizeFirstLetter, numberWithCommas } from 'app/shared/utils/helpers';
import { ls } from 'app/shared/utils/ls';
import { pricingDao } from 'app/shared/dao/pricingDao';
import { useChangeWorkspace } from 'app/shared/hooks/useChangeWorkspace';
import COUNTRIES from '../../../../constants/COUNTRIES';
import { paymentServices } from '../../../../services/paymentServices';
import VitalEdgeLoader from '../../../Loader/VitalEdgeLoader';

interface Props {
  handleSubmit: (e?: any) => void;
  paymentCards: any[];
  selectedPlan: string;
  workspaceName: string;
  period: string;
  setSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
  setApplying: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: VoidFunction;
}

const { addCustomerCardDetails } = paymentServices();
const { plans, retrieveOrderSummary } = pricingDao();
const NMIPaymentMethod: React.FC<Props> = (props: Props) => {
  const { getLS, setLS } = ls();
  const { workspacesList, currentWorkspaceId } = useChangeWorkspace();
  const user = JSON.parse(getLS('user') || '{}');
  const currentWorkspace = workspacesList?.find((w: any) => w?.id === currentWorkspaceId);

  const [planInfo, setPlanInfo] = useState<any>({});
  const [orderSummary, setOrderSummary] = useState<any>({});
  const [ccAdding, setccAdding] = useState(false);
  const [isUsePreviouslySaved, setUsePreviouslySaved] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<string>('');
  const [stateOptions, setStateOptions] = useState<string[]>([]);

  const { mutate, isLoading } = addCustomerCardDetails();

  useEffect(() => {
    const getPlanInfo = async () => {
      const p = props?.selectedPlan?.replace(/\s/g, '')?.toLowerCase();
      const response = await plans(currentWorkspace?.pricingVersion);
      const planInfo = response?.data?.find((plan: any) => plan?.planName === p);
      setPlanInfo(planInfo);
    };

    getPlanInfo();
  }, [props?.selectedPlan, currentWorkspace?.pricingVersion]);

  useEffect(() => {
    const fetchOrderSummary = async () => {
      props?.setApplying(true);
      const planInterval = props?.period === 'yearly' ? 'annually' : 'monthly';
      const retrievedOrderSummary = await retrieveOrderSummary({
        id: planInfo?.id,
        planInterval,
      });
      setOrderSummary(retrievedOrderSummary?.data);
      props?.setApplying(false);
    };

    fetchOrderSummary();
  }, [planInfo, props?.period]);

  useEffect(() => {
    const country = COUNTRIES.find((c) => c.country === selectedCountry);
    setStateOptions(country?.states || []);
  }, [selectedCountry]);

  const formik = useFormik({
    initialValues: {
      ccnumber: '',
      ccexp: '',
      cvv: '',
      first_name: '',
      last_name: '',
      address1: '',
      city: '',
      state: '',
      zip: '',
      country: '',
      phone: '',
      email: '',
    },
    enableReinitialize: true,
    validationSchema: nmiPaymentDetailsValidationSchema,
    onSubmit: async (values) => {
      setccAdding(true)
      mutate({
        ccnumber: values.ccnumber,
        ccexp: values.ccexp,
        cvv: values.cvv,
        first_name: values.first_name,
        last_name: values.last_name,
        address1: values.address1,
        city: values.city,
        state: values.state,
        zip: values.zip,
        country: values.country,
        phone: values.phone,
        email: values.email,
      }, {
        onSuccess: (response: any) => {
          if (response?.customerVaultId) {
            handleSubmit({ customerVaultId: response?.customerVaultId });
          } else {
            enqueueSnackbar(capitalizeFirstLetter(response?.message || ''), { variant: 'error' });
            setccAdding(false);
          }
        },
        onError: (response: any) => {
          enqueueSnackbar(capitalizeFirstLetter(response?.message || ''), { variant: 'error' });
          setccAdding(false);
        },
      });
    },
  });

  const handleSubmit = async (responsePM?: any) => {
    let registerParams: any = {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      workspaceName: props.workspaceName,
      organizationid: user.organizationid,
      creditPlanInterval: props?.period === 'yearly' ? 'annually' : 'monthly',
      pricingVersion: currentWorkspace?.pricingVersion,
      plan: props.selectedPlan.replace(/\s/g, '').replace(/([A-Z])/g, (match) => match.toLowerCase()),
      price: orderSummary?.total,
      stripePaymentMethodId: '',
      customerVaultId: responsePM?.customerVaultId,
    };

    mutate(registerParams, {
      onSuccess: (res) => {
        setccAdding(false)
        formik.resetForm();
        props.handleSubmit();
      },
      onError: (err: any) => {
        setccAdding(false)
        console.log('err: ', err);
        enqueueSnackbar(capitalizeFirstLetter(err?.message || ''), { variant: 'error' });
      },
    });
  };

  return (
    <PaymentMethodCont>
      <Typography fontSize={24} fontWeight={700}>
        Add Payment Method
      </Typography>
      <FieldCont>
        <Stack direction={'row'} spacing={2}>
          <CustomTextField
            fullWidth
            name='first_name'
            label='First Name'
            value={formik.values.first_name}
            onChange={formik.handleChange}
            error={formik.touched.first_name && Boolean(formik.errors.first_name)}
            helperText={formik.touched.first_name && formik.errors.first_name}
            variant='standard'
            InputLabelProps={{
              shrink: true,
            }}
            className='mb-4'
          />
          <CustomTextField
            fullWidth
            name='last_name'
            label='Last Name'
            value={formik.values.last_name}
            onChange={formik.handleChange}
            error={formik.touched.last_name && Boolean(formik.errors.last_name)}
            helperText={formik.touched.last_name && formik.errors.last_name}
            variant='standard'
            InputLabelProps={{
              shrink: true,
            }}
            className='mb-4'
          />
        </Stack>
        <Stack direction={'row'} spacing={2}>
          <CustomTextField
            select
            style={{ marginTop: 20 }}
            fullWidth
            name='country'
            label='Country'
            value={formik.values.country}
            onChange={(e) => {
              setSelectedCountry(e.target.value);
              formik.setFieldValue('country', e.target.value);
              formik.setFieldValue('state', '');
            }}
            error={formik.touched.country && Boolean(formik.errors.country)}
            helperText={formik.touched.country && formik.errors.country}
            variant='standard'
            InputLabelProps={{
              shrink: true,
            }}
            className='mb-4'
          >
            <MenuItem disabled value="">
                <span style={{ color: COLORS.TEXT_GRAY, opacity: 0.6 }}>
                  Country
                </span>
            </MenuItem>
            {COUNTRIES.map(({ country }) => (
              <MenuItem key={country} value={country}>
                {country}
              </MenuItem>
            ))}
          </CustomTextField>
          <CustomTextField
            style={{ marginTop: 20 }}
            fullWidth
            name='city'
            label='City'
            value={formik.values.city}
            onChange={formik.handleChange}
            error={formik.touched.city && Boolean(formik.errors.city)}
            helperText={formik.touched.city && formik.errors.city}
            variant='standard'
            InputLabelProps={{
              shrink: true,
            }}
            className='mb-4'
          />
          <CustomTextField
            select
            style={{ marginTop: 20 }}
            fullWidth
            name='state'
            label='State'
            value={formik.values.state}
            onChange={formik.handleChange}
            error={formik.touched.state && Boolean(formik.errors.state)}
            helperText={formik.touched.state && formik.errors.state}
            variant='standard'
            InputLabelProps={{
              shrink: true,
            }}
            className='mb-4'
          >
            <MenuItem disabled value="">
                <span style={{ color: COLORS.TEXT_GRAY, opacity: 0.6 }}>
                  State
                </span>
            </MenuItem>
            {stateOptions.map((state) => (
              <MenuItem key={state} value={state}>
                {state}
              </MenuItem>
            ))}
          </CustomTextField>
        </Stack>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <CustomTextField
              style={{ marginTop: 20 }}
              fullWidth
              name='address1'
              label='Address'
              value={formik.values.address1}
              onChange={formik.handleChange}
              error={formik.touched.address1 && Boolean(formik.errors.address1)}
              helperText={formik.touched.address1 && formik.errors.address1}
              variant='standard'
              InputLabelProps={{
                shrink: true,
              }}
              className='mb-4'
            />
          </Grid>
          <Grid item xs={4}>
            <CustomTextField
              style={{ marginTop: 20 }}
              fullWidth
              name='zip'
              label='Zip'
              value={formik.values.zip}
              onChange={formik.handleChange}
              error={formik.touched.zip && Boolean(formik.errors.zip)}
              helperText={formik.touched.zip && formik.errors.zip}
              variant='standard'
              InputLabelProps={{
                shrink: true,
              }}
              className='mb-4'
            />
          </Grid>
        </Grid>
        <Stack direction={'row'} spacing={2}>
          <CustomTextField
            style={{ marginTop: 20 }}
            fullWidth
            name='email'
            label='Email'
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            variant='standard'
            InputLabelProps={{
              shrink: true,
            }}
            className='mb-4'
          />
          <CustomTextField
            style={{ marginTop: 20 }}
            fullWidth
            name='phone'
            label='Phone'
            value={formik.values.phone}
            onChange={formik.handleChange}
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            helperText={formik.touched.phone && formik.errors.phone}
            variant='standard'
            InputLabelProps={{
              shrink: true,
            }}
            className='mb-4'
          />
        </Stack>
        <CustomTextField
          style={{ marginTop: 20 }}
          fullWidth
          name='ccnumber'
          label='Card Number'
          placeholder='0000 0000 0000 0000'
          value={
            formik.values.ccnumber
              .replace(/[^0-9]/g, '')
              .replace(/(\d{4})/g, '$1 ')
              .trim()
          }
          onChange={formik.handleChange}
          error={formik.touched.ccnumber && Boolean(formik.errors.ccnumber)}
          helperText={formik.touched.ccnumber && formik.errors.ccnumber}
          variant='standard'
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{ maxLength: 19 }}
          className='mb-4'
        />
      </FieldCont>
      <Stack
        direction={'row'}
        spacing={2}
        sx={{ my: '20px' }}
      >
        <CustomTextField
          name='ccexp'
          label='Expiry Date'
          placeholder='MM/YY'
          value={
            formik.values.ccexp
              .replace(/[^0-9]/g, '')
              .replace(/^([2-9])$/g, '0$1')
              .replace(/^(1{1})([3-9]{1})$/g, '0$1/$2')
              .replace(/^0{1,}/g, '0')
              .replace(/^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g, '$1/$2')
              .trim()
          }
          onChange={formik.handleChange}
          error={formik.touched.ccexp && Boolean(formik.errors.ccexp)}
          helperText={formik.touched.ccexp && formik.errors.ccexp}
          variant='standard'
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{ maxLength: 5 }}
          className='mb-4'
        />
        <CustomTextField
          type='password'
          name='cvv'
          label='CVV'
          placeholder='****'
          value={
            formik.values.cvv
              .replace(/[^0-9]/g, '')
              .trim()
          }
          onChange={formik.handleChange}
          error={formik.touched.cvv && Boolean(formik.errors.cvv)}
          helperText={formik.touched.cvv && formik.errors.cvv}
          variant='standard'
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{ maxLength: 4 }}
          className='mb-4'
        />
      </Stack>
      {orderSummary?.planPrice !== undefined &&
      orderSummary?.planPrice !== null &&
      orderSummary?.discountedAmount !== undefined &&
      orderSummary?.discountedAmount !== null ? (
        <AmountCont container>
          <Grid item xs={4}>
            Subscription Amount
          </Grid>
          <Grid item xs={8} style={{ textAlign: 'right' }}>
            ${numberWithCommas((orderSummary?.planPrice).toFixed(2))}
          </Grid>
        </AmountCont>
      ) : (
        ''
      )}
      {orderSummary?.discountedAmount !== undefined && orderSummary?.discountedAmount !== null ? (
        <AmountCont container>
          <Grid item xs={4}>
            Discounted Amount
          </Grid>
          <Grid item xs={8} style={{ textAlign: 'right' }}>
            - ${numberWithCommas((orderSummary?.discountedAmount).toFixed(2))}
          </Grid>
        </AmountCont>
      ) : (
        ''
      )}
      {orderSummary?.total !== undefined && orderSummary?.total !== null ? (
        <AmountCont container>
          <Grid item xs={4}>
            Total Due Today
          </Grid>
          <Grid item xs={8} style={{ textAlign: 'right' }}>
            ${numberWithCommas(orderSummary?.total?.toFixed(2))}
          </Grid>
        </AmountCont>
      ) : (
        ''
      )}
      <Button
        sx={updateButton}
        type="submit"
        variant="contained"
        size="large"
        fullWidth
        onClick={() => formik.handleSubmit()}
        disabled={!formik.dirty || ccAdding}
      >
        <ConditionalProgress isLoading={ccAdding} color={COLORS.PRIMARY}>
          Pay Now
        </ConditionalProgress>
      </Button>
      <VitalEdgeLoader isOpen={ccAdding}/>
    </PaymentMethodCont>
  );
};

export default NMIPaymentMethod;

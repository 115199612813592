import axios, { AxiosRequestHeaders, AxiosResponse, ResponseType } from 'axios';
import moment from 'moment';
import jwt_decode from 'jwt-decode';

import { authToken } from '../utils/authToken';
import { ls } from '../utils/ls';
import { CONFIG } from '../config';

export interface IAxios<P, B> {
  url?: string;
  params?: P;
  body?: B;
  data?: B;
  headers?: AxiosRequestHeaders;
  responseType?: ResponseType;
  onUploadProgress?: (event: any) => void;
}

export const useAxios = () => {
  const { setLS } = ls();
  const { getAuthToken } = authToken();

  const handleTokenExpired = (Authorization: any) => {
    if(Authorization) {
      const currentToken = Authorization?.split(' ')?.[1];
      const decodedToken: any = jwt_decode(currentToken);
      const dateNow = moment();
      const expDate = moment.unix(decodedToken.exp);

      if (dateNow > expDate) {
        try {
          setLS('isSessionExpired', 'true');
          return;
        } catch (err) {
          setLS('isSessionExpired', 'true');
          return Promise.reject(err);
        }
      }
    }
  }

  const handleSuccess = (response: AxiosResponse) => {
    console.log(
      `
        %c response from ${response.config.url}\n`,
      'color:white;background-color:#1890ff;padding:5px;border-radius:5px;',
      response.data,
    );
    if(response.config.headers.Authorization) {
      handleTokenExpired(response?.config?.headers?.Authorization);
    }
    return response;
  };

  const handleError = (error: any) => {
    if (error.code === 'ERR_NETWORK') {
      handleTokenExpired(error?.config?.headers?.Authorization);
    }
    return Promise.reject(error);
  };

  const handleHeaders = ({ url, headers }: IAxios<any, any>) => {
    const token = getAuthToken();
    let tokenAuthorization = {};

    if (token) {
      tokenAuthorization = {
        Authorization: `Bearer ${token}`,
      };
    }

    let service = axios.create({
      baseURL: url?.trim() || '',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': CONFIG.X_API_KEY,
        ...headers,
        ...tokenAuthorization
      },
    });

    service.interceptors.response.use(handleSuccess, handleError);
    
    return service;
  };

  const GET = async <R, P = unknown, B = unknown>(args: IAxios<P, B>): Promise<AxiosResponse<R>> => {
    try {
      // return await instance(args, 'GET');
      const service = handleHeaders(args);
      return await service({ ...args, method: 'GET' });
    } catch (e) {
      throw e;
    }
  };

  const POST = async <R, P = unknown, B = unknown>(args: IAxios<P, B>): Promise<AxiosResponse<R>> => {
    try {
      // return await instance(args, 'POST');
      const service = handleHeaders(args);
      return await service({ ...args, method: 'POST' });
    } catch (e) {
      throw e;
    }
  };

  const PUT = async <P, B = unknown>(args: IAxios<P, B>): Promise<AxiosResponse> => {
    try {
      // return await instance(args, 'PUT');
      const service = handleHeaders(args);
      return await service({ ...args, method: 'PUT' });
    } catch (e) {
      throw e;
    }
  };

  const PATCH = async <P, B = unknown>(args: IAxios<P, B>): Promise<AxiosResponse> => {
    try {
      // return await instance(args, 'PATCH');
      const service = handleHeaders(args);
      return await service({ ...args, method: 'PATCH' });
    } catch (e) {
      throw e;
    }
  };

  const DELETE = async <P, B = unknown>(args: IAxios<P, B>): Promise<AxiosResponse> => {
    try {
      // return await instance(args, 'DELETE');
      const service = handleHeaders(args);
      return await service({ ...args, method: 'DELETE' });
    } catch (e) {
      throw e;
    }
  };


  return {
    GET,
    POST,
    PUT,
    DELETE,
  };

};
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Grid, IconButton, InputAdornment } from '@mui/material'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { CustomModal } from 'app/shared/modals/CustomModal';
import { ConditionalProgress } from 'app/shared/components/ConditionalCircular/ConditionalCircular';
import ThankYouView from '../ThankYouView/ThankYouView';
import PaymentDetailsModal from './Modals/PaymentDetailsModal';
import CustomTextField from 'app/shared/components/TextField/TextField';
import Button from 'app/shared/components/Button/Button';
import CheckYourInbox from '../CheckYourInbox/CheckYourInbox';
import VitalEdgeLoader from 'app/shared/components/Loader/VitalEdgeLoader';

import { userDao } from 'app/shared/dao/userDao';
import { stripeDao } from 'app/shared/dao/stripeDao';
import { pricingDao } from 'app/shared/dao/pricingDao';
import { authDao } from 'app/shared/dao/authDao';
import { COLORS } from 'app/shared/constants/COLORS';
import { SIGNUP_PAYLOAD } from 'app/shared/constants/PAYLOAD';
import { signupValidationSchema } from 'app/pages/Authentication/utils';
import { capitalizeFirstLetter, startCase } from 'app/shared/utils/helpers';
import { ROUTES } from 'app/shared/constants/ROUTES';
import PasswordValidationPopover from './PasswordValidationPopover';
import { IRegisterUserRequestPayload } from 'app/shared/types/user.types';
import ReactGA from 'react-ga4';
import { useCheckAuthenticity } from 'app/shared/hooks/useCheckAuthenticity';
import NMIPaymentDetailsModal from './Modals/NMIPaymentDetailsModal';

const Signup = () => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    let [searchParams] = useSearchParams();
    const subscription: any = searchParams.get('subscription') || '';
    const plan = searchParams.get('plan') || '';
    const version = searchParams.get('version') || '';

    const { register, isExisting } = userDao();
    const { paymentMethodAttach } = stripeDao();
    const { plans, retrieveOrderSummary } = pricingDao();
    const { resendActivation } = authDao()
    const { handleSignInSuccess, setAuthenticating, authenticating } = useCheckAuthenticity();

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            companyName: '',
            password: '',
            confirm_password: '',
            remember_me: false,
        },
        validationSchema: signupValidationSchema,
        onSubmit: async (values) => {
            const referrerid = searchParams.get('ref') || '';
            const refid = searchParams.get('refid') || '';

            if(SIGNUP_PAYLOAD.PLANS?.includes(plan)) {
                setSubmitting(true);
                if(plan === 'free') {
                    setAuthenticating(true);
                    let registerParams: IRegisterUserRequestPayload = {
                        email: values?.email?.trim()?.toLowerCase(),
                        password: values.password,
                        firstName: values.firstName?.trim(),
                        lastName: values.lastName?.trim(),
                        clientName: `${values.firstName} ${values.lastName}'s Workspace`,
                        companyName: values.companyName,
                        how_will_you_use_the_platform: 'company',
                        job_role: SIGNUP_PAYLOAD.JOB_ROLE.OWNER ,
                        source: SIGNUP_PAYLOAD.SOURCE.VITAL_EDGE,
                        creditPlanInterval: subscription || '',
                        pricingVersion: SIGNUP_PAYLOAD.VERSIONS.indexOf(atob(version)) + 1,
                        plan,
                        price: "0",
                        couponCode: "",
                        couponId: "",
                        stripePaymentMethodId: "",
                    }
                    if(referrerid) {
                        registerParams = {
                            ...registerParams,
                            referrerid,
                        }
                    }

                    if(refid) {
                        registerParams = {
                            ...registerParams,
                            refid
                        }
                    }

                    const res = await register(registerParams);

                    if(res?.status === 'success') {
                        setEmail(res?.data?.email?.toLowerCase());

                        // setShowCheckInbox(true);
                        formik.resetForm();

                        handleSignInSuccess(res);
                        ReactGA.send({ hitType: "pageview", page: "/conv-thank-you", title: "Free Sign Up Thank you" });
                    } else {
                        const message =  capitalizeFirstLetter(res?.error || res?.message || 'Something went wrong');
                        enqueueSnackbar({ message, variant: 'error'});
                        setSubmitting(false);
                    }

                    setAuthenticating(false);
                    setSubmitting(false);
                } else {
                    setShowPaymentModal(true);
                }
            } else {
                enqueueSnackbar('Invalid plan', { variant: 'error' });
                setSubmitting(false);
            }

        },
    });

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [showThankYou, setShowThankYou] = useState(false);
    const [showCheckInbox, setShowCheckInbox] = useState(false);
    const [planInfo, setPlanInfo] = useState<any>({});
    const [orderSummary, setOrderSummary] = useState<any>({});
    const [submitting, setSubmitting] = useState(false);
    const [email, setEmail] = useState<string>('');
    const [resending, setResending] = useState(false);
    const [emailError, setEmailError] = useState<string>('');
    const [openPWValidation, setOpenPWValidation] = useState(false);
    const [passwordError, setPasswordError] = useState<string>('');
    const [couponCode, setCouponCode] = useState<string | null>(null);
    const [couponApplying, setCouponApplying] = useState(false);
    const [couponApplied, setCouponApplied] = useState(false);
    const [adding, setAdding] = useState(false);
    const [signupResponse, setSignupResponse] = useState<any>({});

    useEffect(() => {
        const getPlanInfo = async () => {
            const p = searchParams.get('plan') || '';
            const response = await plans(SIGNUP_PAYLOAD.VERSIONS.indexOf(atob(version)) + 1);
            const planInfo = response?.data?.find((plan: any) => plan?.planName === p);
            setPlanInfo(planInfo);
        }

        getPlanInfo();
    }, [version])

    useEffect(() => {
        const fetchOrderSummary = async () => {
            setCouponApplying(true);
            const planInterval = searchParams.get('subscription') || '';
            const retrievedOrderSummary = await retrieveOrderSummary({
                id: planInfo?.id,
                planInterval,
                couponCode
            });
            setOrderSummary(retrievedOrderSummary?.data);
            if(retrievedOrderSummary?.data?.couponId) {
                setCouponApplied(true);
                setCouponApplying(false);
            } else {
                setCouponApplied(false);
                setCouponApplying(false);
                enqueueSnackbar(retrievedOrderSummary?.error || retrievedOrderSummary?.message, { variant: 'error' })
            }
            setCouponApplying(false);
        }

        fetchOrderSummary();
    }, [planInfo, couponCode])


    const handleLogin = () => {
        navigate(ROUTES.ROOT);
    }

    const handleClickShowPassword = () => {
        setShowPassword((show) => !show);
    }

    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword((show) => !show);
    }

    const handleSubmit = async (responsePM: any, setccAdding: any) => {
        const values = formik.values;
        const referrerid = searchParams.get('ref') || '';
        const refid = searchParams.get('refid') || '';
        const versionNum = SIGNUP_PAYLOAD.VERSIONS.indexOf(atob(version)) + 1

        setccAdding(true);
        setAuthenticating(true);
        setAdding(true);
        let registerParams: IRegisterUserRequestPayload = {
            email: values.email,
            password: values.password,
            firstName: values.firstName,
            lastName: values.lastName,
            clientName: `${values.firstName} ${values.lastName}'s Workspace`,
            companyName: values.companyName,
            how_will_you_use_the_platform: 'company',
            job_role: SIGNUP_PAYLOAD.JOB_ROLE.OWNER ,
            source: SIGNUP_PAYLOAD.SOURCE.VITAL_EDGE,
            creditPlanInterval: subscription || '',
            pricingVersion: versionNum,
            plan,
            price: orderSummary?.total,
            couponCode: orderSummary?.couponCode || "",
            couponId: orderSummary?.couponId || "",
            stripePaymentMethodId: versionNum != 6 ? responsePM?.id : '',
            customerVaultId: versionNum == 6 ? responsePM?.customerVaultId : '',
        }

        if(referrerid) {
            registerParams = {
                ...registerParams,
                referrerid,
            }
        }

        if(refid) {
            registerParams = {
                ...registerParams,
                refid
            }
        }

        const response = await register(registerParams);

        setEmail(response?.data?.email);
        if(response?.status === 'success') {
            setSignupResponse(response);
            ReactGA.send({ hitType: "pageview", page: "/conv-thank-you", title: "Sign Up Thank you" });
            setShowThankYou(true);
            formik.resetForm();
            setSubmitting(false);
            setShowPaymentModal(false);
            setccAdding(false);
            setAdding(false);
        } else {
            enqueueSnackbar(capitalizeFirstLetter(response?.error || ''), { variant: 'error' });
            setSubmitting(false);
            setccAdding(false);
            setAdding(false);
        }

    }

    const handleResendActivationLink = async () => {
        setResending(true);
        const res = await resendActivation(email?.toLowerCase());

        if(res.status === 'success') {
            enqueueSnackbar('Successfully resent activation link', { variant: 'success' });
        } else {
            enqueueSnackbar(capitalizeFirstLetter(res?.data?.message || res?.message || ''), { variant: 'error' });
        }
        setResending(false);
    }

    const handleValidateEmailIfExisting = async () => {
        const res = await isExisting(formik?.values?.email?.toLowerCase());

        if(res?.isExisting) {
            setEmailError('Email Address is already associated to a workspace, you may add additional workspace upon logging in.');
        } else {
            setEmailError('');
            setSubmitting(false);
            setCouponApplying(false);
        }
    }

    const applyCouponCode = (code: string) => {
        setCouponCode(code);
    }

    if(showThankYou) {
        return (
            <ThankYouView signUp handleShowThankYou={() => {
                // setShowThankYou(s => !s);
                // setShowCheckInbox(true);
                handleSignInSuccess(signupResponse)
            }} />
        )
    }

    if(showCheckInbox) {
        return (
            <CheckYourInbox
                withResend
                withBackSignIn={false}
                details="An email verification link has been sent to your inbox."
                handleResend={handleResendActivationLink}
                isLoading={resending}
            />
        )
    }

    return (
        <>
            <VitalEdgeLoader isOpen={authenticating} />
            <div className='title'>Create Account</div>
            <div className='supporting'>Enter your details to create a {startCase(plan)} account</div>
            <form onSubmit={formik.handleSubmit} className="signup-form">
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <CustomTextField
                            fullWidth
                            id="firstName"
                            name="firstName"
                            placeholder="First Name"
                            value={formik.values.firstName}
                            onChange={formik.handleChange}
                            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                            helperText={formik.touched.firstName && formik.errors.firstName}
                            variant="standard"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            className="mb-4 custom-login-input"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <CustomTextField
                            fullWidth
                            id="lastName"
                            name="lastName"
                            placeholder="Last Name"
                            value={formik.values.lastName}
                            onChange={formik.handleChange}
                            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                            helperText={formik.touched.lastName && formik.errors.lastName}
                            variant="standard"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            className="mb-4 custom-login-input"
                        />
                    </Grid>
                </Grid>
                <CustomTextField
                    fullWidth
                    id="companyName"
                    name="companyName"
                    placeholder="Enter the name of your organization"
                    value={formik.values.companyName}
                    onChange={formik.handleChange}
                    error={formik.touched.companyName && Boolean(formik.errors.companyName)}
                    helperText={formik.touched.companyName && formik.errors.companyName}
                    variant="standard"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    className="mb-4 custom-login-input"
                />
                <CustomTextField
                    fullWidth
                    id="email"
                    name="email"
                    placeholder="Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={handleValidateEmailIfExisting}
                    error={!!emailError || (formik.touched.email && Boolean(formik.errors.email))}
                    helperText={emailError || (formik.touched.email && formik.errors.email)}
                    variant="standard"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    className="mb-4 custom-login-input"
                />
                <PasswordValidationPopover open={openPWValidation} value={formik.values.password} setPasswordError={setPasswordError}>
                    <CustomTextField
                        fullWidth
                        type={showPassword ? "text" : "password"}
                        id="password"
                        name="password"
                        placeholder="Password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onFocus={() => setOpenPWValidation(true)}
                        onBlur={() => setOpenPWValidation(false)}
                        error={(!!!openPWValidation && !!passwordError) || (formik.touched.password && Boolean(formik.errors.password))}
                        helperText={(!!!openPWValidation && passwordError) || formik.touched.password && formik.errors.password}
                        variant="standard"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        edge="end"
                                    >
                                        {!showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        className='mb-4 custom-login-input'
                    />
                </PasswordValidationPopover>
                <CustomTextField
                    fullWidth
                    type={showConfirmPassword ? "text" : "password"}
                    id="confirm_password"
                    name="confirm_password"
                    placeholder="Confirm Password"
                    value={formik.values.confirm_password}
                    onChange={formik.handleChange}
                    error={formik.touched.confirm_password && Boolean(formik.errors.confirm_password)}
                    helperText={formik.touched.confirm_password && formik.errors.confirm_password}
                    variant="standard"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowConfirmPassword}
                                    edge="end"
                                >
                                    {!showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    className='mb-16 custom-login-input'
                />
                <Grid xs={12}>
                    <Button
                        type="submit"
                        variant="contained"
                        size="large"
                        fullWidth
                        className="submit-button"
                        disabled={!!emailError || !!passwordError || submitting || !formik.dirty}
                    >
                        <ConditionalProgress isLoading={submitting} color={COLORS.PRIMARY}>
                            Create Account
                        </ConditionalProgress>
                    </Button>
                </Grid>
                <Grid container xs={12} justifyContent="center" alignItems="center" className="signup-btn-wrapper">
                    Already have an account?<span className="signup-btn-text" onClick={handleLogin}>Log In</span>
                </Grid>
                <Grid container xs={12} justifyContent="center" alignItems="center" className="supporting terms-wrapper">
                    By creating an account, you have agreed to our&nbsp;
                    <div className="terms-btn-text" onClick={() => window.open("https://vitaledge.io/terms/")}>Terms & conditions</div>
                </Grid>
            </form>
            {showPaymentModal && (
                <CustomModal open={showPaymentModal} onClose={() => {
                    setShowPaymentModal(false)
                    setSubmitting(false)
                    applyCouponCode('');
                }} dismissible={
                    !submitting ||
                    (!couponApplying &&
                    !adding)
                }>
                    <NMIPaymentDetailsModal
                        // submitting={submitting}
                        name={`${formik.values.firstName} ${formik.values.lastName}`}
                        email={formik?.values?.email?.toLowerCase()}
                        handleSubmit={handleSubmit}
                        applyCouponCode={applyCouponCode}
                        couponApplying={couponApplying}
                        couponApplied={couponApplied}
                        setAdding={setAdding}
                        discountedAmount={orderSummary?.discountedAmount}
                        planPrice={orderSummary?.planPrice}
                        total={orderSummary?.total}
                    />
                </CustomModal>
            )}
        </>
    )
};

export default Signup;

import React from 'react';

import { ROUTES } from '../constants/ROUTES';
import { Login, Signup, ForgotPassword, ResetPassword, Activation, MemberSignup, ResendVerification, SignInAsOwnerByAdmin, Affiliate } from 'app/pages/Authentication';
import { RouteDataItem } from '../../types/routes.type';
import GoogleIntegration from '../components/GoogleIntegration/GoogleIntegration';
import UnsubscribeEmail from '../components/UnsubscribeEmail/UnsubscribeEmail';
import ThankYou from '../../pages/ThankYou/ThankYou';

//use asyncs to heavily loaded UI
const AsyncUpgradeChangePlan = React.lazy(() => import('../../pages/UpgradeChangePlan'));
const AsyncPricing = React.lazy(() => import('../../pages/Pricing'));
const AsyncManagePixels = React.lazy(() => import('../../pages/ManagePixels/ManagePixels'));
const AsyncPreviewPixels = React.lazy(() => import('../../pages/ManagePixels/Preview'));
const AsyncCapturesTable = React.lazy(() => import('../../pages/ManagePixels/CapturesTable'));
const AsyncDashboard = React.lazy(() => import('../../pages/Dashboard'));
const AsyncSettings = React.lazy(() => import('../../pages/Settings/Settings'));

export const public_routes: RouteDataItem[] = [
  {
    path: ROUTES.ROOT,
    element: <Login />,
  },
  {
    path: ROUTES.LOGIN,
    element: <Login />,
  },
  {
    path: ROUTES.SIGNUP,
    element: <Signup />,
  },
  {
    path: ROUTES.RESEND_VERIFICATION,
    element: <ResendVerification />,
  },
  {
    path: ROUTES.MEMBER_SIGNUP,
    element: <MemberSignup />,
  },
  {
    path: ROUTES.FORGOT_PASSWORD,
    element: <ForgotPassword />,
  },
  {
    path: ROUTES.RESET_PASSWORD,
    element: <ResetPassword />,
  },
  {
    path: ROUTES.ACTIVATION,
    element: <Activation />,
  },
  {
    path: ROUTES.GOOGLE_REDIRECT_URL,
    element: <GoogleIntegration />,
  },
  {
    path: ROUTES.THANK_YOU,
    element: <ThankYou />,
  },
  {
    path: ROUTES.SIGN_IN_AS_OWNER_BY_ADMIN,
    element: <SignInAsOwnerByAdmin />,
  },
  {
    path: ROUTES.AFFILIATE,
    element: <Affiliate />
  },
  {
    path: ROUTES.UNSUBSCRIBE,
    element: <UnsubscribeEmail />
  }
];

export const private_routes: RouteDataItem[] = [
  {
    path: ROUTES.DASHBOARD,
    element: <AsyncDashboard />,
    roles: ['owner', 'member'],
  },
  {
    path: `${ROUTES.SETTINGS}/:tab?`,
    element: <AsyncSettings />,
    roles: ['owner', 'member'],
  },
  {
    path: ROUTES.MANAGE_PIXELS,
    element: <AsyncManagePixels />,
    roles: ['owner', 'member'],
  },
  {
    path: ROUTES.PREVIEW_PIXELS,
    element: <AsyncPreviewPixels />,
    roles: ['owner', 'member'],
  },
  {
    path: ROUTES.PREVIEW_CAPTURES,
    element: <AsyncCapturesTable />,
    roles: ['owner', 'member'],
  },
  // {
  //   path: ROUTES.PRICING,
  //   element: <AsyncPricing />,
  //   roles: ['owner'],
  // },
  {
    path: ROUTES.UNSUBSCRIBE,
    element: <UnsubscribeEmail />,
    roles: ['owner', 'member'],
  },
  {
    path: ROUTES.UPGRADE_CHANGE_PLAN,
    element: <AsyncUpgradeChangePlan />,
    roles: ['owner', 'member'],
  }
];

import React, { useEffect } from 'react'

import ResetPasswordForm from 'app/components/Authentication/ResetPassword';
import AuthenticationLayout from 'app/components/Authentication/AuthenticationLayout';

import { useCheckAuthenticity } from 'app/shared/hooks/useCheckAuthenticity';
import VitalEdgeLogo from 'assets/images/logo_dark.png';

const ResetPassword = () => {
  const { signOut } = useCheckAuthenticity();
  useEffect(() => {
    signOut(false);
  }, []);

  return (
    <AuthenticationLayout>
      <div>
        <img src={VitalEdgeLogo} alt="Vital Edge" />
        <ResetPasswordForm />
      </div>
    </AuthenticationLayout>
  )
}

export default ResetPassword
import { useMutation, useQuery } from '@tanstack/react-query';
import { paymentDao } from '../dao/paymentDao';
const {
  getCustomerCards: getCustomerCardsDao,
  addCustomerCardDetails: addCustomerCardDetailsDao,
  deleteCustomerCardDetails: deleteCustomerCardDetailsDao,
  getSubscriptions: getSubscriptionsDao,
  paymentSubscribe: paymentSubscribeDao,
  paymentUnsubscribe: paymentUnsubscribeDao,
} = paymentDao();

export const paymentServices = () => {
  const getCustomerCards = ({ email }: { email: string }) => {
    return useQuery(['customerCards', { email: email }], () => getCustomerCardsDao({email}));
  };

  const addCustomerCardDetails = () => {
    return useMutation((payload: any) => addCustomerCardDetailsDao(payload));
  }

  const deleteCustomerCardDetails = () => {
    return useMutation((payload: any) => deleteCustomerCardDetailsDao(payload));
  }

  const getSubscriptions = ({ workspaceid }: { workspaceid: string }) => {
    return useQuery(['customerSubscriptions', { workspaceid: workspaceid }], () => getSubscriptionsDao({workspaceid}));
  };

  const paymentSubscribe = () => {
    return useMutation((payload: any) => paymentSubscribeDao(payload));
  }

  const paymentUnsubscribe = () => {
    return useMutation((payload: any) => paymentUnsubscribeDao(payload));
  }

  return {
    getCustomerCards,
    addCustomerCardDetails,
    deleteCustomerCardDetails,
    getSubscriptions,
    paymentSubscribe,
    paymentUnsubscribe,
  }

};

import { Grid, Typography } from '@mui/material'
import ThankYouIllustration from 'assets/images/thankyou_illustration.svg'
import { ThankYouWrapper, sub, title } from 'app/components/Authentication/ThankYouView/ThankYouView.styles'

interface IProps {
  message: string
}

const ThankYouView = (props: IProps) => {
  return (
    <ThankYouWrapper container justifyContent="center" alignItems="center">
      <Grid item xs={8} sm={7} md={5} lg={5} xl={3}>
        <img src={ThankYouIllustration} alt="Thank you" className='illustration'/>
        <Typography style={title}>Thank you!</Typography>
        <Typography style={sub}>{props.message}</Typography>
      </Grid>
    </ThankYouWrapper>
  )
}

export default ThankYouView

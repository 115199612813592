import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Button from 'app/shared/components/Button/Button';
import CircularProgressWithLabel from 'app/shared/components/CircularProgressWithLabel/CircularProgressWithLabel';

import { userDao } from 'app/shared/dao/userDao';
import VitalEdgeLogo from 'assets/images/logo_dark.png';
import CheckMark from 'assets/images/check_mark.svg';
import XMark from 'assets/images/x_mark.svg';
import { useCheckAuthenticity } from 'app/shared/hooks/useCheckAuthenticity';



const Activation = () => {
    const navigate = useNavigate();
    let [searchParams] = useSearchParams();
    const { isActivationCodeValid, activateMember } = userDao();
    const { handleSignInSuccess } = useCheckAuthenticity();

    const [activating, setActivating] = useState(false);
    const [checking, setChecking] = useState(false);
    const [invalidActivationCode, setInvalidActivationCode] = useState<any>(null);

    useEffect(() => {
        const activateUser = async () => {
            setChecking(true);
            const email = window.location.href.match(/email=(.*?)(&|$)/)?.[1] || '';
            const activationCode = searchParams.get('activationCode') || '';
            const workspaceid = window.location.href.match(/workspaceid=(.*?)(&|$)/)?.[1] || '';
            const type = searchParams.get('type') || '';

            const isValidRes = await isActivationCodeValid({
                email: email?.toLowerCase() || '',
                activationCode,
                workspaceid,
            });

            if(isValidRes?.status === 'failed') {
                setInvalidActivationCode({
                    title: 'Sorry, this invite is no longer available',
                    message: 'Please coordinate with the administrator or workspace owner.',
                })
            } else {
                setActivating(true);
                setTimeout(async () => {
                    const res = await activateMember({
                        email: email?.toLowerCase() || '',
                        activationCode,
                        workspaceid,
                        type
                    });
                    if(res?.status === 'success') {
                        handleSignInSuccess(res);
                    } else {
                        setActivating(false);
                    }
                }, 1000);
            }
            setChecking(false);
            
        }

        activateUser();
    }, []);


    const handleBackSignIn = () => {
        navigate('/');
    }

    if(checking) {
        return (
            <>
                <div style={{textAlign: 'center'}}>
                    <div>
                        <div style={{marginBottom: 32}}>
                            <CircularProgressWithLabel size={72} />
                        </div>
                    </div>
                    <div className='title'>
                        Validating Invitation Link
                    </div>
                    <div className='supporting'>
                        Please wait while we validate your invitation link
                    </div>
                </div>
            </>
        )
    }

    if(!checking && !!invalidActivationCode) {
        return (
            <>
                <img src={VitalEdgeLogo} alt="Vital Edge" />
                <div style={{textAlign: 'center'}}>
                    <div>
                        <img src={XMark} alt="Error" className='illustration' height={'80px'}/>
                    </div>
                    <div className='title'>
                        {invalidActivationCode?.title || 'Sorry, this invite is no longer available'}
                    </div>
                    <div className='supporting'>
                        {invalidActivationCode?.message || 'Please coordinate with the administrator or workspace owner.'}
                    </div>
                </div>
            </>
        )
    }


    return (
        <>
            <img src={VitalEdgeLogo} alt="Vital Edge" />
            <div style={{textAlign: 'center'}}>
                <div>
                    {
                        !activating ? (
                            <img src={CheckMark} alt="Success" className='illustration' style={{marginBottom: 0}} />
                        ) : (
                            <div style={{marginBottom: 32}}>
                                <CircularProgressWithLabel size={72} />
                            </div>
                        )
                    }
                </div>
                <div className='title'>
                    {
                        activating ? 'Activating' : 'Success!'
                    }
                </div>
                <div className='supporting'>
                    {
                        activating ? 'Please wait while we activate your account' : 'Your email has been verified'
                    }
                </div>
                {
                    !activating ? (
                        <>
                            <div className="buttons-wrapper" style={{ marginTop: 32 }}>
                                <Button variant="contained" size="large" fullWidth className="back-to-sign-in-button" onClick={handleBackSignIn}>
                                    Sign in
                                </Button>
                            </div>
                        </>
                    ) : null
                }
            </div>
        </>
    )
};

export default Activation;

import React, { useEffect, useMemo, useState } from 'react';
import { Box, ToggleButton, Typography, Stack, CardHeader } from '@mui/material';
import { CustomModal } from 'app/shared/modals/CustomModal';

import { ls } from 'app/shared/utils/ls';
import SuccessModal from 'app/shared/modals/SuccessModal';
import CardContent from '@mui/material/CardContent';
import { PLANS } from 'app/shared/constants/PLANS';
import {
  AnnualMonthlySelector,
  PlanItem,
  SelectedPlanItem,
  PlanButton,
  swirlImage,
  PlanButtonDisabled,
  PricingCardWrapper,
  PricingHeader,
} from './Pricing.styles';
import Swirl from 'assets/images/swirl.png';
import { IPaymentMethodData } from 'app/shared/types/billing.types';
import { workspaceServices } from 'app/shared/services/workspaceServices';
import { billingServices } from 'app/shared/services/billingServices';
import { IUser, IUserAddNewWorkspace } from 'app/shared/types/user.types';
import { CONFIG } from 'app/shared/config';
import PaymentMethod from 'app/shared/components/Header/Pricing/Modals/PaymentMethod';
import NMIPaymentMethod from 'app/shared/components/Header/Pricing/Modals/NMIPaymentMethod';
import { useQueryClient } from '@tanstack/react-query';
import { useChangeWorkspace } from 'app/shared/hooks/useChangeWorkspace';
import CustomTextField from '../../TextField/TextField';
import Button from '../../Button/Button';
import { userServices } from '../../../services/userServices';
import VitalEdgeLoader from '../../Loader/VitalEdgeLoader';
import { enqueueSnackbar } from 'notistack';
import { capitalizeFirstLetter, startCase } from '../../../utils/helpers';
import { pricingService } from '../../../services/pricingServices';
import { IPlans } from '../../../types/pricing.types';

const { paymentMethods } = billingServices();
const { addNewWorkspace } = userServices();
const { getPlans } = pricingService();

const Pricing = ({ onClose }: { onClose: () => void }) => {
  const { getLS, setLS } = ls();
  const queryClient = useQueryClient();
  const { creditPlanInterval } = JSON.parse(getLS('currentWorkspace'));
  const [period, setPeriod] = React.useState(creditPlanInterval === 'annually' ? 'yearly' : 'monthly');
  const { getWorkspace } = workspaceServices();
  const [paymentCards, setPaymentCards] = useState<IPaymentMethodData[]>([]);
  const { mutateAsync, isLoading: updateLoading } = paymentMethods();
  const [showAddCCModal, setShowAddCCModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successMessage, setsuccessMessage] = useState('');
  const { workspaceid } = JSON.parse(localStorage.getItem('user') as string);
  const [submitting, setSubmitting] = useState(false);
  const [applying, setApplying] = useState(false);
  const [workspaceName, setWorkspaceName] = useState('');
  const [pricingPlans, setPricingPlans] = useState<IPlans[]>([]);
  const { mutate, isLoading } = addNewWorkspace();

  const { stripeCustomerId, email }: IUser = JSON.parse(getLS('user') || '{}');
  const { currentWorkspaceId, workspacesList } = useChangeWorkspace();
  const currentWorkspace = workspacesList.find((item: any) => item?.id === currentWorkspaceId);
  const user = JSON.parse(getLS('user') || '{}');
  const { data: workspaces, isLoading: isLoadingWorkspaces } = getWorkspace({
    workspaceid: workspaceid,
  });
  const pricingVersion = workspaces?.data?.pricingVersion || 3;
  const { data: currentPricingPlans, isLoading: isGettingPlans } = getPlans(pricingVersion);

  const [clickedIndex, setClickedIndex] = React.useState(-1);
  const [selectedPlan, setSelectedPlan] = useState('');

  useEffect(() => {
    const getPaymentMethods = () => {
      ``;
      mutateAsync(
        {
          stripeCustomerId: currentWorkspace?.stripeCustomerId || stripeCustomerId || '',
        },
        {
          onSuccess: (response) => {
            setPaymentCards(response?.data?.data || []);
          },
        },
      );
    };

    getPaymentMethods();
  }, [currentWorkspace?.stripeCustomerId, stripeCustomerId]);

  useEffect(() => {
    if (pricingVersion === 2) setPeriod('yearly');
  }, [pricingVersion]);

  useEffect(() => {
    const pricingPlans: IPlans[] = [
      {
        id: '999',
        planName: 'TIER 7',
        websitePixelCount: 10,
        customerCount: 50000,
        priceMonthly: 100000000,
        priceAnnually: 100000000,
        planHierarchy: 0,
        stripePriceId: 'string',
        version: pricingVersion,
        websitePixelUnitCost: 0,
        customerUnitCost: 0,
        phoneNumberCount: 0,
        stripeMonthlyPriceId: '',
      },
    ];
    currentPricingPlans?.data.forEach((plan) => {
      if (plan.workspaceids?.includes(workspaceid)) {
        pricingPlans.push(plan);
      } else if (!plan.workspaceids) {
        // if(!currentWorkspace?.isSubscriptionTrial && plan.planName === 'free') return;
        pricingPlans.push(plan);
      }
    });
    setPricingPlans(pricingPlans.sort((a, b) => (a?.priceMonthly - b?.priceMonthly)) || []);
  }, [currentPricingPlans]);

  const handleChange = (event: React.MouseEvent<HTMLElement>, newPeriod: string) => {
    if (!newPeriod) return;
    setPeriod(newPeriod);
  };

  const handleAddCC = (selectedPlan: string) => {
    if ((selectedPlan.toUpperCase() == 'SCALE' || selectedPlan?.toUpperCase() == 'TIER 7') && CONFIG.MAILTO_CONTACTUS) {
      window.location.href = CONFIG.MAILTO_CONTACTUS;
      return;
    }
  };

  const toggleCloseModal = () => {
    queryClient.invalidateQueries(['email', JSON.parse(getLS('user'))?.email]);
    onClose();
  };

  const handleSuccessAddCC = (data: any) => {
    setShowAddCCModal(false);
    setsuccessMessage('Your payment has been successfully processed.');
    setShowSuccessModal(true);
  };

  const handleClickedItem = (e: any, index: number) => {
    setClickedIndex(index);
    e.preventDefault();
    e.stopPropagation();
    return;
  };

  return (
    <>
      {(isGettingPlans || isLoading) ? <VitalEdgeLoader isOpen={isLoading || isGettingPlans} /> :
        <Box sx={{ height: '100%' }}>

          <PricingHeader
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
              flexDirection: 'column',
            }}
          >
            <Typography fontSize={'40px'} fontWeight={'bold'} color={'#1D1D1D'} className='pricingHeader-title'>
              Choose a plan
            </Typography>
            <CustomTextField
              name='workspace_name'
              placeholder='Enter Workspace Name'
              variant='standard'
              InputLabelProps={{
                shrink: true,
              }}
              sx={{ mb: 5, width: '450px' }}
              error={!Boolean(workspaceName)}
              helperText={!workspaceName ? 'Please enter workspace name' : ''}
              onChange={(e) => {
                setWorkspaceName(e.target.value);
              }}
            />
            <Typography
              fontSize={'18px'}
              color={'#942977'}
              sx={{ position: 'absolute', bottom: '45px', left: 'calc(50% - 295px)' }}
            >
              Save more on annual plan
            </Typography>
            <img style={{ ...(swirlImage as any) }} src={Swirl} />

            <AnnualMonthlySelector value={period} exclusive onChange={handleChange} aria-label='Platform'>
              <ToggleButton value='yearly'>
                <p>Annual</p>
              </ToggleButton>
              {pricingVersion !== 2 ? <ToggleButton value='monthly'>
                <p>Monthly</p>
              </ToggleButton> : null}
            </AnnualMonthlySelector>
          </PricingHeader>

          <PricingCardWrapper>
            {pricingPlans.map((plan: any, index: number) => {
              return (
                <PlanItem
                  sx={clickedIndex == index ? SelectedPlanItem : null}
                  onClick={(e) => {
                    handleClickedItem(e, index);
                    setSelectedPlan(plan?.planName);
                  }}
                >
                  <CardHeader
                    title={
                      <Typography className={'cardText'} textAlign={'center'} fontSize={'28px'}>
                        {plan.planName !== 'TIER 7' ? startCase(plan.planName)?.toUpperCase() : plan.planName}
                      </Typography>
                    }
                    sx={{ background: '#FBF7FB' }}
                  ></CardHeader>
                  <CardContent>
                    <Stack minHeight='70px'>
                      <Typography
                        className={'cardText'}
                        textAlign={'center'}
                        fontWeight={'bold'}
                        fontSize={'40px'}
                        lineHeight={plan.planName.toUpperCase() !== 'TIER 7' ? 1.5 : '40px'}
                        // sx={{ width: '190px' }}
                      >
                        {plan.planName.toUpperCase() !== 'TIER 7' ? '$' : ''}
                        {plan.planName.toUpperCase() !== 'TIER 7' ? period == 'yearly' ? plan?.priceAnnually?.toLocaleString('en-US') : plan?.priceMonthly?.toLocaleString('en-US') : 'Call Us!'}
                      </Typography>
                      {plan.planName.toUpperCase() === 'TIER 7' && (
                        <Typography className={'cardText'} textAlign={'center'} fontWeight={'bold'} fontSize={'14px'}>
                          {plan.subtitle}
                        </Typography>
                      )}
                    </Stack>
                    <PlanButton
                      disabled={selectedPlan === plan.planName ? true : false}
                      sx={selectedPlan === plan.planName ? PlanButtonDisabled : null}
                      onClick={() => {
                        if (selectedPlan === plan.planName) {
                          return;
                        }
                        handleAddCC(plan.planName);
                      }}
                    >
                      {plan.planName.toUpperCase() == 'TIER 7' || plan.planName.toUpperCase() == 'SCALE'
                        ? 'Contact Us'
                        : 'Get Started'}
                    </PlanButton>
                    <Typography className={'cardText pixelLimit'}>
                      {plan?.websitePixelCount}
                      {plan.planName.toUpperCase() === 'TIER 7' ? '+' : ''}{' '}
                      {(plan.planName.toUpperCase() == 'FREE' || plan?.websitePixelCount < 2) ? 'Pixel' : 'Pixels'}
                    </Typography>
                    <Typography className={'cardText pixelLimit'}>
                      {/* <CheckIcon />{' '} */}
                      {period == 'yearly'
                        ? (plan?.customerCount * 12)?.toLocaleString('en-US')
                        : plan?.customerCount?.toLocaleString('en-US')}
                      {plan.planName.toUpperCase() === 'TIER 7' ? '+' : ''}{' '}
                      Matches
                    </Typography>
                  </CardContent>
                </PlanItem>
              );
            })}
          </PricingCardWrapper>

          <Box textAlign={'center'} mt={2}>
            <Button
              variant={'outlined'}
              size={'large'}
              sx={{ mx: 1, width: '200px', fontSize: 16 }}
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </Button>
            <Button
              variant={'contained'}
              size={'large'}
              sx={{ mx: 1, width: '200px', fontSize: 16 }}
              onClick={() => {
                if (clickedIndex == -1) {
                  enqueueSnackbar(capitalizeFirstLetter('Please select a plan'), { variant: 'error' });
                  return;
                }
                if (!workspaceName) return;
                const plan = selectedPlan.replace(/\s/g, '').replace(/([A-Z])/g, (match) => match.toLowerCase());
                if (plan === 'free') {
                  let registerParams: IUserAddNewWorkspace = {
                    email: user.email,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    workspaceName: workspaceName,
                    organizationid: user.organizationid,
                    creditPlanInterval: period === 'yearly' ? 'annually' : 'monthly',
                    pricingVersion: currentWorkspace?.pricingVersion,
                    plan: plan,
                    price: 0,
                    couponCode: '',
                    couponId: '',
                    stripePaymentMethodId: '',
                  };

                  mutate(registerParams, {
                    onSuccess: (res) => {
                      setShowSuccessModal(true);
                      setsuccessMessage('You have successfully added a new workspace.');
                    },
                    onError: (err) => {
                      console.log('err: ', err);
                      onClose();
                    },
                  });
                  return;
                }
                setShowAddCCModal(true);
              }}
            >
              Confirm
            </Button>
          </Box>

          {showAddCCModal && (
            <CustomModal
              open={showAddCCModal}
              onClose={() => setShowAddCCModal(false)}
              dismissible={!submitting && !applying}
            >
              {currentWorkspace?.pricingVersion !== 6 ? <PaymentMethod
                handleSubmit={handleSuccessAddCC}
                paymentCards={paymentCards}
                selectedPlan={selectedPlan}
                period={period}
                setSubmitting={setSubmitting}
                setApplying={setApplying}
                workspaceName={workspaceName}
                onClose={() => setShowAddCCModal(false)}
              /> : <NMIPaymentMethod
                handleSubmit={handleSuccessAddCC}
                paymentCards={paymentCards}
                selectedPlan={selectedPlan}
                period={period}
                setSubmitting={setSubmitting}
                setApplying={setApplying}
                workspaceName={workspaceName}
                onClose={() => setShowAddCCModal(false)}
              />}
            </CustomModal>
          )}

          {showSuccessModal && (
            <SuccessModal
              open={showSuccessModal}
              onClose={toggleCloseModal}
              successMessge={successMessage}
              buttonAction={toggleCloseModal}
            />
          )}
        </Box>}
    </>
  );
};

export default Pricing;

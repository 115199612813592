import React, { ReactNode } from 'react';
import { SuccessCont, successButton, errorButton } from 'app/shared/modals/Modal.styles';
import { ErrorIcon, SuccessIcon } from 'app/shared/components/Icon/Icons';
import { Button, Stack, Typography } from '@mui/material';

interface Props {
  title: string;
  message?: string;
  type: 'success' | 'error';
  content?: ReactNode;
  successLabel: string;
  successAction: VoidFunction;
  errorLabel?: string;
  errorAction?: VoidFunction;
  top?: string;
  transform?: string;
  titleSize?: number;
  successButtonStyle?: any
}

const PromptModal: React.FC<Props> = ({
  title,
  message,
  type = 'success',
  content,
  successLabel,
  successAction,
  errorLabel,
  errorAction,
  titleSize = 32,
  successButtonStyle = {}
}) => {
  return (
    <SuccessCont>
      <Stack direction={'row'}>
        {type === 'success' ? <SuccessIcon size={72} /> : <ErrorIcon size={72} />}
      </Stack>
      <Typography fontSize={titleSize} fontWeight={700} sx={{ marginTop: '20px' }}>
        {title}
      </Typography>
      {content ? (
        content
      ) : (
        <Typography marginTop={1} fontSize={16}>
          {message}
        </Typography>
      )}
      <Button sx={{...successButton, ...successButtonStyle }} type="submit" variant="contained" size="large" onClick={successAction}>
        {successLabel ? successLabel : 'Close'}
      </Button>
      {errorLabel ? (
        <Button variant={'text'} sx={errorButton} type="submit" size="large" onClick={errorAction}>
          {errorLabel ? errorLabel : 'Close'}
        </Button>
      ) : null}
    </SuccessCont>
  );
};

export default PromptModal;

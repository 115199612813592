import Button from '../../Button/Button';
import { COLORS } from 'app/shared/constants/COLORS';
import { Grid, styled } from '@mui/material';

export const MainCont = styled(Grid)(() => ({
  // marginLeft: 'auto',
  display: 'flex',
  alignItems: 'center',
  padding: '0 30px 0 70px',
  color: 'black',
  fontSize: 18,
  flex: 1,
  justifyContent: 'flex-end',
}));

export const Avatar = styled('img')(() => ({
  borderRadius: '50%',
  width: 30,
  height: 30,
  marginRight: 10,
  padding: 2,
  border: `1px solid ${COLORS.PRIMARY}`,
  objectFit: 'cover',
}));

export const StyledButton = styled(Button)(() => ({
  color: COLORS.BG_WHITE,
  backgroundColor: COLORS.PRIMARY,
  fontSize: 15,
  padding: '5px 10px',
  marginInline: '10px'
,  textTransform: 'none',
  '&:hover': {
    backgroundColor: COLORS.PRIMARY_DARK,
    color: COLORS.BG_WHITE,
  },
}));

import { Routes } from './app/shared/navigation/Routes';

import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import THEME from './app/shared/constants/THEME';
import { AuthProvider } from 'app/shared/contexts/AuthContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { SnackbarProvider } from 'notistack';
import ReactGA from "react-ga4";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      retry: false,
      staleTime: 5 * 60 * 1000,
    },
  },
});
import { GlobalProvider } from 'app/shared/contexts/GlobalContext';
import { StyledMaterialDesignContent } from 'App.styles';
const TRACKING_ID = 'G-2Z30XXKZBC';

export const App = () => {
  ReactGA.initialize(TRACKING_ID);
  return (
    <SnackbarProvider
      preventDuplicate
      autoHideDuration={3000}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      hideIconVariant
      Components={{
        success: StyledMaterialDesignContent,
        error: StyledMaterialDesignContent,
      }}
    >
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <GlobalProvider>
            <ThemeProvider theme={THEME}>
              <BrowserRouter>
                <Routes />
              </BrowserRouter>
            </ThemeProvider>
          </GlobalProvider>
        </AuthProvider>
      </QueryClientProvider>
    </SnackbarProvider>
  );
};

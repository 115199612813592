import { Box, styled } from '@mui/material';
import { COLORS } from 'app/shared/constants/COLORS';

export const MainCont = styled(Box)(() => ({
  display: 'flex',
}));

export const BalanceCont = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginLeft: 20,
  width: 120,
}));

export const IconCountCont = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 5,
}));

export const CountCont = styled(Box)(({ insufficient }: { insufficient: boolean }) => ({
  display: 'flex',
  alignItems: 'center',
  background: insufficient ? '#D43125' : COLORS.BLUE,
  color: 'white',
  borderRadius: 5,
  justifyContent: 'center',
  marginLeft: 15,
  padding: '1px 10px',
}));

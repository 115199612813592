import { Checkbox, styled } from '@mui/material';

import { COLORS } from '../../constants/COLORS';

export const StyledCheckbox = styled(Checkbox)(() => ({
    '&.MuiButtonBase-root.MuiCheckbox-root': {
        '& > svg.MuiSvgIcon-root': {
            'path': {
                fill: COLORS.BORDER_GRAY,
            },
        },

        '&.Mui-checked > svg.MuiSvgIcon-root': {
            'path': {
                fill: COLORS.PRIMARY,
            },
        },
    },
}));

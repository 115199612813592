import { ENDPOINTS } from '../constants/ENDPOINTS';
import { useAxios } from '../hooks/useAxios';
import { IVisitorsResponsePayload } from '../types/contact.types';

const { GET } = useAxios();

export const contactDao = () => {

  const listCaptures = async (pixelid: string, workspaceid: any) => {
    const response = await GET<IVisitorsResponsePayload>({
      url: `${ENDPOINTS.CAPTURES_SERVICE}/captures/?pixelid=${encodeURIComponent(pixelid)}&workspaceid=${encodeURIComponent(workspaceid)}`,
    } as any);

    return response.data;
  };

  const visitorsUrl = async (pixelid: string, workspaceid: any) => {
    const response = await GET<any>({
      url: `${ENDPOINTS.CAPTURES_SERVICE}/visitors-url?pixelid=${encodeURIComponent(pixelid)}&workspaceid=${encodeURIComponent(workspaceid)}`,
    } as any);

    return response.data;
  };

  return {
    listCaptures,
    visitorsUrl,
  };
};

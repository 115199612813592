import { styled, Box } from '@mui/material';

export const AddCreditCardModalCont = styled(Box)(({ theme }) => ({
  display: 'flex',
  // width: 468,
  minHeight: 450,
  borderRadius: 10,
  background: '#fff',
  padding: 30,
  position: 'relative',
  flexDirection: 'column',
}));

export const FieldCont = styled(Box)(({ theme }) => ({
  marginTop: 35,
}));

export const ExpCont = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    marginTop: 20,

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      marginBottom: 20,

      '.MuiFormControl-root': {
        marginLeft: 0,

        '&:not(:first-child)': {
          marginTop: 20,
        },
      }
    },
}));

export const updateButton = {
  height: 56,
  marginTop: 'auto',
  fontSize: 18,
  textTransform: 'none',
//   marginBottom: '15px',
  borderRadius: '8px',
};

export const cvvField = {
  marginLeft: '20px',
};

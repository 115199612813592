import { Box, Button, styled, Paper, ToggleButtonGroup, Card } from '@mui/material';
import { COLORS } from 'app/shared/constants/COLORS';

export const PlanItem = styled(Card)(() => ({
  width: '271px',
  height: 'auto',
  margin: '0 10px',
  transition: 'all 0.5s ease-in-out',
  minWidth: '171px',

  pixelLimit: {
    fontSize: '12px',
    width: '178px',
    height: '33px',
  },

  'img.swirl': {
    position: 'absolute',
    width: '90px',
    bottom: '-10px',
    left: 'calc(50% - 190px)',
  },

  '.pixelLimit': {
    textAlign: 'center',
    margin: '4px 0',
  },
}));

export const SelectedPlanItem = {
  // backgroundColor: '#631B4F',
  // '.cardText': {
  //     color: '#fff'
  // }
};

export const swirlImage = {
  position: 'absolute',
  width: '90px',
  bottom: '-10px',
  left: 'calc(50% - 190px)',
};

export const PlanButton = styled(Button)(() => ({
  display: 'block',
  maxWidth: '178px',
  width: '100%',
  height: '33px',
  backgroundColor: '#942977',
  color: '#fff',
  margin: '0 auto 10px',
  '&:hover': {
    backgroundColor: 'rgba(0,0,0,0.2)',
  },
}));

export const PlanButtonDisabled = {
  backgroundColor: 'ghostwhite !important',
  color: 'gray !important',
  '&:hover': {
    backgroundColor: 'ghostwhite !important',
  },
};

export const AnnualMonthlySelector = styled(ToggleButtonGroup)(() => ({
  '.root': {},
  '.MuiToggleButtonGroup-root': {
    padding: 0,
    border: '1px solid #919191',
    borderRadius: '15px',
  },

  button: {
    border: 'none',
    padding: 0,

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  '.MuiToggleButtonGroup-grouped.Mui-selected': {
    backgroundColor: 'transparent',
  },

  '.Mui-selected p': {
    backgroundColor: '#942977',
    color: '#fff',
  },

  p: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '98px',
    borderRadius: '15px',
    height: '36px',
    margin: 0,
  },
}));

export const BackButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  top: '64px',
  left: 'calc(50% - 450px)',
  zIndex: 999,
  color: '#858687',

  [theme.breakpoints.down('md')]: {
    left: '50px',
  },
  [theme.breakpoints.down('sm')]: {
    left: '10px',
    top: '30px',
  },
}));

export const PricingCardWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  overflowY: 'auto',
  display: 'flex',
  margin: '0px auto 0',
  padding: '20px 0',

  '&.custom-cardwrapper-style': {
    justifyContent: 'center',
  },

  [theme.breakpoints.down('lg')]: {
    '&.custom-cardwrapper-style': {
      justifyContent: 'flex-start',
    },
  },
}));

export const PricingHeader = styled(Box)(({ theme }) => ({
  '.pricingHeader-title': {
    [theme.breakpoints.down('sm')]: {
      fontSize: '40px',
    },
  },

  '.pricingHeader-subtitle': {
    [theme.breakpoints.down('md')]: {
      width: '90vw',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
    },
  },
}));

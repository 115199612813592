import { Grid, styled } from '@mui/material';

import { COLORS } from 'app/shared/constants/COLORS';

export const RightDisplayWrapper = styled(Grid)(() => ({
    background: COLORS.PRIMARY,
}));

export const LeftDisplayWrapper = styled(Grid)(({ theme }) => ({
    padding: '0 75px',

    img: {
        width: '160px',
        marginBottom: '40px',
    },

    'img.vitaledge-loader': {
        width: '60px',
    },

    '.illustration': {
        width: '240px',
        marginTop: '40px',
    },

    '.title': {
        fontSize: '28px',
        fontWeight: 700,
        marginBottom: '8px',
    },

   '.supporting': {
       fontSize: '14px',
       fontWeight: 400,
       color: COLORS.TEXT_GRAY,
       marginBottom: '4px',
    },

    
    'form': {
        marginTop: '60px',

        '.MuiFormHelperText-root.Mui-error': {
            color: COLORS.ERROR_DARK,

            '&.custom-error-text': {
                fontSize: '14px',
                marginBottom: '4px',
            }
        },
        
        '.MuiFormControl-root':  {
            marginBottom: '24px',
        },

        '.mb-16': {
            marginBottom: '16px',
        },

        '.MuiFormControlLabel-label': {
            color: COLORS.TEXT_GRAY,
        },

        '.submit-button': {
            fontSize: '16px',
            marginTop: '24px',
        },
        
        '.remember-me .MuiFormControlLabel-label': {
            fontSize: '14px',
        },

        '.signup-btn-wrapper': {
            color: COLORS.BUTTON_BLACK,
            fontSize: '14px',
            marginTop: '20px',
            
            '.signup-btn-text': {
                marginLeft: '4px',
                fontWeight: 700,
                cursor: 'pointer',
                color: COLORS.PRIMARY,
            },
        },

        '.terms-wrapper': {
            marginTop: '20px',

            '.terms-btn-text': {
                cursor: 'pointer',
                color: COLORS.PRIMARY,
                textDecoration: 'underline',
                marginTop: '4px',
            }
        },

        '.affiliate-terms-wrapper': {
            '.terms-btn-text': {
                cursor: 'pointer',
                color: COLORS.PRIMARY,
                textDecoration: 'underline',
                marginTop: '4px',
            }
        },

        '.Mui-error.affiliate-terms-wrapper': {
            '.MuiButtonBase-root.MuiCheckbox-root>svg.MuiSvgIcon-root path': {
                fill: COLORS.ERROR_DARK,
            }
        },

        '.custom-login-input': {
            '.MuiInputBase-root.MuiInput-root.MuiInput-underline': {
                marginTop: 0,
                borderRadius: '30px',
            },

            '.MuiInputAdornment-root.MuiInputAdornment-positionEnd': {
                '& .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd': {
                    paddingRight: 15,
                }
            }
        },
    },

    'form .button-text': {
        fontSize: '14px',
        color: COLORS.BUTTON_BLACK,
        cursor: 'pointer',
    },

    'form.signup-form, form.forgot-password-form, form.member-signup-form': {
        marginTop: '32px',
    },

    '.buttons-wrapper': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },

    '.back-to-sign-in-button': {
        fontSize: '14px',
        margin: '0 auto',
    },

    '.resend-now-wrapper': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '20px',
        position: 'relative',

        '.resend-now-btn': {
            cursor: 'pointer',
            color: COLORS.PRIMARY,
            textDecoration: 'underline',
            fontWeight: 700,
            fontSize: '14px',
            marginLeft: '10px',
        }
    },

    [theme.breakpoints.down('md')]: {
        padding: '0 40px',

        'form': {
            minWidth: '250px',
        }
    },

}));

import React, { ReactNode } from 'react';
import { CustomModal } from './CustomModal';
import { DeleteCont, StyledDangerContainer, StyledDangerText, Subtitle, Title } from './Modal.styles';
import { Button } from '@mui/material';
import { ConditionalProgress } from '../components/ConditionalCircular/ConditionalCircular';
import { COLORS } from '../constants/COLORS';

interface Props {
  onClose: VoidFunction;
  open: boolean;
  message: ReactNode;
  title: ReactNode;
  subtitle?: ReactNode;
  okButtonLabel?: string;
  cancelButtonLabel?: string;
  okAction?: VoidFunction;
  cancelAction?: VoidFunction;
  isLoading?: boolean;
  hideCancelButton?: boolean;
  isCancelButtonText?: boolean;
  buttonWrapperStyle?: React.CSSProperties;
  titleStyle?: React.CSSProperties;
  modalProps?: any;
  okDisabled?: boolean;
  cancelDisabled?: boolean;
}

const GenericModal: React.FC<Props> = ({
    onClose,
    open,
    message,
    title,
    subtitle,
    okButtonLabel,
    cancelButtonLabel,
    okAction,
    cancelAction,
    isLoading = false,
    hideCancelButton = false,
    isCancelButtonText = false,
    buttonWrapperStyle = {},
    titleStyle = {},
    modalProps = {},
    okDisabled = false,
    cancelDisabled = false,
}) => {
  return (
    <CustomModal onClose={onClose} open={open} {...modalProps}>
      <DeleteCont>
        <Title style={titleStyle}>
          {title}
        </Title>
        {subtitle && <span style={{ color: 'red' }}>{subtitle}</span>}
        <Subtitle>
          {message}
        </Subtitle>
        <StyledDangerContainer style={buttonWrapperStyle}>
            {
              hideCancelButton ? null : (
                <Button variant={isCancelButtonText ? 'text' : 'outlined'} fullWidth onClick={() => {
                  if(cancelAction) return cancelAction();
                  return onClose();
                }} disabled={cancelDisabled}>
                  <ConditionalProgress isLoading={isLoading} color={COLORS.PRIMARY}>
                    <StyledDangerText color={'primary'}>{ cancelButtonLabel || 'Cancel' }</StyledDangerText>
                  </ConditionalProgress>
                </Button>
              )
            }
            <Button variant={'contained'} color={'primary'} fullWidth onClick={okAction} disabled={okDisabled}>
              <ConditionalProgress isLoading={isLoading} color={COLORS.PRIMARY}>
                <StyledDangerText color={COLORS.BG_WHITE}>{ okButtonLabel || 'Set as Default' }</StyledDangerText>
              </ConditionalProgress>
            </Button>
        </StyledDangerContainer>
      </DeleteCont>
    </CustomModal>
  );
};

export default GenericModal;

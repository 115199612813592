import React, { useEffect, useMemo, useState } from 'react';
import { Avatar, MainCont, StyledButton } from './ProfileAvatar.style';
import avatar from '../../../../../assets/images/avatar_fallback.jpg';
import { Box, Divider, Typography } from '@mui/material';
import { ls } from 'app/shared/utils/ls';
import { IUser, IWorkspace } from 'app/shared/types/user.types';

import Select from '../../Select/Select';
import { useChangeWorkspace } from 'app/shared/hooks/useChangeWorkspace';
import { useQueryClient } from '@tanstack/react-query';
import GenericModal from 'app/shared/modals/GenericModal';
import { CustomModal } from 'app/shared/modals/CustomModal';
import AddCreditCardModal from 'app/components/Settings/BillingInfo/Modals/AddCreditCardModal';
import SuccessModal from 'app/shared/modals/SuccessModal';
import { useCheckAuthenticity } from 'app/shared/hooks/useCheckAuthenticity';
import { workspaceServices } from 'app/shared/services/workspaceServices';
import Pricing from 'app/shared/components/Header/Pricing';
import { useNavigate } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PopupButton from '../../PopupButton/PopupButton';
import { BookIcon, LogoutIcon, ProfileIcon } from '../../Icon/Icons';
import { COLORS } from '../../../constants/COLORS';
import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined';
import { useUserJobRole } from '../../../hooks/useUserJobRole';

const { updateRequirePaymentMethod } = workspaceServices();

const OptionLabel = ({icon, label} : {icon?: any, label: string}) => {
  return (
    <Box gap={2} display={'flex'} alignItems={'center'}>
      {icon}
      <Typography fontSize={16}>
        {label}
      </Typography>
    </Box>
  )
}

const ProfileAvatar = () => {
  const { getLS, setLS } = ls();
  const navigate = useNavigate();
  const { signOut } = useCheckAuthenticity();
  const { jobRole } = useUserJobRole();

  const { firstName, lastName, photoURL } = JSON.parse(getLS('user')) as IUser;
  const [name, setName] = useState({
    firstName,
    lastName,
  });

  useEffect(() => {
    const handleStorageEvent = () => {
      //Redeclare to get the latest value
      const { firstName, lastName } = JSON.parse(getLS('user')) as IUser;
      setName({ firstName, lastName });
    };
    window.addEventListener('storage', handleStorageEvent);

    return () => window.removeEventListener('storage', handleStorageEvent);
  }, []);

  return (
    <MainCont>
      <StyledButton
        sx={{gap: 1}}
       onClick={() => {
         window.open('https://calendly.com/vitaledge-support/30min', '_blank');
       }}
      >
        <BookIcon/> Book an Appointment for Pixel Installation
      </StyledButton>
      <Divider orientation={'vertical'} sx={{ color: '#E1E1E1', height: '50%' }} />
      <StyledButton
       onClick={() => {
         window.open('https://vital-edge.atlassian.net/servicedesk/customer/portal/1', '_blank');
       }}
      >
        Need Help?
      </StyledButton>
      <PopupButton
        label={<>
          <Avatar src={photoURL || avatar} />
          <Typography fontSize={16} fontWeight={800}>
            {name.firstName} {name.lastName}
          </Typography>
        </>}
        options={[
          {
            label: <OptionLabel label={'My Profile'} icon={<ProfileIcon size={18} color={COLORS.TEXT_GRAY}/>}/>,
            value: 'my_profile',
            onClick: () => {
              navigate('/settings/profile')
            },
            isHidden: (jobRole)?.toLowerCase() !== 'owner'
          },
          {
            label: <OptionLabel label={'Sign Out'} icon={<LogoutIcon size={18} color={COLORS.TEXT_GRAY}/>}/>,
            value: 'sign_out',
            hasDivider: true,
            onClick: () => {
              signOut();
            },
          },
        ]}
      />
    </MainCont>
  );
};

export default ProfileAvatar;

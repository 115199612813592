import { ENDPOINTS } from '../constants/ENDPOINTS';
import { useAxios } from '../hooks/useAxios';

import { IDefaultResponsePayload } from '../types/auth.types';
import {
  IDeleteMemberPayload,
  IActivateRequestPayload,
  IExistingUserResponsePayload,
  IInviteUserRequestPayload,
  IRegisterUserRequestPayload,
  IRegisterUserResponsePayload,
  IUpdateProfileRequestPayload,
  IUploadPhotoRequestPayload,
  IUploadPhotoResponsePayload,
  IUsersResponsePayload,
  ICreateMemberRequestPayload,
  ISignInAsOwnerByAdminRequestPayload,
  IUserAddNewWorkspace,
} from '../types/user.types';

const { POST, GET, DELETE, PUT } = useAxios();

export const userDao = () => {
  const register = async (payload: IRegisterUserRequestPayload) => {
    try {
      const response = await POST<IRegisterUserResponsePayload>({
        url: `${ENDPOINTS.USER_SERVICE}/register-v2`,
        data: payload,
      } as any);

      return response?.data;
    } catch (e: any) {
      return e?.response?.data;
    }
  };

  const signInAsOwnerByAdmin = async (payload: ISignInAsOwnerByAdminRequestPayload) => {
    try {
      const response = await POST<IDefaultResponsePayload>({
        url: `${ENDPOINTS.USER_SERVICE}/admin-signas-owner`,
        data: payload,
      } as any);

      return response?.data;
    } catch (e: any) {
      return e?.response?.data;
    }
  };

  const activateMember = async (payload: IActivateRequestPayload) => {
    try {
      const response = await POST<IDefaultResponsePayload>({
        url: `${ENDPOINTS.USER_SERVICE}/activateMember`,
        data: payload,
      } as any);

      return response?.data;
    } catch (e: any) {
      return e?.response?.data;
    }
  };

  const activate = async (payload: IActivateRequestPayload) => {
    try {
      let queryParams = `?activationCode=${payload.activationCode}&email=${encodeURIComponent(payload.email)}&workspaceid=${encodeURIComponent(payload.workspaceid)}`;
      if (payload?.type) {
        queryParams += `&type=${payload.type}`;
      }
      const response = await GET<IDefaultResponsePayload>({
        url: `${ENDPOINTS.USER_SERVICE}/activatecode${queryParams}`,
      } as any);

      return response?.data;
    } catch (e: any) {
      return e?.response?.data;
    }
  };

  const isActivationCodeValid = async (payload: IActivateRequestPayload) => {
    try {
      const response = await GET<IDefaultResponsePayload>({
        url: `${ENDPOINTS.USER_SERVICE}/isActivationCodeValid?activationCode=${
          payload.activationCode
        }&email=${encodeURIComponent(payload.email)}&workspaceid=${encodeURIComponent(payload.workspaceid)}`,
      } as any);

      return response?.data;
    } catch (e: any) {
      return e?.response?.data;
    }
  };

  const updateProfile = async (payload: IUpdateProfileRequestPayload) => {
    const response = await PUT<IRegisterUserResponsePayload>({
      url: `${ENDPOINTS.USER_SERVICE}/updateprofile`,
      data: payload,
    } as any);

    return response?.data;
  };

  const isExisting = async (email: string) => {
    try {
      const response = await GET<IExistingUserResponsePayload>({
        url: `${ENDPOINTS.USER_SERVICE}/isexisting?email=${encodeURIComponent(email)}`,
      } as any);

      return response.data?.data;
    } catch (e: any) {
      return e?.response?.data;
    }
  };

  const isWorkspaceMember = async (email: string, workspaceid: string) => {
    try {
      const response = await GET<IExistingUserResponsePayload>({
        url: `${ENDPOINTS.USER_SERVICE}/is-workspace-member?email=${encodeURIComponent(
          email,
        )}&workspaceid=${encodeURIComponent(workspaceid)}`,
      } as any);

      return response.data?.data;
    } catch (e: any) {
      return e?.response?.data;
    }
  };

  const getMembers = async (workspaceid: string) => {
    const response = await GET<IUsersResponsePayload>({
      url: `${ENDPOINTS.USER_SERVICE}/users?workspaceid=${encodeURIComponent(workspaceid)}`,
    } as any);

    return response.data;
  };

  const invite = async (payload: IInviteUserRequestPayload) => {
    const response = await POST<IDefaultResponsePayload>({
      url: `${ENDPOINTS.USER_SERVICE}/inviteuser`,
      data: payload,
    } as any);

    return response?.data;
  };

  const createMember = async (payload: ICreateMemberRequestPayload) => {
    try {
      const response = await POST<IDefaultResponsePayload>({
        url: `${ENDPOINTS.USER_SERVICE}/member`,
        data: payload,
      } as any);

      return response?.data;
    } catch (e: any) {
      return e?.response?.data;
    }
  };

  const addNewWorkspace = async (payload: IUserAddNewWorkspace) => {
    try {
      const response = await POST<any>({
        url: `${ENDPOINTS.USER_SERVICE}/user-add-new-workspace`,
        data: payload,
      } as any);

      return response?.data;
    } catch (e: any) {
      return e?.response?.data;
    }
  };

  const uploadPhoto = async (payload: IUploadPhotoRequestPayload) => {
    const response = await POST<IUploadPhotoResponsePayload>({
      url: `${ENDPOINTS.USER_SERVICE}/uploadphoto`,
      data: payload,
    } as any);

    return response?.data;
  };

  const deleteMember = async (payload: IDeleteMemberPayload) => {
    const response = await DELETE<IUsersResponsePayload>({
      url: `${ENDPOINTS.USER_SERVICE}/user?workspaceid=${encodeURIComponent(
        payload.workspaceid,
      )}&email=${encodeURIComponent(payload.email)}`,
    } as any);

    return response.data;
  };

  const retrieveWorkspaces = async (email: string) => {
    if(!email) return Promise.resolve([])
    const response = await GET<any>({
      url: `${ENDPOINTS.USER_SERVICE}/workspaces?email=${encodeURIComponent(email)}`,
    } as any);

    return response.data;
  };

  return {
    getMembers,
    createMember,
    register,
    updateProfile,
    invite,
    isExisting,
    uploadPhoto,
    deleteMember,
    activate,
    isWorkspaceMember,
    retrieveWorkspaces,
    isActivationCodeValid,
    activateMember,
    signInAsOwnerByAdmin,
    addNewWorkspace,
  };
};

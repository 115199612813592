import React, { useMemo, useState } from 'react';
import { Alert, FormControlLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { MainCont, ModalContentWrapper } from './InsufficientCredits.style';
import { useChangeWorkspace } from 'app/shared/hooks/useChangeWorkspace';
import { ROUTES } from 'app/shared/constants/ROUTES';
import { useUserJobRole } from 'app/shared/hooks/useUserJobRole';
import GenericModal from 'app/shared/modals/GenericModal';
import { pixelServices } from 'app/shared/services/pixelServices';
import CustomCheckbox from '../../Checkbox/Checkbox';
import SuccessModal from 'app/shared/modals/SuccessModal';
import { useQueryClient } from '@tanstack/react-query';
import Box from '@mui/material/Box';
import NumberInputTextField from '../../TextField/NumberInput';
import VitalEdgeLoader from '../../Loader/VitalEdgeLoader';

const { listPixels, creditMatches } = pixelServices();

const InsufficientCredits = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [showFixOptionModal, setShowFixOptionModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState(1);
  const [matchesToCredit, setMatchesToCredit] = useState(0);
  const [selectedPixel, setSelectedPixel] = useState<any>();
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const { jobRole } = useUserJobRole();
  const { currentWorkspaceId, workspacesList, currentOrganizationId } = useChangeWorkspace();
  const currentWorkspace = workspacesList.find((item: any) => item?.id === currentWorkspaceId);
  const { organizationid, workspaceid } = JSON.parse(localStorage.getItem('user') as string);
  const availableMatches = currentWorkspace?.contactsCount || 0;

  const {
    data: pixels,
    isLoading,
    refetch,
  } = listPixels({
    workspaceid: currentWorkspaceId || workspaceid,
    organizationid: currentOrganizationId || organizationid,
  });

  const { mutate, isLoading: isSavingCredit } = creditMatches();

  const uncreditedContactsCount = currentWorkspace?.uncreditedContactsCount || 0;
  const currentContactsCount = currentWorkspace?.contactsCount || 0;
  const showInsufficientCredits = currentContactsCount > 0;

  const notallowed = (jobRole)?.toLowerCase() === 'member';

  const handleCreditMatches = () => {
    mutate({
      id: selectedPixel?.id,
      credits: matchesToCredit,
    }, {
      onSuccess: () => {
        setShowSuccessModal(true);
        setShowFixOptionModal(false);
        refetch();
      },
    });
  };

  const handleSelectedPixelChange = (pixel: any) => {
    setSelectedPixel(pixel);
  };

  const pixelList = useMemo(() => {
    return pixels?.data?.map((pixel: any) => {
      return {
        id: pixel.id,
        name: pixel.name,
        domainURL: pixel.domainURL,
        enablePixel: pixel.enablePixel,
        dbCountTotal: pixel.dbCountTotal,
        dbCreditedCount: pixel.dbCreditedCount,
      };
    }).filter((pixel: any) => pixel.dbCreditedCount !== pixel.dbCountTotal);
  }, [JSON.stringify(pixels)]);

  const errorMessage = useMemo(() => {
    const error = matchesToCredit > currentContactsCount ? `Only ${currentContactsCount.toLocaleString()} credit balance available` :
                  matchesToCredit > uncreditedContactsCount ? `${selectedPixel?.domainURL} has only ${uncreditedContactsCount} uncredited matches` : '';
    return error;
  }, [selectedPixel?.id, matchesToCredit, currentContactsCount, uncreditedContactsCount]);

  const FixPromptOptionModal = useMemo(() => {
    return (
      <GenericModal
        open={showFixOptionModal}
        onClose={() => {
          setShowFixOptionModal(false);
        }}
        title={'Please select a pixel'}
        subtitle={'Please note that if the pixel is not clickable, it means that you need to enable the pixel before crediting the uncredited matches.'}
        message={
          <ModalContentWrapper>
            <Box
              sx={{
                maxHeight: '300px',
                overflow: 'auto',
              }}
            >
              {
                pixelList?.map((pixel: any) => {
                  return (
                    <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'left', margin: '10px 0px' }}>
                      <Radio
                        disabled={!pixel.enablePixel}
                        onChange={() => handleSelectedPixelChange(pixel)}
                        checked={selectedPixel?.id === pixel.id}
                      />
                      <Typography sx={{ verticalAlign: 'center' }} fontSize={16}>{pixel.domainURL} = <span style={{
                        fontWeight: 600,
                        fontStyle: 'italic',
                      }}>{((pixel.dbCountTotal || 0) - (pixel.dbCreditedCount || 0)).toLocaleString()} Uncredited</span></Typography>
                    </Box>
                  );
                })
              }
            </Box>
            <Box display={'flex'} alignItems={'left'} flexDirection={'column'} mb={1}>
              <Typography fontSize={16} textAlign={'left'} mb={'4px'}>Number of Matches to Credit:</Typography>
              <NumberInputTextField
                initialValue={0}
                onChange={(value) => setMatchesToCredit(value)}
                error={Boolean(errorMessage)}
                helperText={errorMessage}
                disabled={!!!selectedPixel}
              />
            </Box>
          </ModalContentWrapper>
        }
        okButtonLabel='Confirm'
        okAction={() => {
          handleCreditMatches();
        }}
        okDisabled={
          matchesToCredit <= 0 || !!!selectedPixel || Boolean(errorMessage) || isSavingCredit
        }
      />
    );
  }, [showFixOptionModal, selectedOption, availableMatches, JSON.stringify(pixelList), JSON.stringify(selectedPixel), isSavingCredit, errorMessage, matchesToCredit]);

  if (showInsufficientCredits) return (
    <>
      <VitalEdgeLoader isOpen={isSavingCredit || isLoading}/>
      {FixPromptOptionModal}
      {showSuccessModal && (
        <SuccessModal
          open={showSuccessModal}
          onClose={() => {
            setShowSuccessModal(false);
            queryClient.invalidateQueries(['retrieveWorkspace']);
            queryClient.invalidateQueries(['pixels']);
            window.location.href = '/dashboard';
          }}
          successMessge={'Successfully credited matches'}
          buttonAction={() => {
            setShowSuccessModal(false);
            queryClient.invalidateQueries(['retrieveWorkspace']);
            queryClient.invalidateQueries(['pixels']);
            window.location.href = '/dashboard';
            // queryClient.invalidateQueries(['stripeCustomerId']);
            // if(location?.state?.from) {
            //   navigate(ROUTES.DASHBOARD, { state: { refetchWorkspaces: true }});
            // } else {
            //   navigate('/settings/billing_info');
            // }
          }}
        />
      )}
      {uncreditedContactsCount > 0 ? (
        <>
          <MainCont severity='error'> You have {
            uncreditedContactsCount?.toLocaleString('en-US')} uncredited
            match{uncreditedContactsCount > 1 ? 'es' : ''}.&nbsp;
            {
              !notallowed ? <>
                        <span
                          style={{ textDecoration: 'underline', cursor: 'pointer' }}
                          onClick={() => setShowFixOptionModal(true)}
                        >Click here to fix.</span>
              </> : <span>Please contact your workspace owner to upgrade the account.</span>
            }
          </MainCont>
        </>
      ) : null}
    </>
  );

  return (
    uncreditedContactsCount > 0 ?
    <>
      <VitalEdgeLoader isOpen={isSavingCredit || isLoading}/>
      <MainCont severity='error'>{
        uncreditedContactsCount?.toLocaleString('en-US')} match{uncreditedContactsCount > 1 ? 'es' : ''} not displayed
        due to insufficient credits.&nbsp;
        {
          !notallowed ? <>
                        <span
                          style={{ textDecoration: 'underline', cursor: 'pointer' }}
                          onClick={() => {
                            navigate(ROUTES.PRICING, {
                              state: {
                                from: 'dashboard',
                              },
                            });
                          }}
                        >Click here to upgrade plan.</span>
          </> : <span>Please contact your workspace owner to upgrade the account.</span>
        }
      </MainCont>
    </> : <></>
  );
};

export default InsufficientCredits;
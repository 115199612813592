import { IUser } from 'app/shared/types/user.types';
import { ls } from './ls';

const { getLS } = ls();

export const authToken = () => {
  const getAuthToken = () => {
    const token: string = getLS('token');
    const parsedToken = token ? token.replace(/['"]+/g, '') : '';
    return parsedToken;
  };

  const isAuthenticated = () => {
    return !!getAuthToken();
  };

  const getUser = (): IUser | null => {
    const userLS = getLS('user');
    if (userLS) {
      return JSON.parse(getLS('user') || '');
    }
    return null;
  };

  return {
    getAuthToken,
    isAuthenticated,
    getUser,
  };
};

import React from 'react';
import Modal from '@mui/material/Modal';
import { Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ModalCont, closeButton } from './Modal.styles';
interface Props {
  open: boolean;
  onClose: any;
  top?: string;
  transform?: string;
  closeIcon?: boolean;
  zoom?: number;
  dismissible?: boolean;
}

export const CustomModal: React.FC<React.PropsWithChildren<Props>> = ({
  open,
  onClose,
  children,
  top,
  transform,
  zoom = 1,
  closeIcon = true,
  dismissible = true,
}) => {
  const style = {
    position: 'absolute',
    top: top ? top : '50%',
    left: '50%',
    transform: transform ? transform : 'translate(-50%, -50%)',
    outline: 'none',
    zoom: zoom,
  };
  return (
    <Modal
      open={open}
      onClose={dismissible ? onClose : () => {}}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalCont transform={transform} top={top} zoom={zoom}>
        {children}
        {dismissible && closeIcon ? (
          <IconButton sx={closeButton} onClick={onClose}>
            <CloseIcon fontSize="large" />
          </IconButton>
        ) : (
          ''
        )}
      </ModalCont>
    </Modal>
  );
};

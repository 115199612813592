export const ROUTES = {
  ROOT: '/',
  LOGIN: '/auth/login',
  SIGNUP: '/auth/register',
  RESEND_VERIFICATION: '/auth/resend-verification',
  MEMBER_SIGNUP: '/auth/invite',
  FORGOT_PASSWORD: '/auth/forgot-password',
  RESET_PASSWORD: '/auth/reset-password',
  ACTIVATION: '/auth/activation',
  DASHBOARD: '/dashboard',
  SETTINGS: '/settings',
  AI: '/ai',
  MANAGE_PIXELS: '/pixels',
  PREVIEW_PIXELS: '/pixels/:id',
  PREVIEW_CAPTURES: '/pixels/:id/matches',
  GOOGLE_REDIRECT_URL: '/integration/google',
  PRICING: '/pricing',
  THANK_YOU: '/thank-you',
  SIGN_IN_AS_OWNER_BY_ADMIN: '/auth/sign-as-owner-by-admin',
  AFFILIATE: '/affiliate',
  UNSUBSCRIBE: '/unsubscribe',
  INVITE_TEAM_MEMBERS: '/settings/members?invite=true',
  UPGRADE_CHANGE_PLAN: '/upgrade-change-plan',
};

import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { Grid, IconButton, InputAdornment } from '@mui/material'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import CustomTextField from 'app/shared/components/TextField/TextField';
import Button from 'app/shared/components/Button/Button';

import { resetPasswordValidationSchema } from 'app/pages/Authentication/utils';
import { authDao } from 'app/shared/dao/authDao';
import SuccessView from './SuccessView';
import { ConditionalProgress } from 'app/shared/components/ConditionalCircular/ConditionalCircular';
import { COLORS } from 'app/shared/constants/COLORS';
import { capitalizeFirstLetter } from 'app/shared/utils/helpers';

const ResetPassword = () => {
    const { resetPassword } = authDao();
    let [searchParams] = useSearchParams();

    const formik = useFormik({
        initialValues: {
            password: '',
            confirm_password: '',
        },
        validationSchema: resetPasswordValidationSchema,
        onSubmit: async (values) => {
            setSubmitting(true);
            const email = window.location.href.match(/email=(.*?)(&|$)/)?.[1] || '';
            const token = searchParams.get('token') || '';
            const res = await resetPassword({
                password: values.password,
                email: email?.toLowerCase() || '',
                token,
            })

            setSubmitting(false);
            if(res?.status === 'success') {
                setSuccess(true);
                formik.resetForm();
            } else {
                enqueueSnackbar(capitalizeFirstLetter(res?.message || res?.error || ''), { variant: 'error' });
            }
        },
    });

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [success, setSuccess] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword((show) => !show);
    }

    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword((show) => !show);
    }

    if(success) {
        return <SuccessView />
    }

    return (
        <>
            <div className='title'>Reset Password</div>
            <div className='supporting'>Enter a new  password to proceed</div>
            <form onSubmit={formik.handleSubmit} className="forgot-password-form">
                <CustomTextField
                    fullWidth
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    placeholder="Password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                    variant="standard"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    edge="end"
                                >
                                    {!showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    className='mb-16 custom-login-input'
                />
                <CustomTextField
                    fullWidth
                    type={showConfirmPassword ? "text" : "password"}
                    id="confirm_password"
                    name="confirm_password"
                    placeholder="Confirm Password"
                    value={formik.values.confirm_password}
                    onChange={formik.handleChange}
                    error={formik.touched.confirm_password && Boolean(formik.errors.confirm_password)}
                    helperText={formik.touched.confirm_password && formik.errors.confirm_password}
                    variant="standard"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowConfirmPassword}
                                    edge="end"
                                >
                                    {!showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    className='mb-16 custom-login-input'
                />
                <Grid xs={12}>
                    <Button 
                        type="submit"
                        variant="contained" 
                        size="large"
                        fullWidth
                        className="submit-button"
                        disabled={submitting || !formik.dirty}
                    >
                        <ConditionalProgress isLoading={submitting} color={COLORS.PRIMARY}>
                            Reset Password
                        </ConditionalProgress>
                    </Button>
                </Grid>
            </form>
        </>
    )
};

export default ResetPassword;

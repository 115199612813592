import { Box, Button, styled, Grid } from '@mui/material';
import { COLORS } from 'app/shared/constants/COLORS';

export const SideBarCont = styled(Grid)(({ theme }) => ({
  background: COLORS.BG_SIDEBAR,
  borderRight: `1px solid ${COLORS.LIGHT_GRAY}`,
  width: 250,
  [theme.breakpoints.down('md')]: {
    width: '100%',
    flex: 1,
  },
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: 24,
  paddingRight: 24,
  paddingBottom: 24,
  boxSizing: 'border-box',
  height: '100vh',
}));

export const SidebarButton = styled(Button)(() => ({
  borderRadius: 8,
  marginBottom: 16,
  padding: 12,
  height: 40,
  fontSize: 15,
  width: '100%',
  backgroundColor: COLORS.BG_SIDEBAR,
  justifyContent: 'flex-start',
  paddingLeft: 50,
  color: COLORS.TEXT_GRAY,

  '&:hover': {
    backgroundColor: COLORS.PRIMARY,
    color: COLORS.BG_WHITE,
    '& path': {
      fill: 'white',
    },
    '& path[name="Path1"]': {
      stroke: COLORS.BG_WHITE,
      fill: 'unset'
    },
    '& path[name="Path2"]': {
      fill: COLORS.BG_WHITE,
    },
  },
}));

export const SidebarButtonActive = {
  backgroundColor: COLORS.PRIMARY_DARK,
  color: COLORS.BG_WHITE,
};

export const Logo = styled('img')({
  height: 45,
  margin: '20px auto',
  display: 'block',
  maxWidth: '100%',
});

import { Tooltip } from "@mui/material"
import { ValidationText, ValidationWrapper, tooltipStyle } from "./PasswordValidationPopover.styles"
import { Dispatch, ReactElement, useEffect, useState } from "react";
import { CheckCircle as ValidIcon, Cancel as InvalidIcon } from '@mui/icons-material'

interface IProps {
    children: ReactElement<any, any>;
    open: boolean;
    value: string;
    setPasswordError: Dispatch<React.SetStateAction<string>>
}

const error_list: any = {
    min_error: 'Password must be at least 8 characters long.',
    number_error: 'Password requires a number (0-9).',
    lowercase_error: 'Password requires a lowercase letter (a-z).',
    uppercase_error: 'Password requires an uppercase letter (A-Z).',
    symbol_error: 'Password requires a symbol (!, @, #, $, etc.).',
}

const PasswordCheck = ({ value, setPasswordError }: { value: string, setPasswordError: Dispatch<React.SetStateAction<string>> }) => {
    const [error, setError] = useState<string[]>([]);
    useEffect(() => {
        if(value.length === 0) {
            setPasswordError('');
            return setError([]);
        }

        const errors: string[] = [];

        // .min(8, 'Password should be of minimum 8 characters length')
        if(value.length < 8) {
            errors.push('min_error');
        } else {
            errors.filter(e => e !== 'min_error');
        }

        // .matches(/[a-z]/, 'Password requires a lowercase letter (a-z)')
        if(!value.match(/[a-z]/)) {
            errors.push('lowercase_error');
        } else {
            errors.filter(e => e !== 'lowercase_error');
        }

        // .matches(/[A-Z]/, 'Password requires an uppercase letter (A-Z)')
        if(!value.match(/[A-Z]/)) {
            errors.push('uppercase_error');
        } else {
            errors.filter(e => e !== 'uppercase_error');
        }

        // .matches(/[0-9]/, 'Password requires a number (0-9)')
        if(!value.match(/[0-9]/)) {
            errors.push('number_error');
        } else {
            errors.filter(e => e !== 'number_error');
        }

        // .matches(/[^\w]/, 'Password requires a symbol (!, @, #, $, etc.)'),
        if(!value.match(/[^\w]/)) {
            errors.push('symbol_error');
        } else {
            errors.filter(e => e !== 'symbol_error');
        }

        setPasswordError(error_list?.[errors?.[0]] || '');
        setError(errors);
    }, [value]);

    return (
        <ValidationWrapper>
            <ValidationText className="header">Your password must contain:</ValidationText>
            <ValidationText>
                <span className={error?.includes("min_error") ? 'error' : value ? 'success' : ''}>
                    {!error?.includes("min_error") ? <ValidIcon /> : <InvalidIcon />}
                </span> Minimum 8 characters
            </ValidationText>
            <ValidationText>
                <span className={error?.includes("lowercase_error") ? 'error' : value ? 'success' : ''}>
                    {!error?.includes("lowercase_error") ? <ValidIcon /> : <InvalidIcon />}
                </span> Lower case letters (a-z)
            </ValidationText>
            <ValidationText>
                <span className={error?.includes("uppercase_error") ? 'error' : value ? 'success' : ''}>
                    {!error?.includes("uppercase_error") ? <ValidIcon /> : <InvalidIcon />}
                </span> Upper case letters (A-Z)
            </ValidationText>
            <ValidationText>
                <span className={error?.includes("number_error") ? 'error' : value ? 'success' : ''}>
                    {!error?.includes("number_error") ? <ValidIcon /> : <InvalidIcon />}
                </span> Numbers (0-9)
            </ValidationText>
            <ValidationText>
                <span className={error?.includes("symbol_error") ? 'error' : value ? 'success' : ''}>
                    {!error?.includes("symbol_error") ? <ValidIcon /> : <InvalidIcon />}
                </span> Symbols (!, @, #, $, etc.)
            </ValidationText>
        </ValidationWrapper>
    )
}

const PasswordValidationPopover = ({
   children,
   open,
   value,
   setPasswordError
}: IProps) => {
  return (
    <Tooltip
        PopperProps={{
            disablePortal: true,
        }}
        componentsProps={tooltipStyle}
        open={open}
        placement="right"
        title={<PasswordCheck value={value} setPasswordError={setPasswordError}/>}
    >
        {children}
    </Tooltip>
  )
}


export default PasswordValidationPopover
import { useQuery } from '@tanstack/react-query';
import { pricingDao } from '../dao/pricingDao';

const {
  plans: getPlansDao,
} = pricingDao();
export const pricingService = () => {
  const getPlans = (version: number) => {
    return useQuery(['plans', version], () => getPlansDao(version));
  };

  return { getPlans };
};
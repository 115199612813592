import React, { useState } from 'react';
import { AddCreditCardModalCont, ExpCont, FieldCont, cvvField, updateButton } from './AddCreditCardModal.styles';
import { Button, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';

import CustomTextField from 'app/shared/components/TextField/TextField';
import { ConditionalProgress } from 'app/shared/components/ConditionalCircular/ConditionalCircular';

import { COLORS } from 'app/shared/constants/COLORS';
import { paymentDetailsValidationSchema } from 'app/pages/Authentication/utils';
import { stripeDao } from 'app/shared/dao/stripeDao';
import { capitalizeFirstLetter } from 'app/shared/utils/helpers';
import { ls } from 'app/shared/utils/ls';
import { billingServices } from 'app/shared/services/billingServices';
import { IPaymentMethodData } from 'app/shared/types/billing.types';
import { useChangeWorkspace } from 'app/shared/hooks/useChangeWorkspace';

interface Props {
    handleSubmit: (e?: any) => void;
    paymentCards: IPaymentMethodData[];
}

const { addPaymentMethods, paymentMethodAttach } = stripeDao();
const { createAlternativeCard, setDefaultCard } = billingServices();

const AddCreditCardModal: React.FC<Props> = (props: Props) => {
    const { getLS, setLS } = ls();
    const user = JSON.parse(getLS('user') || '{}');
    const { currentWorkspaceId, workspacesList } = useChangeWorkspace();
    const currentWorkspace = workspacesList.find((item: any) => item?.id === currentWorkspaceId);

    const queryClient = useQueryClient();
    const { mutateAsync: setDefaultCardAsync, isLoading: setDefaultLoading } = setDefaultCard();
    const { mutateAsync: createAlternativeCardAsync, isLoading: addingLoading } = createAlternativeCard();

    const [ccAdding, setccAdding] = useState(false);
    const formik = useFormik({
        initialValues: {
            card_name: '',
            number: '',
            exp_month: '',
            exp_year: '',
            exp_monthyear: '',
            cvv: '',
        },
        validationSchema: paymentDetailsValidationSchema,
        onSubmit: async (values) => {
            setccAdding(true);
            console.log({ values });
            const response = await addPaymentMethods({
                card: {
                    number: values.number,
                    exp_month: Number(values?.exp_monthyear?.split('/')?.[0]),
                    exp_year: Number(values?.exp_monthyear?.split('/')?.[1]),
                    cvc: values.cvv,
                },
                billing_details: {
                    name: values?.card_name || user?.name || `${user?.firstName} ${user?.lastName}`,
                    email: user?.email?.toLowerCase() || '',
                },
            });

            if(response?.id) {
                if(!!!props?.paymentCards?.length) {
                    const attachedPaymentMethod = await paymentMethodAttach({
                        email: user?.email?.toLowerCase() || '',
                        isDefault: true,
                        stripePaymentMethodId: response?.id,
                        workspaceid: currentWorkspaceId
                    })

                    console.log({ attachedPaymentMethod });

                    if(attachedPaymentMethod?.status === 'success') {
                        const defaultCardResponse = await setDefaultCardAsync({
                            stripeCustomerId: attachedPaymentMethod?.data?.updateCustomerDefaultPaymentMethod?.id ,
                            stripePaymentMethodId: response?.id || '',
                        });

                        if (defaultCardResponse?.status === 'success') {
                            formik.resetForm();
                            setLS('user', JSON.stringify({ ...user, stripeCustomerId: attachedPaymentMethod?.data?.updateCustomerDefaultPaymentMethod?.id }));
                            props.handleSubmit(attachedPaymentMethod);
                        }
                    }

                } else {
                    createAlternativeCardAsync(
                        { stripeCustomerId: currentWorkspace?.stripeCustomerId || user.stripeCustomerId, stripePaymentMethodId: response?.id ||'' },
                        {
                          onSuccess: () => {
                            queryClient.invalidateQueries(['stripeCustomerId']);
                            formik.resetForm();
                            props.handleSubmit(response);
                          },
                        },
                    );
                }
                
            } else {
                if(response?.code === 'incorrect_number') {
                    formik.setFieldError('number', 'Incorrect card number');
                } else if(response?.code === "invalid_expiry_year") {
                    formik.setFieldError('exp_monthyear', 'Invalid expiry year');
                } else if(response?.code === "invalid_cvc") {
                    formik.setFieldError('cvv', 'Invalid cvv');
                } else {
                    enqueueSnackbar(capitalizeFirstLetter(response?.message || ''), { variant: 'error' });
                }
                setccAdding(false);
            }

        },
    });
    return (
        <AddCreditCardModalCont>
            <Typography fontSize={24} fontWeight={700}>
                Add credit card
            </Typography>
            <FieldCont>
                <CustomTextField
                    fullWidth
                    name="card_name"
                    label="Card Name"
                    placeholder="Enter the name on your card"
                    value={formik.values.card_name}
                    onChange={formik.handleChange}
                    error={formik.touched.card_name && Boolean(formik.errors.card_name)}
                    helperText={formik.touched.card_name && formik.errors.card_name}
                    variant="standard"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    className="mb-4"
                />
                <CustomTextField
                    style={{ marginTop: 20 }}
                    fullWidth
                    name="number"
                    label="Card Number"
                    placeholder="0000 0000 0000 0000"
                    value={
                        formik.values.number
                        .replace(/[^0-9]/g, "")
                        .replace(/(\d{4})/g, "$1 ")
                        .trim()
                    }
                    onChange={formik.handleChange}
                    error={formik.touched.number && Boolean(formik.errors.number)}
                    helperText={formik.touched.number && formik.errors.number}
                    variant="standard"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{ maxLength: 19 }}
                    className="mb-4"
                />
            </FieldCont>
            <ExpCont>
                <CustomTextField
                    name="exp_monthyear"
                    label="Expiry Date"
                    placeholder="MM/YY"
                    value={
                        formik.values.exp_monthyear
                        .replace(/[^0-9]/g, '')
                        .replace(/^([2-9])$/g, '0$1')
                        .replace(/^(1{1})([3-9]{1})$/g, '0$1/$2')
                        .replace(/^0{1,}/g, '0')
                        .replace(/^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g, '$1/$2')
                        .trim()
                    }
                    onChange={formik.handleChange}
                    error={formik.touched.exp_monthyear && Boolean(formik.errors.exp_monthyear)}
                    helperText={formik.touched.exp_monthyear && formik.errors.exp_monthyear}
                    variant="standard"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{ maxLength: 5 }}
                    className="mb-4"
                />
                <CustomTextField
                    sx={cvvField}
                    type="password"
                    name="cvv"
                    label="CVV"
                    placeholder="****"
                    value={
                        formik.values.cvv
                        .replace(/[^0-9]/g, '')
                        .trim()
                    }
                    onChange={formik.handleChange}
                    error={formik.touched.cvv && Boolean(formik.errors.cvv)}
                    helperText={formik.touched.cvv && formik.errors.cvv}
                    variant="standard"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{ maxLength: 4 }}
                    className="mb-4"
                />
            </ExpCont>
            <Button
                sx={updateButton}
                type="submit"
                variant="contained"
                size="large"
                fullWidth
                onClick={() => formik.handleSubmit()}
                disabled={ccAdding || !formik.dirty}
            >
                <ConditionalProgress isLoading={ccAdding} color={COLORS.PRIMARY}>
                    Add Card
                </ConditionalProgress>
            </Button>
        </AddCreditCardModalCont>
    );
};

export default AddCreditCardModal;

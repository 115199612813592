import { ENDPOINTS } from '../constants/ENDPOINTS';
import { useAxios } from '../hooks/useAxios';
import {
  IDefaultResponsePayload,
  ISubscribeCustomerRequestPayload,
  IAddCustomerCardRequestPayload,
  ICustomerCardResponsePayload,
  IDeleteCustomerCardRequestPayload,
} from '../types/payment.types';

const { GET, POST, DELETE } = useAxios();

export const paymentDao = () => {

  const getCustomerCards = async ({ email }: {email: string}) => {
    try {
      const response = await GET<ICustomerCardResponsePayload>({
        url: `${ENDPOINTS.PAYMENT_SERVICE}/cards?email=${email}`,
      } as any);

      return response.data;
    } catch (e: any) {
      return e?.response?.data;
    }
  };

  const addCustomerCardDetails = async (payload: IAddCustomerCardRequestPayload) => {
    try {
      const response = await POST<ICustomerCardResponsePayload>({
        url: `${ENDPOINTS.PAYMENT_SERVICE}/customer`,
        data: payload,
      } as any);

      return response.data;
    } catch (e: any) {
      return e?.response?.data;
    }
  };

  const deleteCustomerCardDetails = async (payload: IDeleteCustomerCardRequestPayload) => {
    try {
      const response = await DELETE<IDefaultResponsePayload>({
        url: `${ENDPOINTS.PAYMENT_SERVICE}/customer`,
        data: payload,
      } as any);

      return response.data;
    } catch (e: any) {
      return e?.response?.data;
    }
  };

  const getSubscriptions = async ({workspaceid}: {workspaceid: string}) => {
    try {
      const response = await GET<IDefaultResponsePayload>({
        url: `${ENDPOINTS.PAYMENT_SERVICE}/subscriptions?workspaceid=${workspaceid}`,
      } as any);

      return response.data;
    } catch (e: any) {
      return e?.response?.data;
    }
  };

  const paymentSubscribe = async (payload: ISubscribeCustomerRequestPayload) => {
    try {
      const response = await POST<IDefaultResponsePayload>({
        url: `${ENDPOINTS.PAYMENT_SERVICE}/subscription`,
        data: payload,
      } as any);

      return response.data;
    } catch (e: any) {
      return e?.response?.data;
    }
  };

  const paymentUnsubscribe = async (payload: ISubscribeCustomerRequestPayload) => {
    try {
      const response = await DELETE<IDefaultResponsePayload>({
        url: `${ENDPOINTS.PAYMENT_SERVICE}/subscription`,
        data: payload,
      } as any);

      return response.data;
    } catch (e: any) {
      return e?.response?.data;
    }
  };

  return {
    getCustomerCards,
    addCustomerCardDetails,
    deleteCustomerCardDetails,
    getSubscriptions,
    paymentSubscribe,
    paymentUnsubscribe,
  };

};

import SignupForm from 'app/components/Authentication/Signup';
import AuthenticationLayout from 'app/components/Authentication/AuthenticationLayout';

const Signup = () => {

  return (
    <AuthenticationLayout>
      <div>
        <SignupForm />
      </div>
    </AuthenticationLayout>
  )
}

export default Signup
import { useNavigate } from 'react-router-dom';

import Button from 'app/shared/components/Button/Button';
import CheckMark from 'assets/images/check_mark.svg';

const SuccessView = () => {
    const navigate = useNavigate();

    const handleBackSignIn = () => {
        navigate('/');
    }
    return (
        <div style={{textAlign: 'center'}}>
            <div>
                <img src={CheckMark} alt="Success" className='illustration' style={{marginBottom: 0}} />
            </div>
            <div className='title'>Success</div>
            <div className='supporting'>Your password has been reset successfully.</div>
            <div className="buttons-wrapper" style={{ marginTop: 32 }}>
                <Button variant="contained" size="large" fullWidth className="back-to-sign-in-button" onClick={handleBackSignIn}>
                    Sign in
                </Button>
            </div>
        </div>
    )
}

export default SuccessView
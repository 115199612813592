import { useEffect, useState } from 'react';

import { ls } from '../utils/ls';
import { isJsonString } from '../utils/common';

export const useUserJobRole = () => {
  const { getLS } = ls();
  const [jobRole, setJobRole] = useState(isJsonString(getLS('user')) ? JSON.parse(getLS('user'))?.job_role : '');

  useEffect(() => {
    const handleStorageEvent = () => {
      //Redeclare to get the latest value
      const userjobrole = isJsonString(getLS('user')) ? JSON.parse(getLS('user'))?.job_role : '';

      const currentWorkspaceId = getLS('currentWorkspaceId');
      const workspacesList = isJsonString(getLS('workspaces')) ? JSON.parse(getLS('workspaces')) : [];
      const currentWorkspace = workspacesList.find((item: any) => item?.id === currentWorkspaceId);

      console.log({currentWorkspaceId, role: currentWorkspace})
      setJobRole(currentWorkspace?.job_role || userjobrole);
    };
    window.addEventListener('storage', handleStorageEvent);

    return () => window.removeEventListener('storage', handleStorageEvent);
  }, []);

  return { jobRole };
};

import { Grid, Typography } from '@mui/material'

import Button from 'app/shared/components/Button/Button'

import ThankYouIllustration from 'assets/images/thankyou_illustration.svg'
import { ThankYouWrapper, sub, title } from './ThankYouView.styles'
import { useNavigate } from 'react-router-dom';

interface IProps {
    signUp?: boolean
    handleShowThankYou: () => void
}

const ThankYouView = (props: IProps) => {
    const navigate = useNavigate();
    const handleClick = () => {
        props?.handleShowThankYou();
    }
    return (
        <ThankYouWrapper container justifyContent="center" alignItems="center">
            <Grid item xs={8} sm={7} md={5} lg={5} xl={3}>
                <img src={ThankYouIllustration} alt="Thank you" className='illustration'/>
                <Typography style={title}>Thank you!</Typography>
                <Typography style={sub}>Thank you for subscribing to a paid plan! You now have the ability to reveal your website visitors and earn their business.</Typography>
                {props.signUp ? <Button
                  type='submit'
                  variant='contained'
                  size='large'
                  fullWidth
                  className='thankyou-button'
                  onClick={handleClick}
                >Proceed to Dashboard</Button> :
                    <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    fullWidth
                    className="thankyou-button"
                    onClick={() => {navigate('/')}}
                    >Continue</Button>}

            </Grid>
        </ThankYouWrapper>
    )
}

export default ThankYouView

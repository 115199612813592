import { ENDPOINTS } from '../constants/ENDPOINTS';
import { useAxios } from '../hooks/useAxios';
import { IDefaultResponsePayload } from '../types/auth.types';
import { IWhiteLabelRequestPayload, IWorkspacePayload, IWorkspaceResponsePayload } from '../types/workspace.types';

const { GET, PUT, POST } = useAxios();

export const workspaceDao = () => {
  const retrieve = async (id: string) => {
    const response = await GET<IWorkspaceResponsePayload>({
      url: `${ENDPOINTS.WORKSPACE_SERVICE}/workspace?id=${encodeURIComponent(id)}`,
    } as any);

    return response?.data;
  };

  const retrieveAllWorkspaces = async () => {
    const response = await GET<IWorkspaceResponsePayload>({
      url: `${ENDPOINTS.WORKSPACE_SERVICE}/all-workspaces`,
    } as any);

    return response?.data;
  };

  const updateRequirePaymentMethod = async (payload: IWorkspacePayload) => {
    try {
      const response = await PUT<IDefaultResponsePayload>({
        url: `${ENDPOINTS.WORKSPACE_SERVICE}/updateRequirePaymentMethod`,
        data: payload,
      } as any);
  
      return response?.data;
    } catch (e: any) {
      return e?.response?.data;
    }
  };

  const unsubscribeEmail = async (payload: IWorkspacePayload) => {
    try {
      const response = await PUT<IDefaultResponsePayload>({
        url: `${ENDPOINTS.WORKSPACE_SERVICE}/unsubscribeEmail`,
        data: payload,
      } as any);

      return response?.data;
    } catch (e: any) {
      return e?.response?.data;
    }
  };

  const updateWhiteLabel = async (payload: IWhiteLabelRequestPayload) => {
    try {
      const response = await PUT<IDefaultResponsePayload>({
        url: `${ENDPOINTS.WORKSPACE_SERVICE}/whitelabel`,
        data: payload,
      } as any);

      return response?.data;
    } catch (e: any) {
      return e?.response?.data;
    }
  };

  const verifyDNS = async ({ domainName }: {domainName: string}) => {
    try {
      const response = await GET<IDefaultResponsePayload>({
        url: `${ENDPOINTS.WORKSPACE_SERVICE}/dns?domain=${domainName}`,
      } as any);

      return response?.data;
    } catch (e: any) {
      return e?.response?.data;
    }
  };

  return {
    retrieve,
    retrieveAllWorkspaces,
    updateRequirePaymentMethod,
    unsubscribeEmail,
    updateWhiteLabel,
    verifyDNS,
  };
};

import { Grid, styled } from "@mui/material";
import { COLORS } from "app/shared/constants/COLORS";

export const ThankYouWrapper = styled(Grid)(() => ({
    position: "fixed",
    background: COLORS.BG_WHITE,
    top: '25%',
    left: 0,
    right: 0,
    bottom: '25%',
    width: '50%',
    textAlign: 'center',

    '.illustration': {
        height: '150px',
    },

    '.thankyou-button': {
        fontSize: '16px',
        marginTop: 40,
    }
}));

export const title = {
    fontWeight: 400,
    fontSize: '64px',
}

export const sub = {
    fontWeight: 400,
    fontSize: '14px'
}
import React from 'react';
import { Grid } from '@mui/material';

import { LoginInfoGraphicsWrapper } from './InfoGraphics.styles';

import LoginInfoGraphics from 'assets/images/login_illustration.png';
import LoginInfoText from 'assets/images/login_text_illustration2.png';

const InfoGraphics = () => {
    return (
        <LoginInfoGraphicsWrapper container>
            <Grid item xs={6} textAlign="center" container justifyContent="center" alignItems="center">
                <img src={LoginInfoGraphics} alt="" width="90%" className="info-image" />
            </Grid>
            <Grid item xs={6} textAlign="center" container justifyContent="center" alignItems="center">
                <img src={LoginInfoText} alt="" width="90%" className="info-text" />
            </Grid>
        </LoginInfoGraphicsWrapper>
    )
}

export default InfoGraphics;
import { Backdrop } from '@mui/material';
import VitalEdgeLoaderIcon from 'assets/images/vital-edge-loader.gif';
import React from 'react';

const VitalEdgeLoader = ({ isOpen }: { isOpen: boolean }) => {
  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 9999999 }} open={isOpen}>
      <img src={VitalEdgeLoaderIcon} width={60} className="vitaledge-loader" />
    </Backdrop>
  );
};

export default VitalEdgeLoader;

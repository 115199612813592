import MemberSignupForm from 'app/components/Authentication/MemberSignup';
import AuthenticationLayout from 'app/components/Authentication/AuthenticationLayout';
import { useCheckAuthenticity } from 'app/shared/hooks/useCheckAuthenticity';
import { useEffect } from 'react';

const MemberSignup = () => {
  const { signOut } = useCheckAuthenticity();
  useEffect(() => {
    signOut(false);
  }, []);
  return (
    <AuthenticationLayout>
      <div>
        <MemberSignupForm />
      </div>
    </AuthenticationLayout>
  );
};

export default MemberSignup;

import Button from '../../Button/Button';
import { COLORS } from 'app/shared/constants/COLORS';
import { Alert, styled } from '@mui/material';

export const MainCont = styled(Alert)(() => ({
    justifyContent: 'center',
    fontSize: 14,
    color: COLORS.BG_WHITE,
    backgroundColor: '#D43125',
    borderRadius: 0,

    "svg.MuiSvgIcon-root": {
        path: {
            fill: COLORS.BG_WHITE,
        }
    },

    '.MuiAlert-icon': {
        padding: '3px 0',
    },

    '.MuiAlert-message': {
        padding: '4px 0',
    }
}));


export const ModalContentWrapper = styled('div')(({ theme }) => ({
    '.MuiFormControlLabel-label': {
        fontSize: 14,
    },
}));
import React from 'react';
import { Routes as Switch, Route, Navigate } from 'react-router-dom';

import { PublicRoutes } from './PublicRoutes';
import { PrivateRoutes } from './PrivateRoutes';
import { private_routes, public_routes } from './utils';

export const Routes = () => {

  //TODO : improve Private and Public route
  return (
    <React.Fragment>
      <Switch>
        {
          private_routes.map((route, index) => {
            if(route.children) {
              return route.children.map((child, index) => {
                return (
                  <Route
                    key={index}
                    path={child.path}
                    element={
                      <PrivateRoutes roles={child?.roles}>
                        <React.Suspense>
                          {child.element}
                        </React.Suspense>
                      </PrivateRoutes>
                    }
                  />
                )
              })
            }

            return (
              <Route
                key={index}
                path={route.path}
                element={
                  <PrivateRoutes roles={route?.roles}>
                    <React.Suspense>
                      {route.element}
                    </React.Suspense>
                  </PrivateRoutes>
                }
              />
            )
          })
        }
        {
          public_routes.map((route, index) => {
            if(route.children) {
              return route.children.map((child, index) => {
                return (
                  <Route
                    key={index}
                    path={child.path}
                    element={
                      <PublicRoutes>
                        {child.element}
                      </PublicRoutes>
                    }
                  />
                )
              })
            }

            return (
              <Route
                key={index}
                path={route.path}
                element={
                  <PublicRoutes>
                    {route.element}
                  </PublicRoutes>
                }
              />
            )
          })
        }

        {/* TODO: Create a NOT FOUND Page */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Switch>
    </React.Fragment>
  );
};

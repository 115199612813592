import React from 'react'

import { useChangeWorkspace } from 'app/shared/hooks/useChangeWorkspace';
import InsufficientCredits from '../InsufficientCredits/InsufficientCredits';
import TrialExpired from '../TrialExpired/TrialExpired';

const Banner = () => {
  const { currentWorkspaceId, workspacesList } = useChangeWorkspace();
  const currentWorkspace = workspacesList.find((item: any) => item?.id === currentWorkspaceId);

  return (
    <>
      {currentWorkspace?.trialExpired ? <TrialExpired /> : <InsufficientCredits />}
    </>
  )
}

export default Banner
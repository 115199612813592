import React from "react";
import { TextFieldProps } from '@mui/material/TextField';
import { StyledTextField } from "./TextField.styles";

const CustomTextField = (props: TextFieldProps) => {
    return (
        <StyledTextField 
            fullWidth
            variant="standard"
            InputLabelProps={{
                shrink: true,
            }}
            {...props}
        />
    )
}

export default CustomTextField;
import React, { createContext, useContext, useState } from 'react';

interface IGlobalContext {
  isCreatePixelModalVisible: boolean;
  setIsCreatePixelModalVisible: (value: boolean) => void;
  selectedPixelItem: any;
  setSelectedPixelItem: (value: Object) => void;
}

const GlobalContext = createContext<IGlobalContext>({
  isCreatePixelModalVisible: false,
  setIsCreatePixelModalVisible: () => {},
  selectedPixelItem: {},
  setSelectedPixelItem: () => {},
});

export function useGlobalContext() {
  return useContext(GlobalContext);
}

export function GlobalProvider(props: { children: React.ReactNode }) {
  const [isCreatePixelModalVisible, setIsCreatePixelModalVisible] = useState(false);
  const [selectedPixelItem, setSelectedPixelItem] = useState({});

  return (
    <GlobalContext.Provider
      value={{
        isCreatePixelModalVisible,
        setIsCreatePixelModalVisible,

        selectedPixelItem,
        setSelectedPixelItem,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
}

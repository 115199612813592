import React from 'react'
import ResendVerificationForm from 'app/components/Authentication/ResendVerification';
import AuthenticationLayout from 'app/components/Authentication/AuthenticationLayout';

const ResendVerification = () => {

  return (
    <AuthenticationLayout>
      <div>
        <ResendVerificationForm />
      </div>
    </AuthenticationLayout>
  )
}

export default ResendVerification

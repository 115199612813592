import { ENDPOINTS } from '../constants/ENDPOINTS';
import { useAxios } from '../hooks/useAxios';
import { IRegisterAffiliateRequestPayload, IUpdateAffiliateRequestPayload } from '../types/affiliate.types';
import { IDefaultResponsePayload } from '../types/auth.types';

const { POST, PUT } = useAxios();

export const affiliateDao = () => {
  const registerAffiliate = async (payload: IRegisterAffiliateRequestPayload) => {
    try {
      const response = await POST<IDefaultResponsePayload>({
        url: `${ENDPOINTS.AFFILIATE_SERVICE}/user`,
        data: payload,
      } as any);
      console.log({response})
      return response?.data;
    } catch (e: any) {
      return e?.response?.data;
    }
  };

  const updateAffiliate = async (payload: IUpdateAffiliateRequestPayload) => {
    try {
      const response = await PUT<IDefaultResponsePayload>({
        url: `${ENDPOINTS.AFFILIATE_SERVICE}/user`,
        data: payload,
      } as any);

      return response?.data;
    } catch (e: any) {
      return e?.response?.data;
    }
  };

  return {
    registerAffiliate,
    updateAffiliate,
  };
};

import React from 'react';
import { Navigate } from 'react-router-dom';

import Layout from '../components/Layout/Layout';
import { useCheckAuthenticity } from '../hooks/useCheckAuthenticity';
import { ROUTES } from '../constants/ROUTES';
import { useUserJobRole } from '../hooks/useUserJobRole';

interface Props {
  roles?: string[];
}

export const PrivateRoutes: React.FC<React.PropsWithChildren<Props>> = ({ children, roles }) => {
  const { isAuthenticated } = useCheckAuthenticity();
  const { jobRole } = useUserJobRole();
  
  const allowed = roles?.includes((jobRole)?.toLowerCase())
  // If authorized, redirect tp requested page
  // If not, redirect to login page
  const url = location.pathname;
  if (url.includes(ROUTES.UNSUBSCRIBE)) return <React.Fragment>{children}</React.Fragment>;
  if(!allowed) return <Navigate to="/" replace />;
  if (url.includes(ROUTES.PRICING)) return <React.Fragment>{children}</React.Fragment>;


  return isAuthenticated ? <Layout>{children}</Layout> : <Navigate to="/" replace />;
};

import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Grid, IconButton, InputAdornment } from '@mui/material'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import SuccessView from './SuccessView';
import CustomTextField from 'app/shared/components/TextField/TextField';
import Button from 'app/shared/components/Button/Button';
import PasswordValidationPopover from '../Signup/PasswordValidationPopover';
import { ConditionalProgress } from 'app/shared/components/ConditionalCircular/ConditionalCircular';
import CircularProgressWithLabel from 'app/shared/components/CircularProgressWithLabel/CircularProgressWithLabel';

import { memberSignupValidationSchema } from 'app/pages/Authentication/utils';
import { capitalizeFirstLetter } from 'app/shared/utils/helpers';

import { COLORS } from 'app/shared/constants/COLORS';;
import { ROUTES } from 'app/shared/constants/ROUTES';
import { userDao } from 'app/shared/dao/userDao';

import VitalEdgeLogo from 'assets/images/logo_dark.png';
import XMark from 'assets/images/x_mark.svg';
import { useCheckAuthenticity } from 'app/shared/hooks/useCheckAuthenticity';

const MemberSignup = () => {
    const navigate = useNavigate();
    let [searchParams] = useSearchParams();
    const { createMember, isActivationCodeValid } = userDao();
    const { handleSignInSuccess, setAuthenticating, authenticating } = useCheckAuthenticity();

    const [openPWValidation, setOpenPWValidation] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [passwordError, setPasswordError] = useState<string>('');
    const [success, setSuccess] = useState(false);
    const [checking, setChecking] = useState(false);
    const [invalidActivationCode, setInvalidActivationCode] = useState<any>(null);

    const formik = useFormik({
        initialValues: {
            password: '',
            confirm_password: '',
        },
        validationSchema: memberSignupValidationSchema,
        onSubmit: async (values) => {
            activateUser(values);
        },
    });

    useEffect(() => {
        setChecking(true);
        const checkActivationCode = async () => {
            const email = window.location.href.match(/email=(.*?)(&|$)/)?.[1] || '';
            const activationCode = searchParams.get('activationCode') || '';
            const workspaceid = window.location.href.match(/workspaceid=(.*?)(&|$)/)?.[1] || '';

            const res = await isActivationCodeValid({
                email: email?.toLowerCase() || '',
                activationCode,
                workspaceid,
            });

            if(res?.status === 'failed') {
                setInvalidActivationCode({
                    title: 'Sorry, this invite is no longer available',
                    message: 'Please coordinate with the administrator or workspace owner.',
                })
            }
            
            setChecking(false);
        }

        checkActivationCode();
    }, []);

    const activateUser = async (values: any) => {
        setSubmitting(true);
        setAuthenticating(true);
        const email = window.location.href.match(/email=(.*?)(&|$)/)?.[1] || '';
        const activationCode = searchParams.get('activationCode') || '';
        const workspaceid = window.location.href.match(/workspaceid=(.*?)(&|$)/)?.[1] || '';

        const res = await createMember({
            email: email?.toLowerCase() || '',
            activationCode,
            workspaceid,
            password: values.password,
        });
        
        if(res?.status === 'success') {
            handleSignInSuccess(res);
            // setSuccess(true);
            formik.resetForm();
        } else {
            enqueueSnackbar(capitalizeFirstLetter(res?.message || res?.error || ''), { variant: 'error' });
        }
        
        setSubmitting(false);
    }

    const handleLogin = () => {
        navigate(ROUTES.ROOT);
    }

    const handleClickShowPassword = () => {
        setShowPassword((show) => !show);
    }

    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword((show) => !show);
    }

    if(success) {
        return <SuccessView />
    }

    if(checking) {
        return (
            <>
                <div style={{textAlign: 'center'}}>
                    <div>
                        <div style={{marginBottom: 32}}>
                            <CircularProgressWithLabel size={72} />
                        </div>
                    </div>
                    <div className='title'>
                        Validating Invitation Link
                    </div>
                    <div className='supporting'>
                        Please wait while we validate your invitation link
                    </div>
                </div>
            </>
        )
    }

    if(!checking && !!invalidActivationCode) {
        return (
            <>
                <img src={VitalEdgeLogo} alt="Vital Edge" />
                <div style={{textAlign: 'center'}}>
                    <div>
                        <img src={XMark} alt="Error" className='illustration' height={'80px'}/>
                    </div>
                    <div className='title'>
                        {invalidActivationCode?.title || 'Sorry, this invite is no longer available'}
                    </div>
                    <div className='supporting'>
                        {invalidActivationCode?.message || 'Please coordinate with the administrator or workspace owner.'}
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            <div className='title'>Create Account</div>
            <div className='supporting'>Create a password to proceed to dashboard</div>
            <form onSubmit={formik.handleSubmit} className="member-signup-form">
                <PasswordValidationPopover open={openPWValidation} value={formik.values.password} setPasswordError={setPasswordError}>
                    <CustomTextField
                        fullWidth
                        type={showPassword ? "text" : "password"}
                        id="password"
                        name="password"
                        placeholder="Password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onFocus={() => setOpenPWValidation(true)}
                        onBlur={() => setOpenPWValidation(false)}
                        error={(!!!openPWValidation && !!passwordError) || (formik.touched.password && Boolean(formik.errors.password))}
                        helperText={(!!!openPWValidation && passwordError) || formik.touched.password && formik.errors.password}
                        variant="standard"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        edge="end"
                                    >
                                        {!showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        className='mb-4 custom-login-input'
                    />
                </PasswordValidationPopover>
                <CustomTextField
                    fullWidth
                    type={showConfirmPassword ? "text" : "password"}
                    id="confirm_password"
                    name="confirm_password"
                    placeholder="Confirm Password"
                    value={formik.values.confirm_password}
                    onChange={formik.handleChange}
                    error={formik.touched.confirm_password && Boolean(formik.errors.confirm_password)}
                    helperText={formik.touched.confirm_password && formik.errors.confirm_password}
                    variant="standard"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowConfirmPassword}
                                    edge="end"
                                >
                                    {!showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    className='mb-16 custom-login-input'
                />
                <Grid xs={12}>
                    <Button 
                        type="submit"
                        variant="contained" 
                        size="large"
                        fullWidth
                        className="submit-button"
                        disabled={submitting || !formik.dirty || authenticating}
                    >
                        <ConditionalProgress isLoading={submitting || authenticating} color={COLORS.PRIMARY}>
                            Create Account
                        </ConditionalProgress>
                    </Button>
                </Grid>
                <Grid container xs={12} justifyContent="center" alignItems="center" className="signup-btn-wrapper"> 
                    Already have an account?<span className="signup-btn-text" onClick={handleLogin}>Log In</span>
                </Grid>
                <Grid container xs={12} justifyContent="center" alignItems="center" className="supporting terms-wrapper"> 
                    By creating an account, you have agreed to our&nbsp;
                    <div className="terms-btn-text" onClick={() => window.open("https://vitaledge.io/terms/")}>Terms & conditions</div>
                </Grid>
            </form>
        </>
    )
};

export default MemberSignup;

import { ENDPOINTS } from '../constants/ENDPOINTS';
import { useAxios } from '../hooks/useAxios';
import { IPlansResponsePayload, IRetrieveOrderSummaryRequestPayload, IRetrieveOrderSummaryResponsePayload } from '../types/pricing.types';

const { GET } = useAxios();

export const pricingDao = () => {
  const plans = async (version: number) => {
    const response = await GET<IPlansResponsePayload>({
      url: `${ENDPOINTS.PRICING_SERVICE}/getPlans?version=${version}`
    } as any);

    return response.data;
  }

  const retrieveOrderSummary = async (payload: IRetrieveOrderSummaryRequestPayload) => {
    if(!payload?.id) return Promise.resolve({data: {}});
    try {
      let queryParams = `?id=${payload.id}&planInterval=${payload.planInterval}`;
      if(payload?.couponCode) queryParams += `&couponCode=${encodeURIComponent(payload.couponCode)}`;
      
      const response = await GET<IRetrieveOrderSummaryResponsePayload>({
        url: `${ENDPOINTS.PRICING_SERVICE}/retrieveOrderSummary${queryParams}`
      } as any);
  
      return response?.data;
    } catch (e: any) {
      return e?.response?.data;
    }
  }

  return {
    plans,
    retrieveOrderSummary
  };

};

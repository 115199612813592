import React from 'react';
import { CustomModal } from './CustomModal';
import { SuccessCont, closeButton, successButton } from './Modal.styles';
import { SuccessIcon } from '../components/Icon/Icons';
import { Button, IconButton, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';

interface Props {
  onClose: VoidFunction;
  open: boolean;
  successMessge: any;
  buttonLabel?: string;
  buttonAction?: VoidFunction;
  backToDashboard?: boolean;
}

const SuccessModal: React.FC<Props> = ({
  onClose,
  open,
  successMessge,
  buttonLabel,
  buttonAction,
  backToDashboard,
}) => {
  const navigate = useNavigate();
  const handleButtonClick = () => {
    onClose();
    if (buttonAction) {
      buttonAction();
    } else {
      // navigate('/dashboard');
      onClose();
    }
  };
  return (
    <CustomModal onClose={onClose} open={open}>
      <SuccessCont>
        <SuccessIcon size={130} />
        <Typography fontSize={32} fontWeight={700} marginTop={-2}>
          Success!
        </Typography>
        <Typography marginTop={1} fontSize={16}>
          {successMessge}
        </Typography>
        <Button sx={successButton} type="submit" variant="contained" size="large" onClick={handleButtonClick}>
          {buttonLabel ? buttonLabel : 'Close'}
        </Button>
        {backToDashboard && (
          <div className="back-to-dashboard" onClick={() => navigate('/dashboard')}>
            Back to dashboard
          </div>
        )}
      </SuccessCont>
    </CustomModal>
  );
};

export default SuccessModal;

import { HeaderContainer } from './Header.styles';
import Balance from './Balance/Balance';
import ProfileAvatar from './ProfileAvatar/ProfileAvatar';
import { useChangeWorkspace } from 'app/shared/hooks/useChangeWorkspace';
import { ls } from 'app/shared/utils/ls';
import { userServices } from 'app/shared/services/userServices';
import React, { useEffect, useMemo } from 'react';
import { IWorkspace } from 'app/shared/types/user.types';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Button from '../Button/Button';
import MenuIcon from '@mui/icons-material/Menu';
import { useLocation } from 'react-router-dom';
import WorkspacesList from './Workspaces/Workspaces';
import { Box, Stack, Typography } from '@mui/material';

const { getMembers, getWorkspaces } = userServices();

const Header = ({ setIsSidebarCollapsed }: { setIsSidebarCollapsed: Function }) => {
  const { getLS, setLS } = ls();
  const { currentWorkspaceId } = useChangeWorkspace();
  const location = useLocation();
  const {
    data: membersData,
    isLoading: membersLoading,
    refetch: membersRefetch,
  } = getMembers(currentWorkspaceId || getLS('currentWorkspaceId'));
  const currentMemberData: any = useMemo(
    () =>
      membersData?.data?.length
        ? membersData?.data?.find((m: any) => m?.email === JSON.parse(getLS('user'))?.email) || {}
        : {},
    [membersData],
  );

  const { data: workspacesData, isLoading: isLoadingWorkspaces, refetch } = getWorkspaces(currentMemberData?.email?.toLowerCase() || '');
  const workspacesList = useMemo(
    () =>
      workspacesData?.workspaces?.length
        ? workspacesData?.workspaces?.map((m: IWorkspace) => ({
            value: m?.id,
            label: m?.workspace,
          }))
        : [],
    [workspacesData?.workspaces],
  );

  useEffect(() => {
    const oldUserData = JSON.parse(getLS('user'));
    setLS('user', JSON.stringify({ ...oldUserData, ...currentMemberData, workspaces: workspacesList }));
  }, [currentMemberData]);

  useEffect(() => {
    if(location?.state?.refetchWorkspaces) {
      refetch();
    }
  }, [location?.state?.refetchWorkspaces])

  useEffect(() => {
    if(workspacesData?.workspaces) {
      setLS('workspaces', JSON.stringify(workspacesData?.workspaces));
    }
  }, [workspacesData?.workspaces])

  return (
    <HeaderContainer>
      <Button sx={{ height: '100%' }} onClick={() => setIsSidebarCollapsed()}>
        {/*{isSidebarCollapsed ? <MenuIcon fontSize={'large'} /> : <ArrowBackIosNewIcon fontSize={'medium'} />}*/}
        <MenuIcon fontSize={'large'} />
      </Button>
      {/*<Box alignContent={'center'}>*/}
      {/*  <Typography>*/}
      {/*    Sample Workspace <br/>*/}
      {/*    Tier 1 Monthly*/}
      {/*  </Typography>*/}
      {/*</Box>*/}
      <WorkspacesList/>
      <Balance />
      <ProfileAvatar />
    </HeaderContainer>
  );
};

export default Header;

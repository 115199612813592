import Button from '../../Button/Button';
import { COLORS } from 'app/shared/constants/COLORS';
import { Grid, styled } from '@mui/material';

export const MainCont = styled(Grid)(() => ({
  // marginLeft: 'auto',
  display: 'flex',
  alignItems: 'center',
  color: 'black',
  fontSize: 18,
}));

export const Avatar = styled('img')(() => ({
  borderRadius: '50%',
  width: 40,
  height: 40,
  marginLeft: 10,
  padding: 2,
  border: `1px solid ${COLORS.PRIMARY}`,
  objectFit: 'cover',
}));

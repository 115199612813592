export const COLORS = {
  PRIMARY: '#942977',
  PRIMARY_LIGHT: '#EEDDE6',
  SECONDARY: '#585E56',
  WARNING: '#FFC22E',
  ERROR: '#FF3B2D',
  ERROR_LIGHT: '#FFF1F0',
  ERROR_DARK: '#922119',
  SUCCESS: '#20AF0B',
  PRIMARY_DARK: '#631B4F',
  YELLOW: '#FFC656',
  GREEN: '#22AD95',
  GREEN_LIGHT: '#1FC1A6',
  BG_WHITE: '#fff',
  BG_SIDEBAR: '#F8F8F8',
  GRAY: '#D8D8D8',
  LIGHT_GRAY: '#E6E6E6',
  TEXT_GRAY: '#7B7579',
  TEXT_LIGHT_GRAY: '#B8B8B8',
  TEXT_LIGHT_GRAY_2: '#E0E0E0',
  BORDER_GRAY: '#CECFCF',
  LABEL_NORMAL: '#310E28',
  BUTTON_BLACK: '#353537',
  BLUE: '#152C7D',
  TEXT_LABEL_GRAY: '#868687',

  CARD_DARK_BLUE: '#0B1433',
  CARD_GRAY: '#ECEEF0',
  CARD_LIGHT_GRAY: '#D7DCE0',

  GAUGE_GREEN: '#27BE63',
  GAUGE_LIGHT_GREEN: '#E5FCEB',
  BLACK: '#0C0D0F',
  PINK: '#FFF5FD',
  DARK_PINK: '#B870A4',
  LIGHT_PINK: '#E9CDE2',
  RED: '#D53126',
};

export const LINEAR_GRADIENTS = {
  ONE: 'linear-gradient(251.59deg, #726E9E -1.95%, #042843 103.4%)',
  TWO: 'linear-gradient(251.59deg, #689DAA -1.95%, #283E43 103.4%)',
  THREE: 'linear-gradient(0deg, #1692F4, #1692F4), linear-gradient(251.59deg, #1692F4 -1.95%, #0F61A3 103.4%)',
  FOUR: 'linear-gradient(251.59deg, #9D8B68 -1.95%, #433F28 103.4%)',
  FIVE: 'linear-gradient(251.59deg, #9D6868 -1.95%, #432828 103.4%)',
  SIX: 'linear-gradient(251.59deg, #BC78EC -1.95%, #3B2667 103.4%)',
  SEVEN: 'linear-gradient(251.59deg, #ff0084 -1.95%, #33001b 103.4%)',
  EIGHT: 'linear-gradient(251.59deg, #eea849 -1.95%, #f46b45 103.4%)',
  NINE: 'linear-gradient(251.59deg, #F4A9A8 -1.95%, #A33B3A 103.4%)',
};

import { styled } from '@mui/material';
import { MaterialDesignContent } from 'notistack';

export const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-success': {
    backgroundColor: '#F0FFEE',
    color: '#157507',
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: '#f7c0bc',
  },
}));

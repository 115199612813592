import { ENDPOINTS } from '../constants/ENDPOINTS';
import { useAxios } from '../hooks/useAxios';
import {
  IDefaultResponsePayload,
  IForgotPasswordRequestPayload,
  ISignInRequestPayload,
  ISignInResponsePayload,
  IUpdatePasswordRequestPayload,
  IValidatePassResponse,
  IValidationRequestPayload,
} from '../types/auth.types';

const { GET, POST } = useAxios();

export const authDao = () => {
  const login = async (payload: ISignInRequestPayload) => {
    try {
      const response = await POST<ISignInResponsePayload>({
        url: `${ENDPOINTS.USER_SERVICE}/signin`,
        data: payload,
      } as any);
  
      return response?.data;
    } catch (e: any) {
      return e?.response?.data;
    }
  };

  const forgotPassword = async (payload: IForgotPasswordRequestPayload) => {
    try {
      const response = await POST<IDefaultResponsePayload>({
        url: `${ENDPOINTS.USER_SERVICE}/forgotpassword`,
        data: payload,
      } as any);

      return response?.data;
    } catch (e: any) {
      return e?.response?.data;
    }
  };

  const validatePassword = async (payload: IValidationRequestPayload) => {
    const response = await POST<IValidatePassResponse>({
      url: `${ENDPOINTS.USER_SERVICE}/validatePassword`,
      data: payload,
    } as any);

    return response?.data;
  };

  const updatePassword = async (payload: IUpdatePasswordRequestPayload) => {
    const response = await POST<any>({
      url: `${ENDPOINTS.USER_SERVICE}/updatepassword`,
      data: payload,
    } as any);

    return response?.data;
  };

  const resetPassword = async (payload: IUpdatePasswordRequestPayload) => {
    try {
      const response = await POST<any>({
        url: `${ENDPOINTS.USER_SERVICE}/resetpassword`,
        data: payload,
      } as any);
  
      return response?.data;
    } catch (e: any) {
      return e?.response?.data;
    }
  };

  const resendActivation = async (email: string) => {
    try {
      const response = await POST<IDefaultResponsePayload>({
        url: `${ENDPOINTS.USER_SERVICE}/resendActivation`,
        data: { email },
      } as any);
  
      return response?.data;
    } catch (e: any) {
      return e?.response?.data;
    }
  };

  const validate = async (email: string, workspaceid: string, activationCode: string) => {
    const response = await GET<IDefaultResponsePayload>({
      url: `${ENDPOINTS.USER_SERVICE}/activatecode?email=${email}&workspaceid${workspaceid}&activationCode=${activationCode}`,
    } as any);

    return response?.data;
  };

  return {
    login,
    forgotPassword,
    updatePassword,
    validatePassword,
    resetPassword,
    validate,
    resendActivation,
  };
};

import React from 'react'

import ForgotPasswordForm from 'app/components/Authentication/ForgotPassword';
import AuthenticationLayout from 'app/components/Authentication/AuthenticationLayout';

const ForgotPassword = () => {

  return (
    <AuthenticationLayout>
      <div>
        <ForgotPasswordForm />
      </div>
    </AuthenticationLayout>
  )
}

export default ForgotPassword
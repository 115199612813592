import { ROUTES } from './ROUTES';

export const SIDEBAR_ITEMS = [
  {
    name: 'Dashboard',
    disabled: false,
    url: ROUTES.DASHBOARD,
    icon: 'Dashboard',
    roles: ['owner', 'member']
  },
  {
    name: 'Manage Pixels',
    disabled: false,
    url: ROUTES.MANAGE_PIXELS,
    icon: 'ManagePixel',
    roles: ['owner', 'member']
  },
  {
    name: 'Settings',
    disabled: false,
    url: ROUTES.SETTINGS,
    icon: 'Settings',
    roles: ['owner']
  },
];

export const BOTTOM_SIDEBAR_ITEMS = [
  {
    name: 'Invite Team Members',
    disabled: false,
    url: ROUTES.INVITE_TEAM_MEMBERS,
    icon: 'InviteMemberIcon',
    roles: ['owner']
  },
  {
    name: 'Upgrade or Downgrade Plan',
    disabled: false,
    url: ROUTES.UPGRADE_CHANGE_PLAN,
    icon: 'UpgradeDowngradePlanIcon',
    roles: ['owner']
  },
];

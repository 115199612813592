import { googleDao } from 'app/shared/dao/googleDao';
import { IUser } from 'app/shared/types/user.types';
import { ls } from 'app/shared/utils/ls';
import React, { useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import VitalEdgeLoader from '../Loader/VitalEdgeLoader';

const { connect } = googleDao();

const GoogleIntegration: React.FC = () => {
  const { getLS, setLS } = ls();
  const [searchParams, setSearchParams] = useSearchParams();
  const { workspaceid }: IUser = JSON.parse(getLS('user' || '{}'));
  const location = useLocation();

  useEffect(() => {
    const queryParams = location.search;
    console.log({ queryParams });

    if (queryParams) {
      const authCode = searchParams.get('code');
      const error = searchParams.get('error');
      console.log({error})
      if (error === 'access_denied') {
        setLS('googleAccountInfo', JSON.stringify({error: 'access_denied'}));
        setLS('googleWindow', 'close');
        window.close();
      } else if (authCode) {
        console.log(authCode, workspaceid);
        (async (auth: string) => {
          const res = await connect(auth, workspaceid);
          console.log('res google connect: ', res);
          setLS('googleAccountInfo', JSON.stringify(res.data));
          setLS('googleWindow', 'close');
          window.close();
        })(authCode);
      }
    }
  }, []);

  return <VitalEdgeLoader isOpen={true} />;
};

export default GoogleIntegration;

import { ENDPOINTS } from '../constants/ENDPOINTS';
import { useAxios } from '../hooks/useAxios';
import {
  IAddCardRequestPayload,
  ICardRequestPayload,
  ICardResponsePayload,
  IDefaultCardRequestPayload,
  IPaymentMethodRequestPayload,
  IPaymentMethodResponsePayload,
  InvoicesResponsePayload,
  StripeCustomerResponsePayload
} from '../types/billing.types';

const { GET, POST } = useAxios();

export const billingDao = () => {
  const paymentMethods = async (payload: IPaymentMethodRequestPayload) => {
    if(!payload?.stripeCustomerId) return Promise.resolve({data: { data: [] }});
    const response = await POST<IPaymentMethodResponsePayload>({
      url: `${ENDPOINTS.STRIPE_SERVICE}/customerPaymentMethods`,
      data: payload
    } as any);

    return response?.data;
  };

  const deleteCard = async (stripePaymentMethodId: string) => {
    const response = await POST<IPaymentMethodResponsePayload>({
      url: `${ENDPOINTS.STRIPE_SERVICE}/deleteCard`,
      data: { stripePaymentMethodId },
    } as any);

    return response.data;
  };

  const invoices = async (stripeCustomerId: string) => {
    try {
      if(!stripeCustomerId) return Promise.resolve([]);
      const response = await GET<InvoicesResponsePayload>({
        url: `${ENDPOINTS.STRIPE_SERVICE}/getInvoices?stripeCustomerId=${stripeCustomerId}`,
      } as any);
  
      return response?.data?.data;
    } catch (e: any) {
      return e?.response?.data;
    }
  };

  const setDefaultCard = async (payload: IDefaultCardRequestPayload) => {
    try {
      const response = await POST<ICardResponsePayload>({
        url: `${ENDPOINTS.STRIPE_SERVICE}/setDefaultCard`,
        data: payload,
      } as any);
  
      return response.data;
    } catch (e: any) {
      return e?.response?.data;
    }
  };

  const createAlternativeCard = async (payload: IAddCardRequestPayload) => {
    const response = await POST<ICardResponsePayload>({
      url: `${ENDPOINTS.STRIPE_SERVICE}/addAlternativeCard`,
      data: payload,
    } as any);

    return response.data;
  };

  const getStripeCustomer = async (stripeCustomerId: string) => {
    try {
      if(!stripeCustomerId) return Promise.resolve([]);
      const response = await GET<StripeCustomerResponsePayload>({
        url: `${ENDPOINTS.STRIPE_SERVICE}/retrieveCustomer?stripeCustomerId=${stripeCustomerId}`,
      } as any);
  
      return response?.data?.data;
    } catch (e: any) {
      return e?.response?.data;
    }
  };

  return {
    paymentMethods,
    deleteCard,
    invoices,
    setDefaultCard,
    createAlternativeCard,
    getStripeCustomer,
  };
};

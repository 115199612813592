import React, { useEffect } from 'react';
import { SideBarCont, SidebarButton, SidebarButtonActive, Logo } from './Sidebar.styles';
import { BOTTOM_SIDEBAR_ITEMS, SIDEBAR_ITEMS } from 'app/shared/constants/SIDEBAR_ITEMS';
import { useNavigate, useLocation } from 'react-router-dom';
import { SidebarIcon } from '../Icon/Icons';
import { COLORS } from 'app/shared/constants/COLORS';
import { Divider, Typography } from '@mui/material';
import { useCheckAuthenticity } from 'app/shared/hooks/useCheckAuthenticity';
import { useChangeWorkspace } from '../../hooks/useChangeWorkspace';
import { useUserJobRole } from '../../hooks/useUserJobRole';

const Sidebar = ({isAdmin} : {isAdmin : boolean | null}) => {
  const { signOut } = useCheckAuthenticity();
  const {currentWorkspace} = useChangeWorkspace()
  const navigate = useNavigate();
  const location = useLocation();
  const { jobRole } = useUserJobRole();

  const logout = () => {
    signOut();
  };

  useEffect(() => {
    if(currentWorkspace.companyHeader){
      document.title = currentWorkspace.companyHeader
    }
    const link = document.querySelector("link[rel~='icon']") as HTMLLinkElement | null;
    if (link) {
      link.href = currentWorkspace.logo || require('../../../../assets/images/vital-edge-ic.png');
    } else {
      const newLink = document.createElement('link');
      newLink.rel = 'icon';
      newLink.href = currentWorkspace.logo || require('../../../../assets/images/vital-edge-ic.png');
      document.head.appendChild(newLink);
    }
  }, [currentWorkspace.companyHeader, currentWorkspace.logo])

  return (
    <SideBarCont>
      <Logo src={currentWorkspace.logo || require('../../../../assets/images/logo_dark.png')} sx={{height: currentWorkspace.logo ? 100 : 45}} alt="" />
      {SIDEBAR_ITEMS.map((i, n: number) => {
        if(!i.roles.includes((jobRole)?.toLowerCase())){
          return;
        }
        let isSelected = location.pathname.includes(i.url);
        return (
          <SidebarButton
            key={n}
            sx={isSelected ? SidebarButtonActive : null}
            onClick={() => navigate(i.url)}
            disabled={i.disabled}
          >
            <div style={{ position: 'absolute', left: 18, top: 'calc(50% - 9px)' }}>
              <SidebarIcon   name={i.icon} size={18} color={isSelected ? COLORS.BG_WHITE : COLORS.TEXT_GRAY} />
            </div>
            <Typography style={{ textTransform: 'none', fontSize: 16 }}>{i.name}</Typography>
          </SidebarButton>
        );
      })}
      <Divider sx={{my: 2}}/>
      {BOTTOM_SIDEBAR_ITEMS.map((i, n: number) => {
        if(!i.roles.includes((jobRole)?.toLowerCase())){
          return;
        }
        if(currentWorkspace?.notUpgradable && i.name === 'Upgrade/Downgrade Plan') {
          return;
        }
        let isSelected = location.pathname.includes(i.url);
        return (
          <SidebarButton
            key={n}
            sx={isSelected ? SidebarButtonActive : null}
            onClick={() => navigate(i.url)}
            disabled={i.disabled}
          >
            <div style={{ position: 'absolute', left: 18, top: 'calc(50% - 9px)' }}>
              <SidebarIcon name={i.icon} size={18} color={isSelected ? COLORS.BG_WHITE : COLORS.TEXT_GRAY} />
            </div>
            <Typography style={{ textTransform: 'none', fontSize: 16, lineHeight: 1, textAlign: 'left' }}>{i.name}</Typography>
          </SidebarButton>
        );
      })}
    </SideBarCont>
  );
};

export default Sidebar;

const COUNTRIES = [
  {"country": "United States of America", "states": [
      "Alabama",
      "Alaska",
      "Arizona",
      "Arkansas",
      "California",
      "Colorado",
      "Connecticut",
      "Delaware",
      "Florida",
      "Georgia",
      "Hawaii",
      "Idaho",
      "Illinois",
      "Indiana",
      "Iowa",
      "Kansas",
      "Kentucky",
      "Louisiana",
      "Maine",
      "Maryland",
      "Massachusetts",
      "Michigan",
      "Minnesota",
      "Mississippi",
      "Missouri",
      "Montana",
      "Nebraska",
      "Nevada",
      "New Hampshire",
      "New Jersey",
      "New Mexico",
      "New York",
      "North Carolina",
      "North Dakota",
      "Ohio",
      "Oklahoma",
      "Oregon",
      "Pennsylvania",
      "Rhode Island",
      "South Carolina",
      "South Dakota",
      "Tennessee",
      "Texas",
      "Utah",
      "Vermont",
      "Virginia",
      "Washington",
      "West Virginia",
      "Wisconsin",
      "Wyoming"
    ]},
  {"country": "Afghanistan", "states": []},
  {"country": "Albania", "states": []},
  {"country": "Algeria", "states": []},
  {"country": "Andorra", "states": []},
  {"country": "Angola", "states": []},
  {"country": "Antigua and Barbuda", "states": []},
  {"country": "Argentina", "states": []},
  {"country": "Armenia", "states": []},
  {"country": "Australia", "states": [
      "New South Wales",
      "Queensland",
      "South Australia",
      "Tasmania",
      "Victoria",
      "Western Australia"
    ]},
  {"country": "Austria", "states": [
      "Burgenland",
      "Carinthia",
      "Lower Austria",
      "Salzburg",
      "Styria",
      "Tyrol",
      "Upper Austria",
      "Vienna",
      "Vorarlberg"
    ]},
  {"country": "Azerbaijan", "states": []},
  {"country": "Bahamas", "states": []},
  {"country": "Bahrain", "states": []},
  {"country": "Bangladesh", "states": []},
  {"country": "Barbados", "states": []},
  {"country": "Belarus", "states": []},
  {"country": "Belgium", "states": []},
  {"country": "Belize", "states": []},
  {"country": "Benin", "states": []},
  {"country": "Bhutan", "states": []},
  {"country": "Bolivia", "states": []},
  {"country": "Bosnia and Herzegovina", "states": []},
  {"country": "Botswana", "states": []},
  {"country": "Brazil", "states": [
      "Acre",
      "Alagoas",
      "Amapá",
      "Amazonas",
      "Bahia",
      "Ceará",
      "Distrito Federal",
      "Espírito Santo",
      "Goiás",
      "Maranhão",
      "Mato Grosso",
      "Mato Grosso do Sul",
      "Minas Gerais",
      "Pará",
      "Paraíba",
      "Paraná",
      "Pernambuco",
      "Piauí",
      "Rio de Janeiro",
      "Rio Grande do Norte",
      "Rio Grande do Sul",
      "Rondônia",
      "Roraima",
      "Santa Catarina",
      "São Paulo",
      "Sergipe",
      "Tocantins"
    ]},
  {"country": "Brunei", "states": []},
  {"country": "Bulgaria", "states": []},
  {"country": "Burkina Faso", "states": []},
  {"country": "Burundi", "states": []},
  {"country": "Cabo Verde", "states": []},
  {"country": "Cambodia", "states": []},
  {"country": "Cameroon", "states": []},
  {"country": "Canada", "states": []},
  {"country": "Central African Republic", "states": []},
  {"country": "Chad", "states": []},
  {"country": "Chile", "states": []},
  {"country": "China", "states": []},
  {"country": "Colombia", "states": []},
  {"country": "Comoros", "states": []},
  {"country": "Congo (Congo-Brazzaville)", "states": []},
  {"country": "Costa Rica", "states": []},
  {"country": "Croatia", "states": []},
  {"country": "Cuba", "states": []},
  {"country": "Cyprus", "states": []},
  {"country": "Czechia (Czech Republic)", "states": []},
  {"country": "Democratic Republic of the Congo", "states": []},
  {"country": "Denmark", "states": []},
  {"country": "Djibouti", "states": []},
  {"country": "Dominica", "states": []},
  {"country": "Dominican Republic", "states": []},
  {"country": "Ecuador", "states": []},
  {"country": "Egypt", "states": []},
  {"country": "El Salvador", "states": []},
  {"country": "Equatorial Guinea", "states": []},
  {"country": "Eritrea", "states": []},
  {"country": "Estonia", "states": []},
  {"country": "Eswatini (fmr. 'Swaziland')", "states": []},
  {"country": "Ethiopia", "states": []},
  {"country": "Fiji", "states": []},
  {"country": "Finland", "states": []},
  {"country": "France", "states": []},
  {"country": "Gabon", "states": []},
  {"country": "Gambia", "states": []},
  {"country": "Georgia", "states": []},
  {"country": "Germany", "states": [
      "Baden-Württemberg",
      "Bavaria",
      "Berlin",
      "Brandenburg",
      "Bremen",
      "Hamburg",
      "Hesse",
      "Lower Saxony",
      "Mecklenburg-Vorpommern",
      "North Rhine-Westphalia",
      "Rhineland-Palatinate",
      "Saarland",
      "Saxony",
      "Saxony-Anhalt",
      "Schleswig-Holstein",
      "Thuringia"
    ]},
  {"country": "Ghana", "states": []},
  {"country": "Greece", "states": []},
  {"country": "Grenada", "states": []},
  {"country": "Guatemala", "states": []},
  {"country": "Guinea", "states": []},
  {"country": "Guinea-Bissau", "states": []},
  {"country": "Guyana", "states": []},
  {"country": "Haiti", "states": []},
  {"country": "Honduras", "states": []},
  {"country": "Hungary", "states": []},
  {"country": "Iceland", "states": []},
  {"country": "India", "states": [
      "Andhra Pradesh",
      "Arunachal Pradesh",
      "Assam",
      "Bihar",
      "Chhattisgarh",
      "Goa",
      "Gujarat",
      "Haryana",
      "Himachal Pradesh",
      "Jharkhand",
      "Karnataka",
      "Kerala",
      "Madhya Pradesh",
      "Maharashtra",
      "Manipur",
      "Meghalaya",
      "Mizoram",
      "Nagaland",
      "Odisha",
      "Punjab",
      "Rajasthan",
      "Sikkim",
      "Tamil Nadu",
      "Telangana",
      "Tripura",
      "Uttar Pradesh",
      "Uttarakhand",
      "West Bengal"
    ]},
  {"country": "Indonesia", "states": []},
  {"country": "Iran", "states": []},
  {"country": "Iraq", "states": []},
  {"country": "Ireland", "states": []},
  {"country": "Israel", "states": []},
  {"country": "Italy", "states": []},
  {"country": "Jamaica", "states": []},
  {"country": "Japan", "states": []},
  {"country": "Jordan", "states": []},
  {"country": "Kazakhstan", "states": []},
  {"country": "Kenya", "states": []},
  {"country": "Kiribati", "states": []},
  {"country": "Kuwait", "states": []},
  {"country": "Kyrgyzstan", "states": []},
  {"country": "Laos", "states": []},
  {"country": "Latvia", "states": []},
  {"country": "Lebanon", "states": []},
  {"country": "Lesotho", "states": []},
  {"country": "Liberia", "states": []},
  {"country": "Libya", "states": []},
  {"country": "Liechtenstein", "states": []},
  {"country": "Lithuania", "states": []},
  {"country": "Luxembourg", "states": []},
  {"country": "Madagascar", "states": []},
  {"country": "Malawi", "states": []},
  {"country": "Malaysia", "states": [
      "Johor",
      "Kedah",
      "Kelantan",
      "Malacca",
      "Negeri Sembilan",
      "Pahang",
      "Penang",
      "Perak",
      "Perlis",
      "Sabah",
      "Sarawak",
      "Selangor",
      "Terengganu"
    ]},
  {"country": "Maldives", "states": []},
  {"country": "Mali", "states": []},
  {"country": "Malta", "states": []},
  {"country": "Marshall Islands", "states": []},
  {"country": "Mauritania", "states": []},
  {"country": "Mauritius", "states": []},
  {"country": "Mexico", "states": [
      "Aguascalientes",
      "Baja California",
      "Baja California Sur",
      "Campeche",
      "Chiapas",
      "Chihuahua",
      "Coahuila",
      "Colima",
      "Durango",
      "Guanajuato",
      "Guerrero",
      "Hidalgo",
      "Jalisco",
      "Mexico State",
      "Michoacán",
      "Morelos",
      "Nayarit",
      "Nuevo León",
      "Oaxaca",
      "Puebla",
      "Querétaro",
      "Quintana Roo",
      "San Luis Potosí",
      "Sinaloa",
      "Sonora",
      "Tabasco",
      "Tamaulipas",
      "Tlaxcala",
      "Veracruz",
      "Yucatán",
      "Zacatecas"
    ]},
  {"country": "Micronesia", "states": [
      "Chuuk",
      "Kosrae",
      "Pohnpei",
      "Yap"
    ]},
  {"country": "Moldova", "states": []},
  {"country": "Monaco", "states": []},
  {"country": "Mongolia", "states": []},
  {"country": "Montenegro", "states": []},
  {"country": "Morocco", "states": []},
  {"country": "Mozambique", "states": []},
  {"country": "Myanmar (formerly Burma)", "states": [
      "Chin",
      "Kachin",
      "Kayah",
      "Kayin",
      "Mon",
      "Rakhine",
      "Shan"
    ]},
  {"country": "Namibia", "states": []},
  {"country": "Nauru", "states": []},
  {"country": "Nepal", "states": []},
  {"country": "Netherlands", "states": []},
  {"country": "New Zealand", "states": []},
  {"country": "Nicaragua", "states": []},
  {"country": "Niger", "states": [
      "Abia",
      "Adamawa",
      "Akwa Ibom",
      "Anambra",
      "Bauchi",
      "Bayelsa",
      "Benue",
      "Borno",
      "Cross River",
      "Delta",
      "Ebonyi",
      "Edo",
      "Ekiti",
      "Enugu",
      "Gombe",
      "Imo",
      "Jigawa",
      "Kaduna",
      "Kano",
      "Katsina",
      "Kebbi",
      "Kogi",
      "Kwara",
      "Lagos",
      "Nasarawa",
      "Niger",
      "Ogun",
      "Ondo",
      "Osun",
      "Oyo",
      "Plateau",
      "Rivers",
      "Sokoto",
      "Taraba",
      "Yobe",
      "Zamfara"
    ]},
  {"country": "Nigeria", "states": []},
  {"country": "North Korea", "states": []},
  {"country": "North Macedonia", "states": []},
  {"country": "Norway", "states": []},
  {"country": "Oman", "states": []},
  {"country": "Pakistan", "states": []},
  {"country": "Palau", "states": [
      "Aimeliik",
      "Airai",
      "Angaur",
      "Hatohobei",
      "Kayangel",
      "Koror",
      "Melekeok",
      "Ngaraard",
      "Ngarchelong",
      "Ngardmau",
      "Ngatpang",
      "Ngchesar",
      "Ngeremlengui",
      "Ngiwal",
      "Peleliu",
      "Sonsorol"
    ]},
  {"country": "Palestine State", "states": []},
  {"country": "Panama", "states": []},
  {"country": "Papua New Guinea", "states": []},
  {"country": "Paraguay", "states": []},
  {"country": "Peru", "states": []},
  {"country": "Philippines", "states": []},
  {"country": "Poland", "states": []},
  {"country": "Portugal", "states": []},
  {"country": "Qatar", "states": []},
  {"country": "Romania", "states": []},
  {"country": "Russia", "states": []},
  {"country": "Rwanda", "states": []},
  {"country": "Saint Kitts and Nevis", "states": []},
  {"country": "Saint Lucia", "states": []},
  {"country": "Saint Vincent and the Grenadines", "states": []},
  {"country": "Samoa", "states": []},
  {"country": "San Marino", "states": []},
  {"country": "Sao Tome and Principe", "states": []},
  {"country": "Saudi Arabia", "states": []},
  {"country": "Senegal", "states": []},
  {"country": "Serbia", "states": []},
  {"country": "Seychelles", "states": []},
  {"country": "Sierra Leone", "states": []},
  {"country": "Singapore", "states": []},
  {"country": "Slovakia", "states": []},
  {"country": "Slovenia", "states": []},
  {"country": "Solomon Islands", "states": []},
  {"country": "Somalia", "states": []},
  {"country": "South Africa", "states": []},
  {"country": "South Korea", "states": []},
  {"country": "South Sudan", "states": [
      "Central Equatoria",
      "Eastern Equatoria",
      "Jonglei",
      "Lakes",
      "Northern Bahr el Ghazal",
      "Unity",
      "Upper Nile",
      "Warrap",
      "Western Bahr el Ghazal",
      "Western Equatoria"
    ]},
  {"country": "Spain", "states": []},
  {"country": "Sri Lanka", "states": []},
  {"country": "Sudan", "states": []},
  {"country": "Suriname", "states": []},
  {"country": "Sweden", "states": []},
  {"country": "Switzerland", "states": []},
  {"country": "Syria", "states": []},
  {"country": "Tajikistan", "states": []},
  {"country": "Tanzania", "states": []},
  {"country": "Thailand", "states": []},
  {"country": "Timor-Leste", "states": []},
  {"country": "Togo", "states": []},
  {"country": "Tonga", "states": []},
  {"country": "Trinidad and Tobago", "states": []},
  {"country": "Tunisia", "states": []},
  {"country": "Turkey", "states": []},
  {"country": "Turkmenistan", "states": []},
  {"country": "Tuvalu", "states": []},
  {"country": "Uganda", "states": []},
  {"country": "Ukraine", "states": []},
  {"country": "United Arab Emirates", "states": []},
  {"country": "United Kingdom", "states": []},
  {"country": "Uruguay", "states": []},
  {"country": "Uzbekistan", "states": []},
  {"country": "Vanuatu", "states": []},
  {"country": "Vatican", "states": []},
  {"country": "Venezuela", "states": []},
  {"country": "Vietnam", "states": []},
  {"country": "Yemen", "states": []},
  {"country": "Zambia", "states": []},
  {"country": "Zimbabwe", "states": []}
]

export default COUNTRIES;
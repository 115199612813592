import Visa from 'assets/images/card_visa.svg';
import Mastercard from 'assets/images/card_mastercard.svg';
import JCB from 'assets/images/card_jcb.svg';
import AMEX from 'assets/images/card_amex.svg';
import moment from 'moment/moment';
import React from 'react';

export const parseJSON = (jsonString: string) => {
  try {
    var o = JSON.parse(jsonString);

    if (o) {
      return o;
    }
  } catch (e) {
    if (!!jsonString) {
      return jsonString;
    }
  }
};

export const getCardTypeLogo = (cardType: string) => {
  switch (cardType) {
    case 'visa':
      return Visa;

    case 'mastercard':
      return Mastercard;

    case 'amex':
      return AMEX;

    case 'discover':
      return 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/Discover_Card_logo.svg/1200px-Discover_Card_logo.svg.png';

    case 'diners':
      return 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/1b/Diners_Club_Logo.svg/1200px-Diners_Club_Logo.svg.png';

    case 'jcb':
      return JCB;

    default:
      return;
  }
};

export const decodeEscapedHTML = (str: string) => {
  if (!str) {
    return '';
  }
  const tags: any = {
    '&amp;': '&',
    '&lt;': '<',
    '&gt;': '>',
    '&#39;': "'",
    '&quot;': '"',
    '&#x3d;': '=',
    '&#x2f;': '/',
    '&#x3a;': ':',
    '&#x3f;': '?',
    '&#x28;': '(',
    '&#x29;': ')',
    '&#x27;': "'",
    '&#x3b;': ';',
  };

  return str.replace(/&.*?;/g, (tag: string) => tags[tag]);
};

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const startCase = (str: string) => {
  if(!str || typeof str == 'undefined') return '';
  if (typeof str !== 'string') {
    throw new TypeError('Input should be a string.');
  }

  return str
    .replace(/([A-Z])/g, ' $1') // Add space before capital letters
    .replace(/([0-9])/g, ' $1') // Add space before numbers
    .replace(/(_|-)/g, ' ') // Replace underscores and hyphens with spaces
    .replace(/\b\w/g, (match) => match.toUpperCase()); // Capitalize the first letter of each word
};

export const numberWithCommas = (x: any) => {
  if (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return x;
};

export const sortData = (data: any, column: string, column2?: string) => {
  if (!Array.isArray(data) || !data.length) return [];
  return data?.sort((a: any, b: any) => {
    const columnA = column2 ? a[column] || a[column2] : a[column];
    const columnB = column2 ? b[column] || b[column2] : b[column];

    if (columnA < columnB) {
      return -1;
    }
    if (columnA > columnB) {
      return 1;
    }
    return 0;
  });
};

export const checkIfFirstTimeSeen = (journeys: any[], timeOffsetMinutes: number = 30): boolean => {
  const sortedJourney = sortData(journeys, 'dateTime', 'date');
  const firstJourney = sortedJourney[0];
  const firstJourneyDateTime = moment(firstJourney.dateTime || firstJourney.date).add(timeOffsetMinutes, 'minutes').utc().toISOString();
  let firstTimeSeen = true;

  for (const journey of sortedJourney) {
    if (firstJourney.url !== journey.url) {
      firstTimeSeen = false;
      break;
    } else if((journey.dateTime || journey.date) > firstJourneyDateTime && firstJourney.url == journey.url){
      firstTimeSeen = false;
      break;
    }
  }

  return firstTimeSeen;
}

export const isEqualIgnoreCase = (value1: string | number, value2: string | number): boolean => {
  if (typeof value1 === 'string' && typeof value2 === 'string') {
    return value1.toLowerCase() === value2.toLowerCase();
  }

  return value1 === value2;
};

export const isIncludeIgnoreCase = (value1: string | number, value2: string | number): boolean => {
  if (typeof value1 === 'string' && typeof value2 === 'string') {
    return value1.toLowerCase().includes(value2.toLowerCase());
  }

  return value1 === value2;
};

export const isNumberKey = (event: React.KeyboardEvent<HTMLInputElement>) => {
  // Get the key code
  const keyCode = event.keyCode || event.which;

  // Allow numbers (48-57), decimal point (46), and special keys like Backspace, Delete, Tab, and arrows
  if (
    (
      (keyCode < 48 || keyCode > 57) && // numbers (main keyboard)
      (keyCode < 96 || keyCode > 105) // numbers (numpad)
    ) &&
    keyCode !== 46 && // decimal point
    keyCode !== 8 && // Backspace
    keyCode !== 9 && // Tab
    keyCode !== 37 && // Left arrow
    keyCode !== 39 && // Right arrow
    keyCode !== 46 && // Delete
    keyCode !== 190 && // Period (for some keyboards)
    keyCode !== 110 // Numpad decimal point
  ) {
    // Prevent the default action
    event.preventDefault();
  }
}

import AffiliateForm from 'app/components/Authentication/Affiliate';
import AuthenticationLayout from 'app/components/Authentication/AuthenticationLayout';

import VitalEdgeLogo from 'assets/images/logo_dark.png';

const Affiliate = () => {

  return (
    <AuthenticationLayout>
      <div>
        <img src={VitalEdgeLogo} alt="Vital Edge" />
        <AffiliateForm />
      </div>
    </AuthenticationLayout>
  )
}

export default Affiliate
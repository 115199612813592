import { ENDPOINTS } from '../constants/ENDPOINTS';
import { useAxios } from '../hooks/useAxios';
import { IDefaultResponsePayload } from '../types/auth.types';
import {
  IIntegrateViewRequestPayload,
  IIntegrateViewResponsePayload,
  ICreatePixelRequestPayload,
  ICreatePixelResponsePayload,
  ICreateViewRequestPayload,
  ICreateViewResponsePayload,
  IDeleteViewRequestPayload,
  IDeleteViewResponsePayload,
  IDetachViewRequestPayload,
  IDetachViewResponsePayload,
  IEnableDisableDeleteRequestPayload,
  IEnableDisableResponsePayload,
  IGetByDomainRequestPayload,
  IGetViewResponsePayload,
  IListPixelsRequestPayload,
  IListPixelsResponsePayload,
  IWidgetRequestPayload,
  IWidgetResponsePayload,
  IChatGptRequestPayload,
  ICreditMatchesRequestPayload,
  IGenerateAPIKeyRequestPayload,
  IGenerateAPIKeyResponsePayload,
} from '../types/pixel.types';

const { GET, POST, PUT, DELETE } = useAxios();

export const pixelDao = () => {
  const pixels = async (payload: IListPixelsRequestPayload) => {
    if (!payload?.organizationid || !payload?.workspaceid) return Promise.resolve({ data: [] });
    const response = await GET<IListPixelsResponsePayload>({
      url: `${ENDPOINTS.PIXEL_SERVICE}/pixels?workspaceid=${encodeURIComponent(
        payload.workspaceid,
      )}&organizationid=${encodeURIComponent(payload.organizationid)}`,
    } as any);

    return response.data;
  };

  const getWorkspaceNewMatches = async (payload: any) => {
    const response = await GET<any>({
      url: `${ENDPOINTS.PIXEL_SERVICE}/workspaceNewMatches`,
      params: payload,
    } as any);

    return response.data;
  };

  const getTotalMatchesByDateRange = async (payload: any) => {
    const response = await GET<any>({
      url: `${ENDPOINTS.PIXEL_SERVICE}/totalMatchesByDateRange`,
      params: payload,
    } as any);

    return response.data;
  };

  const getPixelNewAndPriorMatches = async (payload: any) => {
    const response = await GET<any>({
      url: `${ENDPOINTS.PIXEL_SERVICE}/pixelNewAndPriorMatches`,
      params: payload,
    } as any);

    return response.data;
  };

  const getByDomain = async (payload: IGetByDomainRequestPayload) => {
    const response = await GET<ICreatePixelResponsePayload>({
      url: `${ENDPOINTS.PIXEL_SERVICE}/getByDomain`,
      params: payload,
    } as any);

    return response.data;
  };

  const createPixel = async (payload: ICreatePixelRequestPayload) => {
    const response = await POST<ICreatePixelResponsePayload>({
      url: `${ENDPOINTS.PIXEL_SERVICE}/pixel`,
      data: payload,
    } as any);

    return response.data;
  };

  const enableDisable = async (payload: IEnableDisableDeleteRequestPayload) => {
    const response = await POST<IEnableDisableResponsePayload>({
      url: `${ENDPOINTS.PIXEL_SERVICE}/enable_or_disable`,
      data: payload,
    } as any);

    return response.data;
  };

  const deletePixel = async (payload: IEnableDisableDeleteRequestPayload) => {
    const response = await DELETE<IDefaultResponsePayload>({
      url: `${ENDPOINTS.PIXEL_SERVICE}/pixels`,
      data: payload,
    } as any);

    return response.data;
  };

  const getWidgets = async (payload: IWidgetRequestPayload) => {
    const response = await GET<IWidgetResponsePayload>({
      url: `${ENDPOINTS.PIXEL_SERVICE}/widgets`,
      params: payload,
    } as any);

    return response.data;
  };

  const getViews = async (payload: any) => {
    const response = await GET<IGetViewResponsePayload>({
      url: `${ENDPOINTS.PIXEL_SERVICE}/views?email=${encodeURIComponent(payload.email)}&pixelid=${encodeURIComponent(payload.pixelid)}`,
    } as any);

    return response.data;
  };

  const updateView = async (payload: ICreateViewRequestPayload) => {
    const response = await PUT<ICreateViewResponsePayload>({
      url: `${ENDPOINTS.PIXEL_SERVICE}/view`,
      data: payload,
    } as any);

    return response.data;
  };

  const createView = async (payload: ICreateViewRequestPayload) => {
    const response = await POST<ICreateViewResponsePayload>({
      url: `${ENDPOINTS.PIXEL_SERVICE}/view`,
      data: payload,
    } as any);

    return response.data;
  };

  const deleteView = async (payload: IDeleteViewRequestPayload) => {
    const response = await DELETE<IDeleteViewResponsePayload>({
      url: `${ENDPOINTS.PIXEL_SERVICE}/view`,
      data: payload,
    } as any);

    return response.data;
  };

  const integrateView = async (payload: IIntegrateViewRequestPayload) => {
    const response = await POST<IIntegrateViewResponsePayload>({
      url: `${ENDPOINTS.PIXEL_SERVICE}/integrate-view`,
      data: payload,
    } as any);

    return response.data;
  };

  const detachView = async (payload: IDetachViewRequestPayload) => {
    const response = await DELETE<IDetachViewResponsePayload>({
      url: `${ENDPOINTS.PIXEL_SERVICE}/disintegrate-view`,
      data: payload,
    } as any);

    return response.data;
  };

  const creditMatches = async (payload: ICreditMatchesRequestPayload) => {
    const response = await POST<ICreditMatchesRequestPayload>({
      url: `${ENDPOINTS.PIXEL_SERVICE}/credit-matches`,
      data: payload,
    } as any);

    return response.data;
  }

  const triggerMatches = async (payload: any) => {
    const response = await POST<any>({
      url: `${ENDPOINTS.PIXEL_SERVICE}/trigger-matches`,
      data: payload,
    } as any);

    return response.data;
  }

  const chatgpt = async (payload: IChatGptRequestPayload) => {
    const response = await POST<IChatGptRequestPayload>({
      url: `${ENDPOINTS.PIXEL_SERVICE}/chatgpt`,
      data: payload,
    } as any);

    return response.data;
  }

  const generateApi = async (payload: IGenerateAPIKeyRequestPayload) => {
    const response = await POST<IGenerateAPIKeyResponsePayload>({
      url: `${ENDPOINTS.PIXEL_SERVICE}/apikey`,
      data: payload,
    } as any);

    return response.data;
  }

  return {
    pixels,
    getByDomain,
    createPixel,
    enableDisable,
    deletePixel,
    getWidgets,
    getViews,
    createView,
    updateView,
    deleteView,
    integrateView,
    detachView,
    getPixelNewAndPriorMatches,
    getWorkspaceNewMatches,
    getTotalMatchesByDateRange,
    creditMatches,
    triggerMatches,
    chatgpt,
    generateApi
  };
};

import { useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material'
import { enqueueSnackbar } from 'notistack';

import CustomTextField from 'app/shared/components/TextField/TextField';
import Button from 'app/shared/components/Button/Button';

import { forgotPasswordValidationSchema } from 'app/pages/Authentication/utils';
import CheckYourInbox from '../CheckYourInbox/CheckYourInbox';

import VitalEdgeLogo from 'assets/images/logo_dark.png';
import { authDao } from 'app/shared/dao/authDao';
import { ConditionalProgress } from 'app/shared/components/ConditionalCircular/ConditionalCircular';
import { COLORS } from 'app/shared/constants/COLORS';
import { ROUTES } from 'app/shared/constants/ROUTES';
import { capitalizeFirstLetter } from 'app/shared/utils/helpers';

const { forgotPassword } = authDao();

const ForgotPassword = () => {
    const navigate = useNavigate();
    const [success, setSuccess] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [showResend, setShowResend] = useState(true);
    const [resending, setResending] = useState(false);

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: forgotPasswordValidationSchema,
        onSubmit: async (values) => {
            setSubmitting(true);
            const res = await forgotPassword({
                email: values?.email?.trim()?.toLowerCase() || '',
            });

            if(res?.status === 'success') {
                setSuccess(true);
            } else {
                enqueueSnackbar(capitalizeFirstLetter(res?.message || res?.error || ''), { variant: 'error' });
            }

            setSubmitting(false);
        },
    });

    const handleBackSignIn = () => {
        navigate(ROUTES.ROOT);
    }

    const handleResend = async () => {
        setResending(true);
        const res = await forgotPassword({
            email: formik?.values?.email?.toLowerCase() || '',
        });

        if(res?.status === 'success') {
            enqueueSnackbar('Successfully resent password reset link', { variant: 'success' });
            setShowResend(false);
        } else {
            enqueueSnackbar(capitalizeFirstLetter(res?.message || ''), { variant: 'error' });
        }
        setResending(false);
    }

    if(success) {
        return <CheckYourInbox withTimer={showResend} timer={60} handleResend={handleResend} isLoading={resending} />
    }

    return (
        <>
            <img src={VitalEdgeLogo} alt="Vital Edge" />
            <div className='title'>Forgot Password</div>
            <div className='supporting'>Enter your email address to reset password</div>
            <form onSubmit={formik.handleSubmit} className="forgot-password-form">
                <CustomTextField
                    fullWidth
                    id="email"
                    name="email"
                    placeholder="Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    variant="standard"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    className="mb-4 custom-login-input"
                />
                <Grid xs={12}>
                    <Button 
                        type="submit"
                        variant="contained" 
                        size="large"
                        fullWidth
                        className="submit-button"
                        disabled={submitting || !formik.dirty}
                    >
                        <ConditionalProgress isLoading={submitting} color={COLORS.PRIMARY}>
                            Reset Password
                        </ConditionalProgress>
                    </Button>
                </Grid>
                <Grid container xs={12} justifyContent="center" alignItems="center" className="signup-btn-wrapper"> 
                    <span className="signup-btn-text" onClick={handleBackSignIn}>Back to sign in</span>
                </Grid>
            </form>
        </>
    )
};

export default ForgotPassword;

import React, { useEffect, useRef, useState } from 'react';
import { ChildrenCont, MainCont, RightContainer } from './Layout.styles';
import Sidebar from '../Sidebar/Sidebar';
import Header from '../Header/Header';
import PromptModal from 'app/shared/modals/PromptModal';
import { useCheckAuthenticity } from 'app/shared/hooks/useCheckAuthenticity';
import { CustomModal } from 'app/shared/modals/CustomModal';
import { useLocation } from 'react-router-dom';
import { ls } from 'app/shared/utils/ls';
import { ROUTES } from 'app/shared/constants/ROUTES';
import Banner from '../Header/Banner/Banner';

interface Props {}
const Layout: React.FC<React.PropsWithChildren<Props>> = ({ children }) => {
  const { innerWidth } = window;
  const { getLS } = ls();

  const { signOut } = useCheckAuthenticity();
  const [showExpire, setshowExpire] = useState(false);
  const location = useLocation();
  const user = JSON.parse(getLS('user'))
  const isAdmin = getLS('isAdmin')
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(innerWidth < 900);
  const isSidebarCollapsedRef = useRef(isSidebarCollapsed);

  const handleWindowResize = () => {
    const { innerWidth } = window;
    setIsSidebarCollapsed(innerWidth < 900);
  };

  useEffect(() => {
    isSidebarCollapsedRef.current = isSidebarCollapsed;
  }, [isSidebarCollapsed]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
  }, []);

  useEffect(() => {
    const handleStorageEvent = () => {
      const isSessionExpired = localStorage.getItem('isSessionExpired');
      setshowExpire(isSessionExpired === 'true');
    };
    window.addEventListener('storage', handleStorageEvent);

    return () => window.removeEventListener('storage', handleStorageEvent);
  }, []);

  const logout = () => {
    signOut();
  };
  return (
    <MainCont>
      {!isSidebarCollapsed ? <Sidebar isAdmin={isAdmin}/> : null}
      <RightContainer>
        <Header
          setIsSidebarCollapsed={() => {
            setIsSidebarCollapsed(!isSidebarCollapsed);
          }}
        />
        {isAdmin ? <div style={{
          display: 'block',
          textAlign: 'center',
          verticalAlign: 'center',
          backgroundColor: '#FBF7F9',
          color: '#310E28',
          fontSize: 18,
          fontWeight: 500,
          height: 30,
          lineHeight: '24px',
        }}>Logged in as {user.firstName} {user.lastName}</div> : null
        }
        {location?.pathname === ROUTES.DASHBOARD ? <Banner /> : ''}
        <ChildrenCont>{children}</ChildrenCont>
      </RightContainer>
      {showExpire ? (
        <CustomModal open={showExpire} onClose={() => setshowExpire(false)} dismissible={false}>
          <PromptModal
            titleSize={25}
            type="error"
            title={'Your session has expired'}
            message={'You will be redirected to login page'}
            successLabel={'OK'}
            successAction={logout}
          ></PromptModal>
        </CustomModal>
      ) : (
        <React.Fragment />
      )}
    </MainCont>
  );
};

export default Layout;

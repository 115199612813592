import React from 'react';
import { createContext, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { IDefaultValue } from '../../types/auth.types';
import { parseJSON } from '../utils/helpers';
import { authDao } from '../dao/authDao';
import { ISignInRequestPayload } from '../types/auth.types';
import { Navigate, useLocation } from 'react-router-dom';
import { ROUTES } from '../constants/ROUTES';

const AuthContext = createContext({
  isAuthenticated: false,
  setIsAuthenticated: () => {},
  signIn: (body: ISignInRequestPayload) => {},
  signOut: () => {},
  authenticating: false,
  handleSignInSuccess: (res: any, page?: string) => {},
  setAuthenticating: () => {},
} as IDefaultValue);

const AuthConsumer = AuthContext.Consumer;

const { login } = authDao();
const Auth = (props: any) => {
  const auth = localStorage.token ? !!parseJSON(localStorage.token) : false;

  const [isAuthenticated, setIsAuthenticated] = useState(auth);
  const [authenticating, setAuthenticating] = useState(false);
  const url = window?.location?.pathname;

  async function signIn(body: ISignInRequestPayload) {
    setAuthenticating(true);
    const res = await login(body);
    if (res?.status === 'success') {
      handleSignInSuccess(res);
    } else {
      setAuthenticating(false);
      return {
        status: 'error',
        message: res?.error?.includes('Kindly message your administrator.') ? res?.error : 'Incorrect email or password',
        data: res.data
      };
    }
  }

  function handleSignInSuccess(res: any, page?: string) {
    const user = JSON.stringify({ ...res?.data, orgName: res?.userAccess?.name });
    localStorage.setItem('token', JSON.stringify(res?.token));
    localStorage.setItem('user', user);
    localStorage.setItem('userAccess', JSON.stringify(res?.userAccess));
    localStorage.setItem('workspaces', JSON.stringify(res?.workspaces));
    localStorage.setItem('currentWorkspaceId', res?.workspaces?.[0]?.id);
    localStorage.setItem('currentWorkspace', JSON.stringify(res?.workspaces?.[0]));

    setIsAuthenticated(true);
    setAuthenticating(false);

    if(page){
      window.location.href = `/${page}`
    } else if (url?.includes(ROUTES.MEMBER_SIGNUP) || url?.includes(ROUTES.ACTIVATION) || url?.includes(ROUTES.SIGN_IN_AS_OWNER_BY_ADMIN)) {
      window.location.href = '/dashboard';
    }
  }

  function signOut(redirectToLogin = true) {
    localStorage.clear();
    setIsAuthenticated(false);
    if(redirectToLogin) {
      window.location.href = '/';
    }
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        signIn,
        signOut,
        authenticating,
        handleSignInSuccess,
        setAuthenticating,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

const AuthProvider = Auth;
export { AuthContext, AuthProvider, AuthConsumer };

import { useMutation, useQuery } from '@tanstack/react-query';
import { workspaceDao } from 'app/shared/dao/workspaceDao';
import { IWhiteLabelRequestPayload, IWorkspacePayload } from '../types/workspace.types';

const {
  retrieve,
  retrieveAllWorkspaces,
  updateRequirePaymentMethod: updateRequirePaymentMethodDao,
  updateWhiteLabel: updateWhiteLabelDao,
  verifyDNS: verifyDNSDao,
} = workspaceDao();

export const workspaceServices = () => {
  const getWorkspace = (payload: { workspaceid: string }) => {
    return useQuery(['retrieveWorkspace', { id: payload.workspaceid }], () => retrieve(payload.workspaceid));
  };

  const getAllWorkspaces = () => {
    return useQuery([], () => retrieveAllWorkspaces());
  };

  const verifyDNS = ({domainName} : {domainName: string}) => {
    return useQuery([], () => verifyDNSDao({domainName}));
  };

  const updateRequirePaymentMethod = () => {
    return useMutation((payload: IWorkspacePayload) => updateRequirePaymentMethodDao(payload));
  };

  const updateWhiteLabel = () => {
    return useMutation((payload: IWhiteLabelRequestPayload) => updateWhiteLabelDao(payload));
  };

  return {
    getWorkspace,
    getAllWorkspaces,
    updateRequirePaymentMethod,
    updateWhiteLabel,
    verifyDNS,
  };
};

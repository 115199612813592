import React from 'react'

import LoginForm from 'app/components/Authentication/Login';
import AuthenticationLayout from 'app/components/Authentication/AuthenticationLayout';

import VitalEdgeLogo from 'assets/images/logo_dark.png';

const Login = () => {

  return (
    <AuthenticationLayout>
      <div>
        <img src={VitalEdgeLogo} alt="Vital Edge" />
        <div className='supporting'>Good to see you again!</div>
        <div className='title'>Sign in to Your Account</div>
        <LoginForm />
      </div>
    </AuthenticationLayout>
  )
}

export default Login
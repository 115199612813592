import React from 'react'
import { CircularProgress } from '@mui/material'

import { CircularProgressWrapper, ProgressWrapper, ProgressLabel } from './CircularProgressWithLabel.styles'
import { COLORS } from 'app/shared/constants/COLORS'

interface IProps {
    size?: number
    label?: string | number
}

const CircularProgressWithLabel = ({
    size = 30,
    label
}: IProps) => {
    return (
        <>
            <svg width={size + 10} height={size + 10} style={{ position: "absolute", left: 0 }}>
                <linearGradient id="linearColors" x1="0" y1="0" x2="1" y2="1">
                    <stop offset="20%" stopColor={COLORS.PRIMARY} />
                    <stop offset="100%" stopColor="rgba(196, 196, 196, 0)" />
                </linearGradient>
            </svg>
            <ProgressWrapper>
                <CircularProgress variant="indeterminate" size={size || 30}/>
                <CircularProgressWrapper>
                    <ProgressLabel variant="caption" color="text.secondary">
                        {label}
                    </ProgressLabel>
                </CircularProgressWrapper>
            </ProgressWrapper>
        </>
    )
}

export default CircularProgressWithLabel
import React, { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import VitalEdgeLoader from '../Loader/VitalEdgeLoader';
import { workspaceDao } from '../../dao/workspaceDao';
import ThankYou from './ThankYou';

const { unsubscribeEmail } = workspaceDao();

const UnsubscribeEmail: React.FC = () => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const queryParams = location.search;

    if (queryParams) {
      const workspaceid = searchParams.get('workspaceid') || '';
      (async () => {
        const res = await unsubscribeEmail({ workspaceid });
        if(res.status === 'success'){
          setIsLoading(false)
        }
      })();
    } else {
      window.location.href = '/'
    }
  }, []);

  return isLoading ? <VitalEdgeLoader isOpen={true} /> : <ThankYou message={'Unsubscription successful. You will no longer receive emails from us.'}/>;
};

export default UnsubscribeEmail;

import { useMutation, useQuery } from '@tanstack/react-query';
import { pixelDao } from 'app/shared/dao/pixelDao';
import { contactDao } from 'app/shared/dao/contactDao';
import {
  ICreatePixelRequestPayload,
  IListPixelsRequestPayload,
  IEnableDisableDeleteRequestPayload,
  ICreateViewRequestPayload,
  IDeleteViewRequestPayload,
  IDetachViewRequestPayload,
  IIntegrateViewRequestPayload, IChatGptRequestPayload, ICreditMatchesRequestPayload, IGenerateAPIKeyRequestPayload,
} from '../types/pixel.types';

const {
  getWidgets: getWidgetsDao,
  createPixel: createPixelDao,
  pixels: getPixelsDao,
  deletePixel: deletePixelDao,
  enableDisable: enableDisableDao,
  getViews: getViewsDao,
  createView: createViewDao,
  updateView: updateViewDao,
  deleteView: deleteViewDao,
  integrateView: integrateViewDao,
  detachView: detachViewDao,
  getPixelNewAndPriorMatches: getPixelNewAndPriorMatchesDao,
  getWorkspaceNewMatches: getWorkspaceNewMatchesDao,
  getTotalMatchesByDateRange: getTotalMatchesByDateRangeDao,
  creditMatches: creditMatchesDao,
  triggerMatches: triggerMatchesDao,
  chatgpt: chatgptDao,
  generateApi: generateApiDao,
} = pixelDao();
const { listCaptures, visitorsUrl } = contactDao();

export const pixelServices = () => {
  const listPixels = (payload: IListPixelsRequestPayload) => {
    return useQuery(['pixels', payload], () => getPixelsDao(payload));
  };

  const getTotalMatchesByDateRange = (payload: any) => {
    return useQuery(['getTotalMatchesByDateRange', payload.pixelid], () => getTotalMatchesByDateRangeDao(payload));
  };

  const getWorkspaceNewMatches = (payload: any) => {
    return useQuery(['getPixelNewMatches', payload], () => getWorkspaceNewMatchesDao(payload));
  };

  const getPixelNewAndPriorMatches = (payload: any) => {
    return useQuery(['getPixelNewAndPriorMatches', payload], () => getPixelNewAndPriorMatchesDao(payload));
  };

  const createPixel = () => {
    return useMutation((payload: ICreatePixelRequestPayload) => createPixelDao(payload));
  };

  const getViews = (payload: any) => {
    return useQuery(['views'], () => getViewsDao(payload));
  };

  const createView = () => {
    return useMutation((payload: ICreateViewRequestPayload) => createViewDao(payload));
  };

  const updateView = () => {
    return useMutation((payload: ICreateViewRequestPayload) => updateViewDao(payload));
  };

  const deleteView = () => {
    return useMutation((payload: IDeleteViewRequestPayload) => deleteViewDao(payload));
  };

  const deletePixel = () => {
    return useMutation((payload: IEnableDisableDeleteRequestPayload) => deletePixelDao(payload));
  };

  const enableDisablePixel = () => {
    return useMutation((payload: IEnableDisableDeleteRequestPayload) => enableDisableDao(payload));
  };

  const getWidgets = (payload: any) => {
    return useQuery(['widgets', payload], () => getWidgetsDao(payload));
  };

  const listCapturesByPixelId = (pixelid: string, workspaceid: any, enabled = true) => {
    return useQuery({
      queryKey: ['contactCaptures', pixelid, workspaceid],
      queryFn: () => listCaptures(pixelid, workspaceid),
      enabled,
    });
  };

  const visitorsUrlByPixelId = (pixelid: string, workspaceid: any, enabled = true) => {
    return useQuery({
      queryKey: ['visitorsUrl', pixelid, workspaceid],
      queryFn: () => visitorsUrl(pixelid, workspaceid),
      enabled,
    });
  };

  const integrateView = () => {
    return useMutation((payload: IIntegrateViewRequestPayload) => integrateViewDao(payload));
  };

  const detachView = () => {
    return useMutation((payload: IDetachViewRequestPayload) => detachViewDao(payload));
  };

  const creditMatches = () => {
    return useMutation((payload: ICreditMatchesRequestPayload) => creditMatchesDao(payload));
  }

  const triggerMatches = () => {
    return useMutation((payload: any) => triggerMatchesDao(payload));
  }

  const chatgpt = () => {
    return useMutation((payload: IChatGptRequestPayload) => chatgptDao(payload));
  }

  const generateApi = () => {
    return useMutation((payload: IGenerateAPIKeyRequestPayload) => generateApiDao(payload));
  }

  return {
    createPixel,
    deletePixel,
    enableDisablePixel,
    getWidgets,
    listPixels,
    listCapturesByPixelId,
    visitorsUrlByPixelId,
    getViews,
    createView,
    updateView,
    deleteView,
    integrateView,
    detachView,
    getPixelNewAndPriorMatches,
    getWorkspaceNewMatches,
    getTotalMatchesByDateRange,
    creditMatches,
    triggerMatches,
    chatgpt,
    generateApi,
  };
};

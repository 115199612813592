import { useEffect, useState } from 'react';

import { ls } from '../utils/ls';
import { isJsonString } from '../utils/common';
import { IWorkspace } from '../types/user.types';

export const useChangeWorkspace = () => {
  const { getLS, setLS } = ls();
  const [currentWorkspaceId, setCurrentWorkspaceId] = useState(getLS('currentWorkspaceId'));
  const [currentWorkspace, setCurrentWorkspace] = useState(
    isJsonString(getLS('currentWorkspace')) ? JSON.parse(getLS('currentWorkspace')) : {}
  );
  const [workspacesList, setWorkspacesList] = useState(
    isJsonString(getLS('workspaces')) ? JSON.parse(getLS('workspaces')) : [],
  );
  const [currentOrganizationId, setCurrentOrganizationId] = useState(
    isJsonString(getLS('user')) ? JSON.parse(getLS('user'))?.organizationid : '',
  );

  useEffect(() => {
    const handleStorageEvent = () => {
      //Redeclare to get the latest value
      const workspaceid = getLS('currentWorkspaceId');
      const workspaces = isJsonString(getLS('workspaces')) ? JSON.parse(getLS('workspaces')) : [];
      const organizationid = isJsonString(getLS('user')) ? JSON.parse(getLS('user'))?.organizationid : '';
      const currentWorkspaceLS = isJsonString(getLS('currentWorkspace')) ? JSON.parse(getLS('currentWorkspace')) : {};
      setCurrentWorkspaceId(workspaceid);
      setWorkspacesList(workspaces);
      setCurrentOrganizationId(organizationid);
      setCurrentWorkspace(currentWorkspaceLS);
    };
    window.addEventListener('storage', handleStorageEvent);

    return () => window.removeEventListener('storage', handleStorageEvent);
  }, []);

  function updateCurrentWorkspace({workspace} : any) {
    let newWorkspaceList = [...workspacesList];
    newWorkspaceList = newWorkspaceList.map(w => w.id === currentWorkspaceId ? { ...w, ...workspace } : w);
    setLS('currentWorkspace', JSON.stringify(workspace))
    setLS('workspaces', JSON.stringify(newWorkspaceList))
    setCurrentWorkspace(workspace)
    setWorkspacesList(newWorkspaceList)
  }

  return { currentWorkspaceId, workspacesList, currentOrganizationId, currentWorkspace, setCurrentWorkspace, setWorkspacesList, updateCurrentWorkspace };
};

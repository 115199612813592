import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Button from 'app/shared/components/Button/Button';
import CircularProgressWithLabel from 'app/shared/components/CircularProgressWithLabel/CircularProgressWithLabel';

import { userDao } from 'app/shared/dao/userDao';
import VitalEdgeLogo from 'assets/images/logo_dark.png';
import CheckMark from 'assets/images/check_mark.svg';
import XMark from 'assets/images/x_mark.svg';
import { useCheckAuthenticity } from 'app/shared/hooks/useCheckAuthenticity';



const SignInAsOwnerByAdmin = () => {
    const navigate = useNavigate();
    let [searchParams] = useSearchParams();
    const { signInAsOwnerByAdmin } = userDao();
    const { handleSignInSuccess } = useCheckAuthenticity();

    const [loggingIn, setLoggingIn] = useState(false);
    const [invalidUser, setInvalidUser] = useState<any>(null);

    useEffect(() => {
        const loggedInUser = async () => {
            const username = atob(searchParams.get('username') || '');
            const ghostid = atob(searchParams?.get('ghostid') || '');
            const page = searchParams?.get('page') || '';
                setLoggingIn(true);
                setTimeout(async () => {
                    const res = await signInAsOwnerByAdmin({username, ghostid});
                    if(res?.status === 'success') {
                        localStorage.setItem('isAdmin', 'true');
                        handleSignInSuccess(res, page);
                    } else {
                        setLoggingIn(false);
                        setInvalidUser({
                            title: 'Login as owner failed',
                            message: 'Unexpected error, Cannot Login as this Owner',
                        })
                    }
                }, 1000);

        }

        loggedInUser();
    }, []);


    const handleBackSignIn = () => {
        navigate('/');
    }

    if(!loggingIn && !!invalidUser) {
        return (
            <>
                <img src={VitalEdgeLogo} alt="Vital Edge" />
                <div style={{textAlign: 'center'}}>
                    <div>
                        <img src={XMark} alt="Error" className='illustration' height={'80px'}/>
                    </div>
                    <div className='title'>
                        {invalidUser?.title}
                    </div>
                    <div className='supporting'>
                        {invalidUser?.message}
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            <img src={VitalEdgeLogo} alt="Vital Edge" />
            <div style={{textAlign: 'center'}}>
                <div>
                    {
                        !loggingIn ? (
                            <img src={CheckMark} alt="Success" className='illustration' style={{marginBottom: 0}} />
                        ) : (
                            <div style={{marginBottom: 32}}>
                                <CircularProgressWithLabel size={72} />
                            </div>
                        )
                    }
                </div>
                <div className='title'>
                    {
                        loggingIn ? 'Logging In' : 'Success!'
                    }
                </div>
                <div className='supporting'>
                    {
                        loggingIn ? 'Please wait...' : 'Your are now logged in'
                    }
                </div>
                {
                    !loggingIn ? (
                        <>
                            <div className="buttons-wrapper" style={{ marginTop: 32 }}>
                                <Button variant="contained" size="large" fullWidth className="back-to-sign-in-button" onClick={handleBackSignIn}>
                                    Sign in
                                </Button>
                            </div>
                        </>
                    ) : null
                }
            </div>
        </>
    )
};

export default SignInAsOwnerByAdmin;

import React from 'react'
import ThankYouView from '../../components/Authentication/ThankYouView/ThankYouView';

const ThankYou = () => {
  return (
    <ThankYouView handleShowThankYou={() => {
    }} />
  )
}

export default ThankYou

import { Box, Grid, styled } from '@mui/material';

export const PaymentDetailsModalCont = styled(Box)(({ theme }) => ({
  display: 'flex',
  // width: 468,
  minHeight: 450,
  borderRadius: 10,
  background: '#fff',
  padding: 30,
  position: 'relative',
  flexDirection: 'column',
  
  maxHeight: '99vh',
  overflowY: 'auto',
}));

export const FieldCont = styled(Box)(({ theme }) => ({
  marginTop: 35,
}));

export const ExpCont = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    marginTop: 20,
  }));

export const updateButton = {
  height: 56,
  marginTop: 'auto',
  fontSize: 18,
  textTransform: 'none',
//   marginBottom: '15px',
  borderRadius: '8px',
};

export const cvvField = {
  marginLeft: '20px',
};

export const applyButton = {
  minWidth: '100px',
  marginTop: '24px',
}

export const AmountCont = styled(Grid)(({ theme }) => ({
    marginBottom: 15,
    fontSize: 14,
}));
import { createTheme } from '@mui/material';
import { COLORS } from './COLORS';

const THEME = createTheme({
  palette: {
    primary: {
      main: COLORS.PRIMARY,
    },
    secondary: {
      main: COLORS.SECONDARY,
    },
    success: {
      main: COLORS.SUCCESS,
    },
    warning: {
      main: COLORS.WARNING,
    },
    error: {
      main: COLORS.ERROR,
    },
    info: {
      main: COLORS.TEXT_GRAY,
    },
  },
  typography: {
    fontFamily: `"Lato", "Roboto", "Helvetica", "Arial", sans-serif`,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
});
export default THEME;

import { Box, Button, styled } from '@mui/material';
import { COLORS } from 'app/shared/constants/COLORS';

export const SidebarButton = styled(Button)(() => ({
  color: COLORS.PRIMARY,
}));

export const HeaderContainer = styled(Box)(() => ({
  background: '#F8F9FF',
  flex: 1,
  display: 'flex',
  height: 66,
  borderBottom: `1px solid ${COLORS.LIGHT_GRAY}`,
}));

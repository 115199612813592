import { ButtonProps } from '@mui/material';
import { ReactNode } from 'react';
import { StyledButton } from './Button.styles';

interface IButtonProps {
  children?: ReactNode;
  primary?: boolean;
}

function Button({ children, ...rest }: IButtonProps & ButtonProps) {
  return (
    <StyledButton {...rest} style={{ borderRadius: 8 }}>
      {children}
    </StyledButton>
  );
}

export default Button;

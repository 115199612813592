import { CONFIG } from '../config';
import { ENDPOINTS } from '../constants/ENDPOINTS';
import { useAxios } from '../hooks/useAxios';
import {
  IAddPaymentMethodRequestPayload, ICancelSubscriptionRequestPayload,
  ICreateSubscriptionRequestPayload,
  IPaymentMethodAttachRequestPayload,
  IPaymentMethodAttachResponsePayload,
} from '../types/stripe.types';
import Stripe from 'stripe';
const { GET, POST } = useAxios();

const stripe = new Stripe(CONFIG.STRIPE_PK || '', { apiVersion: "2022-11-15" });

export const stripeDao = () => {
    const addPaymentMethods = async (payload: IAddPaymentMethodRequestPayload) => {
        try {
            const response = await stripe
                .paymentMethods
                .create({
                    type: 'card',
                    card: payload.card,
                    billing_details: payload.billing_details,
                });
            return response;
        } catch(e: any) {
            return e;
        }
        
    }

    const paymentMethodAttach = async (payload: IPaymentMethodAttachRequestPayload) => {
        try {
            const response = await POST<IPaymentMethodAttachResponsePayload>({
                url: `${ENDPOINTS.STRIPE_SERVICE}/paymentMethodAttach`,
                data: payload,
            } as any)
      
          return response?.data;
        } catch(e: any) {
          return e?.response?.data;
        }
    };

    const createSubscription = async (payload: ICreateSubscriptionRequestPayload) => {
        try {
            const response = await POST<any>({
                url: `${ENDPOINTS.STRIPE_SERVICE}/manageSubscription`,
                data: payload,
            } as any)

            return response?.data;
        } catch(e: any) {
            return e?.response?.data;
        }
    }

    const cancelSubscription = async (payload: ICancelSubscriptionRequestPayload) => {
        try {
            const response = await POST<any>({
                url: `${ENDPOINTS.STRIPE_SERVICE}/deletePreviousSubscription`,
                data: payload,
            } as any)

            return response?.data;
        } catch(e: any) {
            return e?.response?.data;
        }
    }

    const transferOwnership = async (payload: any) => {
        try {
            const response = await POST<any>({
                url: `${ENDPOINTS.STRIPE_SERVICE}/transferOwnership`,
                data: payload,
            } as any)

            return response?.data;
        } catch(e: any) {
            return e?.response?.data;
        }
    }

  return {
    addPaymentMethods,
    paymentMethodAttach,
    createSubscription,
    transferOwnership,
    cancelSubscription,
  };
};

import React, { useEffect, useMemo, useState } from 'react';
import { Avatar, MainCont } from './Workspaces.style';
import avatar from '../../../../../assets/images/avatar_fallback.jpg';
import { Box, ListItem, ListItemText, MenuItem, Typography } from '@mui/material';
import { ls } from 'app/shared/utils/ls';
import { IUser, IWorkspace } from 'app/shared/types/user.types';

import Select from '../../Select/Select';
import { useChangeWorkspace } from 'app/shared/hooks/useChangeWorkspace';
import { useQueryClient } from '@tanstack/react-query';
import GenericModal from 'app/shared/modals/GenericModal';
import { CustomModal } from 'app/shared/modals/CustomModal';
import AddCreditCardModal from 'app/components/Settings/BillingInfo/Modals/AddCreditCardModal';
import SuccessModal from 'app/shared/modals/SuccessModal';
import { useCheckAuthenticity } from 'app/shared/hooks/useCheckAuthenticity';
import { workspaceServices } from 'app/shared/services/workspaceServices';
import Pricing from 'app/shared/components/Header/Pricing';
import { useNavigate } from 'react-router-dom';
import { startCase } from '../../../utils/helpers';

const { updateRequirePaymentMethod } = workspaceServices();

const WorkspacesList = () => {
  const { getLS, setLS } = ls();
  const navigate = useNavigate();
  const { workspacesList, setCurrentWorkspace } = useChangeWorkspace();
  const { signOut } = useCheckAuthenticity();
  const queryClient = useQueryClient();

  const { email, stripeCustomerId } = JSON.parse(getLS('user')) as IUser;
  const [currentWorkspaceId, setCurrentWorkspaceId] = useState(getLS('currentWorkspaceId'));
  const [showUserUpdateModal, setShowUserUpdateModal] = React.useState(false);
  const [showAddCCModal, setShowAddCCModal] = React.useState(false);
  const [showSuccessModal, setShowSuccessModal] = React.useState(false);
  const [showAddNewWorkspaceModal, setShowAddNewWorkspaceModal] = React.useState(false);
  const [showChangeWorkspaceModal, setShowChangeWorkspaceModal] = React.useState(false);
  const [selectedWorkspace, setSelectedWorkspace] = React.useState<any>(currentWorkspaceId);

  const { mutate: asyncUpdateRequirePaymentMethod, isLoading: isLoadingUpdateRequirePaymentMethod } =
    updateRequirePaymentMethod();

  const workspacesListData = useMemo(
    () =>
      workspacesList?.length
        ? workspacesList?.map((m: IWorkspace) => {
          return ({
            value: m?.id,
            label: m?.workspace,
            secondary: `${startCase(m?.planName) || ''} ${startCase(m?.creditPlanInterval?.toString() || '') || ''}  ${m?.isSubscriptionTrial && m.planName !== 'free' ? '(Trial)' : ''}`,
          })
        })
        : [],
    [JSON.stringify(workspacesList)],
  );

  const currentWorkspaceData = useMemo(() => {
    return workspacesList?.find((item: any) => item?.id === currentWorkspaceId);
  }, [JSON.stringify(workspacesList), currentWorkspaceId]);

  useEffect(() => {
    if (currentWorkspaceData?.id?.split('-')[1] === email && currentWorkspaceData?.requirePaymentMethod) {
      setShowUserUpdateModal(true);
    }
  }, [JSON.stringify(currentWorkspaceData)]);

  const handleSuccessAddCC = () => {
    setShowAddCCModal(false);
    setShowUserUpdateModal(false);
    asyncUpdateRequirePaymentMethod({
      workspaceid: currentWorkspaceId,
    });

    setShowSuccessModal(true);
  };

  const UserUpdateModal = useMemo(() => {
    return (
      <GenericModal
        open={showUserUpdateModal}
        onClose={() => {
          setShowUserUpdateModal(false);
        }}
        title={'User Update'}
        message={
          <>
            Please update your payment details to continue
            <br />
            using this workspace.
          </>
        }
        okButtonLabel="Add Credit Card"
        okAction={() => {
          setShowAddCCModal(true);
          setShowUserUpdateModal(false);
        }}
        cancelButtonLabel={workspacesList?.length === 1 ? 'Sign out' : 'Change workspace'}
        isCancelButtonText
        buttonWrapperStyle={{ flexDirection: 'column-reverse' }}
        cancelAction={() => {
          if (workspacesList?.length === 1) {
            signOut();
          } else {
            setShowChangeWorkspaceModal(true);
            setShowUserUpdateModal(false);
          }
        }}
        modalProps={{ dismissible: false }}
        titleStyle={{ textAlign: 'center' }}
      />
    );
  }, [showUserUpdateModal, JSON.stringify(workspacesList)]);

  const ChangeWorkspaceModal = useMemo(() => {
    return (
      <GenericModal
        open={showChangeWorkspaceModal}
        onClose={() => {
          setShowUserUpdateModal(false);
        }}
        title={'Change Workspace'}
        message={
          <>
            Choose the workspace you want to use.
            <br />
            <br />
            <Select
              label={''}
              options={workspacesListData}
              value={selectedWorkspace || ''}
              noLabel
              onChange={(event) => {
                setSelectedWorkspace(event.target.value);
              }}
              selectStyle={{ maxWidth: '100%', minWidth: '100%', width: '100%' }}
              formStyle={{ minWidth: '85%' }}
            />
          </>
        }
        okButtonLabel="Choose Workspace"
        okAction={() => {
          console.log('selectedWorkspace', selectedWorkspace)
          const currentWorkSpace = workspacesList.find((w: IWorkspace) => w.id === selectedWorkspace);
          setCurrentWorkspaceId(selectedWorkspace);
          setLS('currentWorkspaceId', selectedWorkspace);
          setLS('currentWorkspace', JSON.stringify(currentWorkSpace));
          setCurrentWorkspace(currentWorkSpace);
          queryClient.invalidateQueries(['stripeCustomerId', currentWorkSpace?.stripeCustomerId || stripeCustomerId]);
          setShowChangeWorkspaceModal(false);
          setShowUserUpdateModal(false);
        }}
        cancelButtonLabel={'Update payment method instead'}
        isCancelButtonText
        buttonWrapperStyle={{ flexDirection: 'column-reverse' }}
        cancelAction={() => {
          if (workspacesList?.length === 1) {
            signOut();
          } else {
            setShowChangeWorkspaceModal(false);
            setShowUserUpdateModal(false);
            setShowAddCCModal(true);
          }
        }}
        modalProps={{ dismissible: false }}
        titleStyle={{ textAlign: 'center' }}
      />
    );
  }, [
    showChangeWorkspaceModal,
    JSON.stringify(workspacesList),
    JSON.stringify(workspacesListData),
    currentWorkspaceId,
    selectedWorkspace,
    stripeCustomerId,
  ]);

  return (
    <MainCont>
      <Select
        label={''}
        options={[
          ...workspacesListData,
          // { value: 'user_add_workspace', label: '+ Add New Workspace' }
        ]}
        value={currentWorkspaceId || ''}
        noLabel
        onChange={(event) => {
          if (event.target.value === 'user_add_workspace') {
            setShowAddNewWorkspaceModal(true);
            return;
          }
          const currentWorkSpace = workspacesList.find((w: IWorkspace) => w.id === event.target.value);
          setCurrentWorkspaceId(event.target.value);
          setLS('currentWorkspaceId', event.target.value);
          setLS('currentWorkspace', JSON.stringify(currentWorkSpace));
          setCurrentWorkspace(currentWorkSpace);
          queryClient.invalidateQueries(['stripeCustomerId', currentWorkSpace?.stripeCustomerId || stripeCustomerId]);
          navigate('/dashboard', {replace: true})
        }}
        selectStyle={{ maxWidth: '200px' }}
      />
      {showAddCCModal && (
        <CustomModal open={showAddCCModal} onClose={() => setShowAddCCModal(false)} dismissible={false}>
          <AddCreditCardModal handleSubmit={handleSuccessAddCC} paymentCards={[]} />
        </CustomModal>
      )}
      {showAddNewWorkspaceModal && (
        <CustomModal open={showAddNewWorkspaceModal} onClose={() => setShowAddNewWorkspaceModal(false)}>
          <Box bgcolor={'#ffffff'} p={2} maxWidth={'1200px'}>
            <Pricing onClose={() => setShowAddNewWorkspaceModal(false)} />
          </Box>
        </CustomModal>
      )}
      {showUserUpdateModal ? UserUpdateModal : null}
      {showChangeWorkspaceModal ? ChangeWorkspaceModal : null}
      {showSuccessModal && (
        <SuccessModal
          open={showSuccessModal}
          onClose={() => setShowSuccessModal(false)}
          successMessge={'You have successfully added a payment method.'}
          buttonLabel="Close"
          backToDashboard
          buttonAction={() => {
            setShowSuccessModal(false);

            queryClient.invalidateQueries(['retrieveWorkspace']);
            queryClient.invalidateQueries(['stripeCustomerId']);
            queryClient.invalidateQueries(['email', email]);
          }}
        />
      )}
    </MainCont>
  );
};

export default WorkspacesList;

import { useMutation, useQuery } from '@tanstack/react-query';
import { userDao } from '../dao/userDao';
import {
  IDeleteMemberPayload,
  IInviteUserRequestPayload,
  IUpdateProfileRequestPayload,
  IUploadPhotoRequestPayload,
  IUserAddNewWorkspace,
} from '../types/user.types';

const {
  updateProfile: updateProfileDao,
  uploadPhoto: uploadPhotoDao,
  getMembers: getMembersDao,
  deleteMember: deleteMemberDao,
  invite: inviteMemberDao,
  retrieveWorkspaces: retrieveWorkspacesDao,
  addNewWorkspace: addNewWorkspaceDao,
} = userDao();

export const userServices = () => {
  const updateProfile = () => {
    return useMutation((payload: IUpdateProfileRequestPayload) => updateProfileDao(payload));
  };

  const uploadPhoto = () => {
    return useMutation((payload: IUploadPhotoRequestPayload) => uploadPhotoDao(payload));
  };

  const getMembers = (workspaceid: string) => {
    return useQuery(['members', workspaceid], () => getMembersDao(workspaceid));
  };

  const deleteMember = () => {
    return useMutation((payload: IDeleteMemberPayload) => deleteMemberDao(payload));
  };

  const addNewWorkspace = () => {
    return useMutation((payload: IUserAddNewWorkspace) => addNewWorkspaceDao(payload));
  };

  const inviteMember = () => {
    return useMutation((payload: IInviteUserRequestPayload) => inviteMemberDao(payload));
  };

  const getWorkspaces = (email: string) => {
    return useQuery(['email', email], () => retrieveWorkspacesDao(email));
  };

  return {
    deleteMember,
    getMembers,
    updateProfile,
    uploadPhoto,
    inviteMember,
    getWorkspaces,
    addNewWorkspace,
  };
};

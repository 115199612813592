import { Box, styled, Grid } from '@mui/material';

export const MainCont = styled(Grid)(({ theme }) => ({
  display: ' flex',
  height: '100vh',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

export const RightContainer = styled(Grid)(() => ({
  flex: 3,
  flexDirection: 'column',
  overflow: 'hidden',
}));

export const ChildrenCont = styled(Grid)(() => ({
  height: 'calc(100% - 66px)',
  overflowY: 'auto',
}));

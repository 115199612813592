import { Box, Stack, Typography, styled, Theme } from '@mui/material';
import { COLORS } from '../constants/COLORS';
import { Height } from '@mui/icons-material';

export const SuccessCont = styled(Box)(({ theme }: any) => ({
  display: 'flex',
  flexDirection: 'column',
  background: 'white',
  borderRadius: 14,
  padding: 30,
  alignItems: 'center',
  maxHeight: '80vh',
  overflowY: 'auto',
  '.back-to-dashboard': {
    color: COLORS.PRIMARY,
    fontSize: 16,
    fontWeight: 700,
    borderBottom: `solid 1px ${COLORS.PRIMARY}`,
    cursor: 'pointer',
    marginTop: 10,
  },

  [theme.breakpoints.up('sm')]: {
    width: '420px',
  },

  [theme.breakpoints.down('sm')]: {
    width: 'auto',
  },
}));

export const ButtonCont = styled(Box)(({ theme }: any) => ({
  overflow: 'hidden',
  display: 'flex',
  justifyContent: 'center',
  borderRadius: '50%',

  [theme.breakpoints.down('sm')]: {
    height: 'auto',
  },
}));

export const successButton = {
  width: '90%',
  height: 48,
  textTransform: 'none',
  marginTop: '32px',
  fontSize: 16,
  borderRadius: 3,
  marginBottom: 1,
};
export const errorButton = {
  width: '90%',
  textTransform: 'none',
  fontSize: 16,
  fontWeight: 700,
  textDecoration: 'underline',
  textUnderlineOffset: 5,
};

export const closeButton = {
  position: 'absolute',
  right: 10,
  top: 10,
  zIndex: 2,
};

export const ModalCont = styled(Box)(({ theme, transform, zoom, top }: { theme?: Theme; transform?: string; zoom?: number; top?: string }) => ({
  position: 'absolute',
  top: top ? top : '50%',
  left: '50%',
  transform: transform ? transform : 'translate(-50%, -50%)',
  outline: 'none',
  zoom: zoom,

  ...(theme && {
    [theme.breakpoints.down('sm')]: {
      width: '80%',
    },
  }),
}));

export const DeleteCont = styled(Box)(({ theme }: any) => ({
  display: 'flex',
  flexDirection: 'column',
  background: 'white',
  borderRadius: 14,
  padding: 30,
  alignItems: 'center',
  maxHeight: '80vh',
  overflowY: 'auto',
  '.back-to-dashboard': {
    color: COLORS.PRIMARY,
    fontSize: 16,
    fontWeight: 700,
    borderBottom: `solid 1px ${COLORS.PRIMARY}`,
    cursor: 'pointer',
    marginTop: 10,
  },

  [theme.breakpoints.up('sm')]: {
    width: '420px',
  },

  [theme.breakpoints.down('sm')]: {
    width: 'auto',
  },
}));

export const StyledDangerContainer = styled(Stack)(({ theme }: any) => ({
  flexDirection: 'row',
  gap: '16px',
  width: '100%',
  alignSelf: 'center',

  [theme.breakpoints.down('sm')]: {
    width: '100%',
    flexDirection: 'column',
  },
}));

export const StyledDangerText = styled(Typography)(() => ({
  fontSize: '16px',
  fontWeight: 500,
  textTransform: 'capitalize',
}));

export const Title = styled(Typography)(() => ({
  fontSize: '20px',
  fontWeight: 700,
  color: COLORS.LABEL_NORMAL,
  textAlign: 'left',
  alignSelf: 'center',
  width: '100%',
  marginBottom: '10px',
}));

export const Subtitle = styled(Typography)(() => ({
  fontSize: '16px',
  fontWeight: 400,
  color: COLORS.BUTTON_BLACK,
  textAlign: 'center',
  alignSelf: 'center',
  width: '100%',
  opacity: 0.8,
  marginBottom: '16px',
}));
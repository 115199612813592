import { useEffect } from 'react';

import ActivationView from 'app/components/Authentication/Activation';
import AuthenticationLayout from 'app/components/Authentication/AuthenticationLayout';
import { useCheckAuthenticity } from 'app/shared/hooks/useCheckAuthenticity';

const Activation = () => {
  const { signOut } = useCheckAuthenticity();
  useEffect(() => {
    signOut(false);
  }, []);

  return (
    <AuthenticationLayout>
      <div>
        <ActivationView />
      </div>
    </AuthenticationLayout>
  )
}

export default Activation